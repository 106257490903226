import React, { useEffect, useRef, useState } from "react";
import {
  IconButton,
  MenuItem,
  Modal,
  Menu,
  makeStyles,
  Typography,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  PageBody,
  PageHeader,
  PageWrapper,
  StyledButton,
  StyledChip,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../ConnectedEmailsStyles";
import {
  connectNylas,
  deleteUserConnectedEmail,
  disconnectUserConnectedEmail,
  getConnectedEmailReportStats,
  getConnectedEmails,
  markEmailAsDefault,
} from "../../../../api/profileApi";
import Loader from "../../../globals/Loader";
import { DefaultIcon } from "../../../customField/custom-field-v2/helpers/IconList";
import NewConfirmAlert from "../../../activity/new-alert/NewConfirmAlert";
import Utils from "../../../../helpers/Utils";
import ConnectEmailView from ".././ConnectEmailView";
import { LocalPostOffice, MoreVertOutlined } from "@material-ui/icons";
import {
  EMAIL_PROVIDER,
  EMAIL_PROVIDER_DEFAULT,
  EMAIL_STATUS,
} from "../../../../constants/CoreConstants";
import ConnectEmailCalenderSync from ".././ConnectEmailCalenderSync";
import { useHistory, useLocation } from "react-router-dom";
import GlobalEmptyPage from "../../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import { EmptyInboxIcon } from "../../../invoiceSettings/common/icons/invoicePageIcons";
import {
  CheckCircleIcon,
  ContactMailIcon,
  EmailCheckedIcon,
  EmailFailedIcon,
  EmailMarkUnreadIcon,
  UnsubscribeEmailIcon,
} from "../../../ten-dlc/helpers/Icon";
import CustomConfirmAlert from "../../../team/CustomConfirmAlert";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: "30px",
    height: "30px",
    background: "rgb(214, 226, 251)",
    marginRight: "8px",
    padding: "4px",
    color: "rgb(52, 111, 239)",
  },
  iconButtonError: {
    width: "30px",
    height: "30px",
    background: "rgb(251, 207, 210)",
    marginRight: "8px",
    padding: "4px",
    color: "rgb(236, 19, 31)",
    "&:focus": {
      background: "rgb(251, 207, 210)",
    },
  },
  stack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    padding: "8px",
  },
  paper: {
    padding: "8px",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  container: {
    height: "65vh",
    overflowY: "auto",
    },
    tooltip: {
        width: 220, 
        maxWidth: 200, 
        fontSize: 12, 
        padding: 10,
        textAlign: 'center', 
    },
    
}));

const ConnectedEmailListNew = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const from = params.get("from");
  const [defaultNylasEmail, setDefaultNyalasEmail] = useState(null);
  const [openConnectNewEmailModal, setOpenConnectNewEmailModal] =
    useState(false);
  const [openConnectSyncCalenderModal, setOpenConnectSyncCalenderModal] =
    useState(false);
  const [connectedEmails, setConnectedEmails] = useState([]);
  const [emailProvider, setEmailProvider] = useState("");

    useEffect(() => {
     fetchConnectedEmails();
    }, []);

    const fetchConnectedEmails = async () => {
        try {
            const resp = await getConnectedEmails({});
            if (resp && resp.data && resp.data.success) {
                setConnectedEmails(resp.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
            const latestEmail = connectedEmails[connectedEmails.length - 1];
            const provider = connectedEmails.length > 0 ? 
            (latestEmail.credentialInfoProvider ? latestEmail.credentialInfoProvider.toUpperCase() : "UNKNOWN") : 
                "UNKNOWN";
             setEmailProvider(provider);
    }, [connectedEmails]);
    
    useEffect(() => {
            if (connectedEmails.length !== 0) {
            if (from && from === "connect-email" && params.has("success")) {
               
                if (emailProvider !== "IMAP") {
                    setOpenConnectSyncCalenderModal(true);
                }
            }
        }
    }, [emailProvider]);


  const removeQueryParam = (paramsToRemove) => {
    const searchParams = new URLSearchParams(location.search);

    paramsToRemove.forEach((param) => searchParams.delete(param));
    const newSearch = searchParams.toString();
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };

  const handleCloseModal = () => {
    removeQueryParam(["from", "success"]);
    setOpenConnectSyncCalenderModal(false);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <div>
          <h5>Connected Emails</h5>
          <p>
            <b>These emails will be used to send emails to your contacts</b>
          </p>
        </div>
        <StyledButton
          onClick={() => {
            setOpenConnectNewEmailModal(true);
          }}
        >
          Add New Personal Email
        </StyledButton>
      </PageHeader>

      <PageBody>
        <ConnectedEmailList setDefaultNylasEmail={setDefaultNyalasEmail} />
      </PageBody>
      <Modal
        open={openConnectNewEmailModal}
        onClose={() => {
          setOpenConnectNewEmailModal(false);
        }}
        className={"global-xs-modal add_list_modal"}
        style={{ display: "grid", placeContent: "center" }}
      >
        <ConnectEmailView
          defaultNylasEmail={defaultNylasEmail}
          handleCancel={() => {
            setOpenConnectNewEmailModal(false);
          }}
        />
      </Modal>
      <Modal
        open={openConnectSyncCalenderModal}
        onClose={handleCloseModal}
        className={"global-xs-modal add_list_modal"}
        style={{ display: "grid", placeContent: "center" }}
      >
        <ConnectEmailCalenderSync handleCancel={handleCloseModal} />
      </Modal>
    </PageWrapper>
  );
};

export default ConnectedEmailListNew;

const ConnectedEmailList = ({ setDefaultNylasEmail }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [connectedEmails, setConnectedEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const containerRef = useRef(null);
  const [reportData, setReportData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetchConnectedEmails().then();
  }, []);

  const fetchConnectedEmails = async () => {
    setIsLoading(true);
    try {
      const resp = await getConnectedEmails({});

      if (resp && resp.data && resp.data.success) {
        let data = resp.data.data;

        setConnectedEmails(data);
        let nylasDefault = data.find(
          (itemData) =>
            itemData.provider === EMAIL_PROVIDER.NYLAS &&
            itemData.isDefault === EMAIL_PROVIDER_DEFAULT.YES
        );

        if (nylasDefault) {
          setDefaultNylasEmail(nylasDefault);
        }

        const resp1 = await getConnectedEmailReportStats({});
        if (resp1?.success) {
          let data = resp1.data;
          setReportData(data);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleClick = (event, elem) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmail(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMakeDefault = (uid, index) => {
    NewConfirmAlert({
      onSubmit: () => {
        markEmailAsDefault({
          userConnectedEmailUid: uid,
        })
          .then((response) => {
            if (response.data.success) {
              processAfterDefault(uid);
              Utils.showNotification(response.data.message, "success");
            } else {
              Utils.showNotification(response.data.message, "error");
            }
          })
          .finally(() => {
            setSelectedEmail(null);
          });
      },
      title: "Are You Sure?",
      description:
        "If you select this as the default email, the previous email will no longer be the default",
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };

  const handleDelete = (uid, isDefault) => {
      CustomConfirmAlert({
          handleConfirm: () => {
         deleteUserConnectedEmail({
          userConnectedEmailUid: uid,
         })
          .then((response) => {
            if (response.data.success) {
              processAfterDelete(uid);
              if(isDefault===EMAIL_PROVIDER_DEFAULT.YES){
                fetchConnectedEmails().then();
              }
              Utils.showNotification(response.data.message, "success");
            } else {
              Utils.showNotification(response.data.message, "error");
            }
          })
          .finally(() => {
            setSelectedEmail(null);
          });
         },
          callConfirmLast: true,
          title: 'Do you want to delete this?',
          description: 'If you delete this email you won\'t be able to restore it again',
          type_word: 'DELETE EMAIL',
          icon: 'question',
          closeButtonText: 'No',
          confirmButtonText: 'Yes'
    });
  };

  const handleDisconnect = (uid, isDefault) => {
    NewConfirmAlert({
      onSubmit: () => {
        disconnectUserConnectedEmail({
          userConnectedEmailUid: uid,
        })
          .then((response) => {
            if (response.data.success) {
              processAfterDisconnect(uid);
              if(isDefault===EMAIL_PROVIDER_DEFAULT.YES){
                fetchConnectedEmails().then();
              }
              Utils.showNotification(response.data.message, "success");
            } else {
              Utils.showNotification(response.data.message, "error");
            }
          })
          .finally(() => {
            setSelectedEmail(null);
          });
      },
      title: "Are You Sure?",
      description: "You want to disconnect this email",
      cancelText: "Cancel",
      submitText: "Disconnect",
      width: "480px",
    });
  };

  const initReConnectionProcess = (connectingEmail) => {
    NewConfirmAlert({
      onSubmit: async () => {
        try {
          const resp = await connectNylas({ email: connectingEmail });
          if (resp && resp.success && resp.data && resp.data.redirect) {
            window.showNotification(
              "success",
              "Please wait while redirecting..."
            );
            setTimeout(() => {
              window.open(resp.data.redirect, "_self");
            }, 2000);
          } else {
            window.showNotification(
              "error",
              resp?.message || "Unable to connect. Please try again later!",
              3000
            );
          }
        } catch (error) {
          window.showNotification(
            "error",
            "Unable to connect. Please try again later!"
          );
        } finally {
          setSelectedEmail(null);
        }
      },
      title: "Are You Sure?",
      description: "You want to reconnect this email",
      cancelText: "Cancel",
      submitText: "Reconnect",
      width: "480px",
    });
  };

  const processAfterDefault = (uid) => {
    setConnectedEmails((prevState) =>
      prevState.map((item) => {
        if (item.uid === uid) {
          return { ...item, ...{ isDefault: EMAIL_PROVIDER_DEFAULT.YES } };
        } else {
          return { ...item, ...{ isDefault: EMAIL_PROVIDER_DEFAULT.NO } };
        }
      })
    );
  };

  const processAfterDelete = (uid) => {
    setConnectedEmails((prevState) =>
      prevState.filter((elem) => {
        return elem.uid !== uid;
      })
    );
  };

  const processAfterDisconnect = (uid) => {
    setConnectedEmails((prevState) =>
      prevState.map((item) => {
        if (item.uid === uid) {
          return { ...item, ...{ status: "INACTIVE" } };
        } else {
          return { ...item };
        }
      })
    );
  };

  const handleSubmit = async (key) => {
    switch (key) {
      case "DISCONNECT":
        handleDisconnect(selectedEmail.uid, selectedEmail.isDefault);
        handleClose();
        break;
      case "RECONNECT":
        await initReConnectionProcess(selectedEmail.email);
        handleClose();
        break;
      case "DELETE":
        handleDelete(selectedEmail.uid, selectedEmail.isDefault);
        handleClose();
        break;
      case "MAKE_DEFAULT":
        handleMakeDefault(selectedEmail.uid);
        handleClose();
        break;
      default:
        console.log("No settings");
    }
  };

  const sortedEmails = () => {
    let uSortedEmails = [...connectedEmails];

    return uSortedEmails.sort((a, b) => {
      if (a.isDefault === b.isDefault) return 0;
      if (a.isDefault === EMAIL_PROVIDER_DEFAULT.YES) return -1;
      return 1;
    });
  };

  const statusChipColor = (status) => {
    switch (status) {
      case EMAIL_STATUS.ACTIVE:
        return { backgroundColor: "#27ae60", color: "#fff" };
      case EMAIL_STATUS.INACTIVE:
        return { backgroundColor: "#f39c12", color: "#fff" };
      case EMAIL_STATUS.FAILED:
        return { backgroundColor: "#e74c3c", color: "#fff" };
      case EMAIL_STATUS.WARNING:
        return { backgroundColor: "#C58D09", color: "#fff" }
      default:
        return { backgroundColor: "#3498db", color: "#fff" };
        
    }
  };
    const icon = (
            <svg width="14" height="14" viewBox="0 -6 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C4.486 0 0 4.48606 0 9.0001C0 13.5141 4.486 18 9 18C13.514 18 18 13.5141 18 9.0001C18 4.48606 13.514 0 9 0ZM9 16.1818C4.48848 16.1818 1.81818 12.5115 1.81818 9.0001C1.81818 5.48861 4.48848 1.81818 9 1.81818C13.5115 1.81818 16.1818 5.48861 16.1818 9.0001C16.1818 12.5115 13.5115 16.1818 9 16.1818Z" fill="#ffffff" fill-opacity="5" />
                <path d="M9.0009 3.24219C8.3327 3.24219 7.78906 3.78619 7.78906 4.45485C7.78906 5.12292 8.3327 5.66643 9.0009 5.66643C9.6692 5.66643 10.2128 5.12292 10.2128 4.45485C10.2128 3.78619 9.6692 3.24219 9.0009 3.24219Z" fill="#ffffff" fill-opacity="5" />
                <path d="M8.99893 7.48438C8.49687 7.48438 8.08984 7.89141 8.08984 8.39347V13.848C8.08984 14.3501 8.49687 14.7571 8.99893 14.7571C9.501 14.7571 9.908 14.3501 9.908 13.848V8.39347C9.908 7.89141 9.501 7.48438 8.99893 7.48438Z" fill="#ffffff" fill-opacity="5" />
            </svg>
        
    );

  const findEmailReport = (connectedEmail) => {
    try {
      return reportData.find((report) => {
        return (
          report.userConnectedEmailId === connectedEmail.userConnectedEmailId
        );
      });
    } catch (error) {
      console.error("An error occurred while finding the email report:", error);
      return null;
    }
  };

  const calculatePercentageAverage = (number, totalSend) => {
    try {
      if (number !== undefined && number !== null && totalSend !== 0) {
        const result = (number / totalSend) * 100 || 0;
        return parseFloat(result.toFixed(2));
      }

      return 0;
    } catch (err) {
      return 0;
    }
  };
  return (
    <div>
      <div className={classes.container} ref={containerRef}>
        <StyledTable>
          <StyledTableBody>
            {isLoading && (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            )}
            {!isLoading && connectedEmails.length === 0 && (
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  <GlobalEmptyPage
                    icon={<EmptyInboxIcon />}
                    title={
                      "No email connected. Click 'Add New Personal Email' button to add new email"
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            )}
            {!isLoading &&
              connectedEmails.length > 0 &&
              sortedEmails().map((connectedEmail, index) => {
                const emailReport = findEmailReport(connectedEmail);
                return (
                  <StyledTableRow key={index}>
                    <Paper
                      elevation={0}
                      variant="outlined"
                      className={classes.paper}
                    >
                      <StyledTableCell
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {connectedEmail.email}
                          {connectedEmail.isDefault ===
                            EMAIL_PROVIDER_DEFAULT.YES && (
                            <IconButton title={"Default"}>
                              <DefaultIcon
                                height="15px"
                                width="15px"
                                color="#3498db"
                              />
                            </IconButton>
                          )}
                          <StyledTableCell>
                            <StyledChip>
                              {connectedEmail.provider ===
                              EMAIL_PROVIDER.POSTMARK
                                ? "Default"
                                : "Personal"}
                            </StyledChip>
                          </StyledTableCell>
                          <StyledTableCell>
                            <StyledChip
                              style={statusChipColor(
                                connectedEmail.status.toUpperCase()
                              )}
                            >
                              {connectedEmail.status.toUpperCase()}
                            </StyledChip>
                          </StyledTableCell>
                                    <StyledTableCell>
                                        {connectedEmail.credentialInfoProvider.toUpperCase() === "IMAP" ? (
                                            <Tooltip
                                                 
                                                arrow
                                                title={<span>{icon} IMAP provider support emails incoming and outgoing, not calendar functionalities</span>}
                                                placement="top"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <StyledChip style={statusChipColor("WARNING")}>
                                                    <span>{icon} </span>{connectedEmail.credentialInfoProvider.toUpperCase()}
                                                </StyledChip>
                                            </Tooltip>
                                        ) : (
                                                <StyledChip style={statusChipColor("WARNING")}>
                                                {connectedEmail.credentialInfoProvider.toUpperCase()}
                                            </StyledChip>
                                        )}
                                    </StyledTableCell>
                        </div>
                        {connectedEmail.provider === EMAIL_PROVIDER.NYLAS && (
                          <IconButton
                            onClick={(event) => {
                              handleClick(event, connectedEmail);
                            }}
                          >
                            <MoreVertOutlined title={"Action"} />
                          </IconButton>
                        )}
                      </StyledTableCell>
                      {connectedEmail.failReason && (
                        <StyledTableCell style={{ padding: "4px" }}>
                          <Typography
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "10px",
                            }}
                          >
                            *** {connectedEmail.failReason}
                          </Typography>
                        </StyledTableCell>
                      )}
                      {Utils.getAccountData("userId") === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButton}
                              size="small"
                            >
                              <LocalPostOffice />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>{emailReport?.totalSend || 0}</b> Emails Sent
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButton}
                              size="small"
                            >
                              <EmailMarkUnreadIcon />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>
                                {calculatePercentageAverage(
                                  emailReport?.totalOpen,
                                  emailReport?.totalSend
                                )}
                                %
                              </b>{" "}
                              Open Rate({emailReport?.totalOpen || 0})
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButton}
                              size="small"
                            >
                              <EmailCheckedIcon color=")" />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>
                                {calculatePercentageAverage(
                                  emailReport?.totalClick,
                                  emailReport?.totalSend
                                )}
                                %
                              </b>{" "}
                              Click Rate ({emailReport?.totalClick || 0})
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButton}
                              size="small"
                            >
                              <CheckCircleIcon />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>
                                {calculatePercentageAverage(
                                  emailReport?.totalRespond,
                                  emailReport?.totalSend
                                )}
                                %
                              </b>{" "}
                              Reply Rate ({emailReport?.totalRespond || 0})
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButtonError}
                              size="small"
                            >
                              <EmailFailedIcon />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>{emailReport?.totalFailed || 0}</b> Failed
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButtonError}
                              size="small"
                            >
                              <ContactMailIcon />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>{emailReport?.totalBounce || 0}</b> Bounces
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "4px",
                              padding: "8px",
                            }}
                          >
                            <IconButton
                              className={classes.iconButtonError}
                              size="small"
                            >
                              <UnsubscribeEmailIcon />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                              <b>{emailReport?.totalUnsubscribe || 0}</b>{" "}
                              Unsubscribes
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Paper>
                  </StyledTableRow>
                );
              })}
          </StyledTableBody>

          {selectedEmail && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              keepMounted
            >
              {selectedEmail.isDefault === EMAIL_PROVIDER_DEFAULT.NO &&
                selectedEmail.status === EMAIL_STATUS.ACTIVE && (
                  <MenuItem
                    onClick={async () => {
                      await handleSubmit("MAKE_DEFAULT");
                    }}
                  >
                    Make Default
                  </MenuItem>
                )}
              {selectedEmail.status === EMAIL_STATUS.ACTIVE && (
                <MenuItem
                  onClick={async () => {
                    await handleSubmit("DISCONNECT");
                  }}
                >
                  Disconnect
                </MenuItem>
              )}
              {(selectedEmail.status === EMAIL_STATUS.INACTIVE ||
                selectedEmail.status === EMAIL_STATUS.FAILED) && (
                <MenuItem
                  onClick={async () => {
                    await handleSubmit("RECONNECT");
                  }}
                >
                  Reconnect
                </MenuItem>
              )}
              <MenuItem
                onClick={async () => {
                  await handleSubmit("DELETE");
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          )}
        </StyledTable>
      </div>
    </div>
  );
};
