import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { SidebarLeftIcon, SidebarRightIcon } from "../../common/Icons";
import { CustomScrollbar } from "../../productsStyle";
import CreateNewFolderModal from "./CreateNewFolderModal";
import EachFolderItem from "./EachFolderItem";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  width: isSidebarCollapsed ? 56 : 268,
  height: "calc(100vh - 180px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));

const SideBar = ({
  folderList,
  setFolderList,
  selectedFolder,
  setSelectedFolder,
  isLoading,
  handleFolderList,
}) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const handleConfirmDelete = async (id) => {
    setFolderList((prevState) =>
      prevState.filter((folder) => folder.id !== id)
    );
  };

  const showContent = isSidebarCollapsed ? "none" : "block";

  const renderFolderList = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (folderList?.[0] && !isLoading) {
      return folderList.map((folder) => (
        <EachFolderItem
          key={folder.id}
          folder={folder}
          selectedFolder={selectedFolder}
          onSelectFolder={(id) => setSelectedFolder(id)}
          handleFolderList={handleFolderList}
          onConfirmDelete={handleConfirmDelete}
        />
      ));
    }
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
        <Typography className="sidebarTitle" variant="body2">My Insurance Companies</Typography>

        <IconButton color="primary" onClick={handleCollapsed} size="small">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
        <CustomScrollbar style={{ maxHeight: "calc(100vh - 445px)" }}>
          <div className="folderList">{renderFolderList()}</div>
        </CustomScrollbar>

        <ListItem button onClick={() => setOpenNewFolderModal(true)}>
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? " Create Category" : ""}>
              <AddIcon style={{ color: "#346fef" }} fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={600}
              minWidth={170}
              style={{ color: "#346fef" }}
            >
              Create Category
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {openNewFolderModal && (
        <CreateNewFolderModal
          open={openNewFolderModal}
          onClose={() => setOpenNewFolderModal(false)}
          handleFolderList={handleFolderList}
          setIsFolderAdded={() => {}}
          selectedFolder={selectedFolder}
        />
      )}
    </SidebarWrapper>
  );
};

export default SideBar;
