import React, { Fragment, useState } from "react";
import Styles from "./TrustCenterV2.module.css";
import { Button } from "@material-ui/core";
import Icons from "./Icons";
import Utils from "../../helpers/Utils";
import BootstrapTooltip from "../common/BootstrapTooltip";
import { RegistrationDetails } from "./RegistrationDetails";
import TrustCenterSkeleton from "./TrustCenterSkeleton";

const statusClass = {
  APPROVED: "Approved",
  INACTIVE: "Inactive",
  FAILED: "Failed",
  IN_REVIEW: "InReview",
};

const humanize = (str) => {
  try {
    str = str.toLowerCase();
    let i, frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  } catch (err) {
    return str;
  }
}

const TrustCenterV2 = ({ a2pRegistration, a2pRegistrationUpdate, campaignUseCase, campaignUseCaseUpdate, loading, geta2pInfos, loaded }) => {
  const [getData, setGetData] = useState(false);
  const [getUpdateData, setGetUpdateData] = useState(false);

  const getAlertColor = (status) => {
    switch (status) {
      case statusClass.APPROVED:
        return Styles.statusChipApproved;
      case statusClass.IN_REVIEW:
        return Styles.statusChipRequested;
      case statusClass.FAILED:
        return Styles.statusChipFailed;
      case statusClass.INACTIVE:
        return Styles.statusChipInactive;
      default:
        return Styles.statusChipDefault;
    }
  };

  if ((
      Utils.getAccountData("userId") >
      process.env.REACT_APP_CHANGE_IN_10DLC_FOR_SUBACCOUNT_AFTER_USER_ID ||
      Utils.getAccountData("centralBilling")) &&
      !Utils.getAccountData("userIsOwner") ||
      (!a2pRegistration && !campaignUseCase && !loading && loaded)
  ) {
    window.location.href = "/";
    return;
  }

  return (
    <div className={Styles.pageWrapper}>
      {
        loading ?
          <TrustCenterSkeleton/> :
          <Fragment>
            <div className={Styles.pageHeader}>
              <h1 className={Styles.pageTitle}>Trust Center</h1>
              <p className={Styles.pageSubtitle}>Trust Center provides products that can improve customer engagement by increasing throughput and brand recognition. To access the available products please follow the steps below.</p>
              <ul className={Styles.pageHints}>
                <li className={Styles.pageHint}>
                  {Icons.checkMark}
                  Setup a Business Profile
                </li>
                <li className={Styles.pageHint}>
                  {Icons.checkMark}
                  Register Your Campaign(s)
                </li>
              </ul>
            </div>
            <div className={Styles.pageBody}>
              <div className={Styles.serviceContainer}>
                <div className={Styles.serviceContainerItem}>
                  <div className={Styles.serviceContainerItemHeader}>
                    <h1>Create Your Business Profile (required)</h1>
                    <p>An approved Business Profile gives you access to products that can increase consumer trust. To
                      create a primary business profile you will need to provide some basic info about your business.
                      Submit your info now and we'll let you know when you're approved!</p>
                  </div>
                  <div className={Styles.serviceContainerBody}>
                    <div className={Styles.serviceStatusV2}>
                      <div>
                        <span className={Styles.strong}>Type of Profile: </span>
                        {
                          (a2pRegistration?.status !== statusClass.INACTIVE && a2pRegistration?.currentType) ?
                              humanize(a2pRegistration?.currentType) : "N/A"
                        }
                      </div>
                      {
                          !getData &&
                          <span className={Styles.viewDetails} onClick={() => {
                            setGetData(true);
                          }}>
                              View Details
                            </span>
                      }

                    </div>
                    <div className={Styles.serviceStatus}>
                      <span className={Styles.strong}>Status: </span>
                      <span className={getAlertColor(a2pRegistration?.status)}>
                        {a2pRegistration?.status}
                      </span>
                    </div>
                    {
                        a2pRegistration?.failedReason &&
                        <div className={Styles.serviceStatus}>
                        <span className={Styles.boxHeaderMessage}>
                          <span className={Styles.strong}>Failed Reason: </span>
                          <BootstrapTooltip arrow placement="left" title={a2pRegistration?.failedReason}>
                            <span>{a2pRegistration?.failedReason}</span>
                          </BootstrapTooltip>
                        </span>
                        </div>
                    }
                  </div>
                  <div className={Styles.serviceContainerFooter}>
                    {
                      (a2pRegistration?.status === statusClass.APPROVED && a2pRegistration?.updatable === true) ?
                          <Button variant="contained" color="primary" className={Styles.neutralButton} onClick={() => {
                            window.globalA2p10DlcManual(true, "ASK_VOLUME", {
                              isExit: true,
                              callback: () => {
                                geta2pInfos();
                              }
                            }, "UPDATE_BRAND");
                          }}>
                            Update Business Profile
                          </Button> :
                          (
                              (a2pRegistration?.status === statusClass.INACTIVE || a2pRegistration?.status === statusClass.FAILED) ?
                                  <Button variant="contained" color="primary" className={Styles.neutralButton}
                                          onClick={() => {
                                            window.globalA2p10DlcManual(true, "WELCOME_DLC", {
                                              callback: () => {
                                                geta2pInfos();
                                              }
                                            });
                                          }}>
                                    Register Business Profile
                                  </Button> :
                                  ""
                          )
                    }
                  </div>
                </div>
                <div className={Styles.serviceContainerItem}>
                  <div className={Styles.serviceContainerItemHeader}>
                    <h1>Campaign Registration (SMS/MMS) (required)</h1>
                    <p>Avoid additional carrier filtering by registering your Business Profile for A2P messaging
                      capabilities. This applies to SMS/MMS sent to the US (only) via 10-digit long code numbers.</p>
                  </div>
                  <div className={Styles.serviceContainerBody}>
                    {
                        campaignUseCase?.message &&
                        <div className={Styles.serviceStatus}>
                          <span className={Styles.strong}>* </span>
                          {campaignUseCase?.message}
                        </div>
                    }
                    <div className={Styles.serviceStatus}>
                      <span className={Styles.strong}>Campaign Use Case: </span>
                      {
                        (campaignUseCase?.status !== statusClass.INACTIVE && campaignUseCase?.useCase) ?
                            humanize(campaignUseCase?.useCase) : "N/A"
                      }
                    </div>
                    <div className={Styles.serviceStatus}>
                      <span className={Styles.strong}>Status: </span>
                      <span className={getAlertColor(campaignUseCase?.status)}>
                        {campaignUseCase?.status}
                      </span>
                    </div>
                    {
                        campaignUseCase?.failedReason &&
                        <div className={Styles.serviceStatus}>
                        <span className={Styles.boxHeaderMessage}>
                          <span className={Styles.strong}>Failed Reason: </span>
                          <BootstrapTooltip title={campaignUseCase?.failedReason}>
                            <span>{campaignUseCase?.failedReason}</span>
                          </BootstrapTooltip>
                        </span>
                        </div>
                    }
                  </div>
                  <div className={Styles.serviceContainerFooter}>
                    {
                        (((campaignUseCase?.status === statusClass.FAILED || campaignUseCase?.status === statusClass.INACTIVE)
                                && a2pRegistration?.status === statusClass.APPROVED) ||
                            (campaignUseCase?.status === statusClass.INACTIVE
                                && a2pRegistration?.status === statusClass.IN_REVIEW)
                        ) &&
                        <Button variant="contained" color="primary" className={Styles.neutralButton} onClick={() => {
                          if (campaignUseCase?.messaging_service_info) {
                            window.globalA2pCampaignUseCaseRegistration(true, {
                              ...campaignUseCase,
                              callback: () => {
                                geta2pInfos();
                              }
                            });
                          } else {
                            window.globalA2pCampaignUseCaseRegistration(true, {
                              callback: () => {
                                geta2pInfos();
                              }
                            });
                          }
                        }}>
                          Review Registration
                        </Button>
                    }
                  </div>
                </div>

                {
                    getData &&
                    <div className={Styles.serviceContainerItem}>
                      <div className={Styles.serviceContainerItemHeader}>
                        <div className={Styles.serviceContainerBody}>
                          <RegistrationDetails/>
                        </div>
                      </div>
                    </div>
                }

                {
                    a2pRegistrationUpdate &&
                    <div className={Styles.serviceContainerItem}>
                      <div className={Styles.serviceContainerItemHeader}>
                        <h1>Update Business Profile Request Info</h1>
                        <p>An approved Business Profile gives you access to products that can increase consumer trust.
                          To
                          create a primary business profile you will need to provide some basic info about your
                          business.
                          Submit your info now and we'll let you know when you're approved!</p>
                      </div>
                      <div className={Styles.serviceContainerBody}>
                        <div className={Styles.serviceStatusV2}>
                          <div>
                            <span className={Styles.strong}>Type of Profile: </span>
                            {
                              (a2pRegistrationUpdate?.status !== statusClass.INACTIVE && a2pRegistrationUpdate?.currentType) ?
                                  humanize(a2pRegistrationUpdate?.currentType) : "N/A"
                            }
                          </div>
                          {
                              !getUpdateData &&
                              <span className={Styles.viewDetails} onClick={() => {
                                setGetUpdateData(true);
                              }}>
                              View Details
                            </span>
                          }

                        </div>
                        <div className={Styles.serviceStatus}>
                          <span className={Styles.strong}>Status: </span>
                          <span className={getAlertColor(a2pRegistrationUpdate?.status)}>
                        {a2pRegistrationUpdate?.status}
                      </span>
                        </div>
                        {
                            a2pRegistrationUpdate?.failedReason &&
                            <div className={Styles.serviceStatus}>
                              <span className={Styles.boxHeaderMessage}>
                                <span className={Styles.strong}>Failed Reason: </span>
                                <BootstrapTooltip arrow placement="left" title={a2pRegistrationUpdate?.failedReason}>
                                  <span>{a2pRegistrationUpdate?.failedReason}</span>
                                </BootstrapTooltip>
                              </span>
                            </div>
                        }
                      </div>
                      <div className={Styles.serviceContainerFooter}>
                        {
                          (a2pRegistrationUpdate?.status === statusClass.APPROVED && a2pRegistrationUpdate?.updatable === true) ?
                              <Button variant="contained" color="primary" className={Styles.neutralButton}
                                      onClick={() => {
                                        window.globalA2p10DlcManual(true, "ASK_VOLUME", {
                                          isExit: true,
                                          callback: () => {
                                            geta2pInfos();
                                          }
                                        });
                                      }}>
                                Update Business Profile
                              </Button> :
                              (
                                  (a2pRegistrationUpdate?.status === statusClass.INACTIVE || a2pRegistrationUpdate?.status === statusClass.FAILED) ?
                                    <Button variant="contained" color="primary" className={Styles.neutralButton} onClick={() => {
                                      window.globalA2p10DlcManual(true, "ASK_VOLUME", {
                                        isExit: true,
                                        callback: () => {
                                          geta2pInfos();
                                        }
                                      }, "UPDATE_BRAND");
                                    }}>
                                      Update Business Profile
                                    </Button> :
                                      ""
                              )
                        }
                      </div>
                    </div>
                }

                {
                    campaignUseCaseUpdate &&
                    <div className={Styles.serviceContainerItem}>
                      <div className={Styles.serviceContainerItemHeader}>
                        <h1>Update Campaign Registration (SMS/MMS) Request</h1>
                        <p>Avoid additional carrier filtering by registering your Business Profile for A2P messaging
                          capabilities. This applies to SMS/MMS sent to the US (only) via 10-digit long code
                          numbers.</p>
                      </div>
                      <div className={Styles.serviceContainerBody}>
                        {
                            campaignUseCaseUpdate?.message &&
                            <div className={Styles.serviceStatus}>
                              <span className={Styles.strong}>* </span>
                              {campaignUseCaseUpdate?.message}
                            </div>
                        }
                        <div className={Styles.serviceStatus}>
                          <span className={Styles.strong}>Campaign Use Case: </span>
                          {
                            (campaignUseCaseUpdate?.status !== statusClass.INACTIVE && campaignUseCaseUpdate?.useCase) ?
                                humanize(campaignUseCaseUpdate?.useCase) : "N/A"
                          }
                        </div>
                        <div className={Styles.serviceStatus}>
                          <span className={Styles.strong}>Status: </span>
                          <span className={getAlertColor(campaignUseCaseUpdate?.status)}>
                        {campaignUseCaseUpdate?.status}
                      </span>
                        </div>
                        {
                            campaignUseCaseUpdate?.failedReason &&
                            <div className={Styles.serviceStatus}>
                        <span className={Styles.boxHeaderMessage}>
                          <span className={Styles.strong}>Failed Reason: </span>
                          <BootstrapTooltip title={campaignUseCaseUpdate?.failedReason}>
                            <span>{campaignUseCaseUpdate?.failedReason}</span>
                          </BootstrapTooltip>
                        </span>
                            </div>
                        }
                      </div>
                      <div className={Styles.serviceContainerFooter}>
                        {
                            (((campaignUseCaseUpdate?.status === statusClass.FAILED || campaignUseCaseUpdate?.status === statusClass.INACTIVE)
                                    && a2pRegistrationUpdate?.status === statusClass.APPROVED) ||
                                (campaignUseCaseUpdate?.status === statusClass.INACTIVE
                                    && a2pRegistrationUpdate?.status === statusClass.IN_REVIEW)
                            ) &&
                            <Button variant="contained" color="primary" className={Styles.neutralButton}
                                    onClick={() => {
                                      if (campaignUseCaseUpdate?.messaging_service_info) {
                                        window.globalA2pCampaignUseCaseRegistration(true, {
                                          ...campaignUseCaseUpdate,
                                          callback: () => {
                                            geta2pInfos();
                                          }
                                        }, "UPDATE_BRAND");
                                      } else {
                                        window.globalA2pCampaignUseCaseRegistration(true, {
                                          callback: () => {
                                            geta2pInfos();
                                          }
                                        }, "UPDATE_BRAND");
                                      }
                                    }}>
                              Review Registration
                            </Button>
                        }
                      </div>
                    </div>
                }

                {
                    getUpdateData &&
                    <div className={Styles.serviceContainerItem}>
                      <div className={Styles.serviceContainerItemHeader}>
                        <div className={Styles.serviceContainerBody}>
                          <RegistrationDetails update={true}/>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </Fragment>
      }
    </div>
  );
};

export default TrustCenterV2;