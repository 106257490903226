import React, { Fragment, useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getUser } from "../../actions/profileAction";
import { getMapFetchContactList, mapOrderAddToContact } from "../../api/profileApi";
import { getCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import { useIsMounted } from "../../hooks/IsMounted";
import Loader from "../globals/Loader";
import './MapOrder.css';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select';
import { Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import { CSVLink } from "react-csv";
import { MenuProps } from "../../constants/CoreConstants";
import GlobalSearchBox from "../globals/GlobalSearchBox";
import MapOrderActions from "./MapOrderActions";

const convertIncome = (_income) => {
    if (_income === 'A')
        return "Under $10K";
    else if (_income === 'B')
        return "$10-19,999";
    else if (_income === 'C')
        return "$20-29,999";
    else if (_income === 'D')
        return "$30-39,999";
    else if (_income === 'E')
        return "$40-49,999";
    else if (_income === 'F')
        return "$50-59,999";
    else if (_income === 'G')
        return "$60-69,999";
    else if (_income === 'H')
        return "$70-79,999";
    else if (_income === 'I')
        return "$80-89,999";
    else if (_income === 'J')
        return "$90-99,999";
    else if (_income === 'K')
        return "$100-149,999";
    else if (_income === 'L')
        return "$150-174,999";
    else if (_income === 'M')
        return "$175-199,999";
    else if (_income === 'N')
        return "$200-249,999";
    else if (_income === 'O')
        return "$250-274,999";
    else if (_income === 'P')
        return "$275-299,999";
    else if (_income === 'Q')
        return "$300-349,999";
    else if (_income === 'R')
        return "$350-374,999";
    else if (_income === 'S')
        return "$375-399,999";
    else if (_income === 'T')
        return "$400-449,999";
    else if (_income === 'U')
        return "$450-474,999";
    else if (_income === 'V')
        return "$475-499,999";
    else if (_income === 'W')
        return "$500-549,999";
    else if (_income === 'X')
        return "$575-599,999";
    else if (_income === 'Y')
        return "$600-649,999";
    else if (_income === 'Z')
        return "$650+";
    else
        return _income;
}

const checkEmptyArray = (array) => {
    let empty = true;
    if (typeof array !== 'undefined' && array.length > 0){
        empty = false;
    }
    return empty;
}

const MapOrder = (props) => {
    const { id } = useParams();

    const isMounted = useIsMounted();
    const sortBy = 'created_at';
    const sortType = 'desc';

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    
    // filter
    const [emailNumberSearch, setEmailNumberSearch] = useState('all');
    const [genderSearch, setGenderSearch] = useState('all');
    const [lengthOfResidence, setLengthOfResidence] = useState([]);
    const [livingSquareFeet, setLivingSquareFeet] = useState([]);
    const [income, setIncome] = useState([]);
    const [creditRating, setCreditRating] = useState([]);
    const [homeOwnerFlag, setHomeOwnerFlag] = useState([]);
    const [homeValue, setHomeValue] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
    const [yearBuilt, setYearBuilt] = useState([]);
    const [subdivisionName, setSubdivisionName] = useState([]);
    const [autoXdate, setAutoXdate] = useState([]);
    const [autoXdateSource, setAutoXdateSource] = useState([]);
    const [saleDate, setSaleDate] = useState([]);
    const [pool, setPool] = useState([]);
    const [motorcycle, setMotorcycle] = useState([]);
    const [rv12, setRv12] = useState([]);
    

    const [showFilters,setShowFilters] = useState(false);

    useEffect(() => {
        document.title = `Map Order | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('map_finder');
        }
        if (!props.user) {
            props.getUser({
                'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        loadData(); 
    }, [                // eslint-disable-line react-hooks/exhaustive-deps
        currentPage, 
        reset, 
        perPage, 
        sortType, 
        debouncedQuery, 
        emailNumberSearch, 
        genderSearch, 
        lengthOfResidence, 
        livingSquareFeet,
        income,
        creditRating,
        homeOwnerFlag,
        homeValue,
        maritalStatus,
        yearBuilt,
        subdivisionName,
        autoXdate,
        autoXdateSource,
        saleDate,
        pool,
        motorcycle,
        rv12
    ]);


    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

       

    const handelCheckBox = (_id) => {
        let array = [...selectedIds];

        if (array.includes(_id)) {
            array = array.filter(function(item) {
                return item !== _id
            });
        } else {
            array.push(_id);
        }

        setSelectedIds(array);
    }

    const loadData = () => {
        setLoading(true);

        getMapFetchContactList({
            "form_data": {
                ...(emailNumberSearch !== 'all') && {email_number_search: emailNumberSearch},
                ...(genderSearch !== 'all') && {gender_search: genderSearch},
                ...(lengthOfResidence.length) && {length_of_residence_search: lengthOfResidence},
                ...(livingSquareFeet.length) && {living_square_feet_search: livingSquareFeet},
                ...(income.length) && {income_search: income},
                ...(creditRating.length) && {credit_rating_search: creditRating},
                ...(homeOwnerFlag.length) && {home_owner_flag_search: homeOwnerFlag},
                ...(homeValue.length) && {home_value_search: homeValue},
                ...(maritalStatus.length) && {marital_status_search: maritalStatus},
                ...(yearBuilt.length) && {year_build_search: yearBuilt},
                ...(subdivisionName.length) && {subdivision_name_search: subdivisionName},
                ...(autoXdate.length) && {auto_xdate_search: autoXdate},
                ...(autoXdateSource.length) && {auto_xdate_source_search: autoXdateSource},
                ...(saleDate.length) && {sale_date_search: saleDate},
                ...(pool.length) && {pool_search: pool},
                ...(motorcycle.length) && {motorcycle_search: motorcycle},
                ...(rv12.length) && {rv_12_search: rv12},
                ...{
                    orderId: id,
                    page: currentPage + 1,
                    per_page: perPage,
                    sort_by: sortBy,
                    sort_type: sortType,
                    query: debouncedQuery
                }
            }
        })
        .then(response => {

            if(response.data.data.result.data && Array.isArray(response.data.data.result.data)){
                let arrayData = response.data.data.result.data.map(item =>{
                    item.income = convertIncome(item.income);
                    return item
                })
                setData(arrayData)
            }else{
                setData(response.data.data.result.data);

            }
            setTotal(response.data.data.result.total);
            setOrderDetails(response.data.data.orderDetails);
            setFilters(response.data.data.filterData);
        }).catch((error) => {
            console.log(error);
            try {
                Utils.showNotification(error.response.data.message);
            } catch (error2) {
                console.log(error2);
            }
        }).finally(() => {
            setLoading(false);
            setSelectedIds([]);
        });
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="11">
                        <div style={{paddingTop: 50}}>
                            <Loader/>
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data && !data.length) {
            return (
                <tr>
                    <td colspan="11">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data && data.length) {
            return data.map((item,index) => {
        
                return (
                    <tr>
                        <td>
                            <Checkbox
                                checked={selectedIds.includes(parseInt(item.id))} 
                                onClick={(event) => handelCheckBox(item.id)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </td>
                        <td>
                            <span>{item.first_name}</span>
                        </td>
                        <td>
                            <span>{item.last_name}</span>
                        </td>
                        <td>
                            <span>{item.address}</span>
                        </td>
                        <td>
                            <span>{item.email}</span>
                        </td>
                        <td>
                            <span>{item.number}</span>
                        </td>
                        <td>
                            <span>{item.city}</span>
                        </td>
                        <td>
                            <span>{item.street}</span>
                        </td>
                        <td>
                            <span>{item.zip}</span>
                        </td>
                        <td>
                            <span>{item.income}</span>
                        </td>
                        <td>
                            <span>{item.gender}</span>
                        </td>
                    </tr>
                )
            })
        }
    }

    const selectAll = () => {
        let array = [];

        if (data && data.length !== 0 && data.length === selectedIds.length) {
            setSelectedIds(array);
        } else {
            data && data.forEach(element => {
                array.push(element.id);
            });

            setSelectedIds(array);
        }
    }

    const renderFilter = () => (
        <Grid container>
            <Grid item xs={3}>
                <label>
                    Search by email and number
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setEmailNumberSearch(event.target.value);
                            }}
                            value={emailNumberSearch}
                            MenuProps={MenuProps}
                            className="customSelect"
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value={'all'}>All</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={'only-number'}>Number available</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={'only-email'}>Email available</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={'number-email'}>Number and email both available</MenuItem>
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Search by gender
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setGenderSearch(event.target.value);
                            }}
                            value={genderSearch}
                            MenuProps={MenuProps}
                            className="customSelect"
                        >
                            <MenuItem className="dropdownhelper-menuitem-class" value={'all'}>All</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={'Male'}>Male</MenuItem>
                            <MenuItem className="dropdownhelper-menuitem-class" value={'Female'}>Female</MenuItem>
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Length Of Residence
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setLengthOfResidence(event.target.value);
                            }}
                            multiple
                            value={lengthOfResidence}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.lengthOfResidenceArray))}
                        >
                            {
                                filters && typeof filters.lengthOfResidenceArray !== 'undefined' && filters.lengthOfResidenceArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Living Square Feet
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setLivingSquareFeet(event.target.value);
                            }}
                            multiple
                            value={livingSquareFeet}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.livingSquareFeetArray))}
                        >
                            {
                                filters && typeof filters.livingSquareFeetArray !== 'undefined' && filters.livingSquareFeetArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Income
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setIncome(event.target.value);
                            }}
                            multiple
                            value={income}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && filters.incomeArray && checkEmptyArray(Object.keys(filters.incomeArray)))}
                        >
                            {
                                filters && typeof filters.incomeArray !== 'undefined' && Object.keys(filters.incomeArray).map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{filters.incomeArray[item]}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Credit Score
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setCreditRating(event.target.value);
                            }}
                            multiple
                            value={creditRating}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.creditRatingArray))}
                        >
                            {
                                filters && typeof filters.creditRatingArray !== 'undefined' && filters.creditRatingArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Home Owner Flag
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setHomeOwnerFlag(event.target.value);
                            }}
                            multiple
                            value={homeOwnerFlag}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.homeOwnerFlagArray))}
                        >
                            {
                                filters && typeof filters.homeOwnerFlagArray !== 'undefined' && filters.homeOwnerFlagArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Home Value
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setHomeValue(event.target.value);
                            }}
                            multiple
                            value={homeValue}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.homeValueArray))}
                        >
                            {
                                filters && typeof filters.homeValueArray !== 'undefined' && filters.homeValueArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Marital Status
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setMaritalStatus(event.target.value);
                            }}
                            multiple
                            value={maritalStatus}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.maritalStatusArray))}
                        >
                            {
                                filters && typeof filters.maritalStatusArray !== 'undefined' && filters.maritalStatusArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Year Build
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setYearBuilt(event.target.value);
                            }}
                            multiple
                            value={yearBuilt}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.yearBuiltArray))}
                        >
                            {
                                filters && typeof filters.yearBuiltArray !== 'undefined' && filters.yearBuiltArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Subdivision Name
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setSubdivisionName(event.target.value);
                            }}
                            multiple
                            value={subdivisionName}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.subdivisionNameArray))}
                        >
                            {
                                filters && typeof filters.subdivisionNameArray !== 'undefined' && filters.subdivisionNameArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Auto_xdate
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setAutoXdate(event.target.value);
                            }}
                            multiple
                            value={autoXdate}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.autoXdateArray))}
                        >
                            {
                                filters && typeof filters.autoXdateArray !== 'undefined' && filters.autoXdateArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Auto_xdate_source
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setAutoXdateSource(event.target.value);
                            }}
                            multiple
                            value={autoXdateSource}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.autoXdateSourceArray))}
                        >
                            {
                                filters && typeof filters.autoXdateSourceArray !== 'undefined' && filters.autoXdateSourceArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Sale Date
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setSaleDate(event.target.value);
                            }}
                            multiple
                            value={saleDate}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.saleDateArray))}
                        >
                            {
                                filters && typeof filters.saleDateArray !== 'undefined' && filters.saleDateArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Pool
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setPool(event.target.value);
                            }}
                            multiple
                            value={pool}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.poolArray))}
                        >
                            {
                                filters && typeof filters.poolArray !== 'undefined' && filters.poolArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    Motorcycle
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setMotorcycle(event.target.value);
                            }}
                            multiple
                            value={motorcycle}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.motorcycleArray))}
                        >
                            {
                                filters && typeof filters.motorcycleArray !== 'undefined' && filters.motorcycleArray.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
            <Grid item xs={3}>
                <label>
                    RV-12
                    <p>
                        <MaterialSelect
                            onChange={(event) => {
                                setRv12(event.target.value);
                            }}
                            multiple
                            value={rv12}
                            MenuProps={MenuProps}
                            className="customSelect"
                            disabled={(filters && checkEmptyArray(filters.rv12Array))}
                        >
                            {
                                filters && typeof filters.rv12Array !== 'undefined' && filters.rv12Array.map((item, index) => (
                                    <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                                ))
                            }
                        </MaterialSelect>
                    </p>
                </label>
            </Grid>
        </Grid>	
    )

    const makeAddToContactRequest = () => {
        setLoading(true);
        mapOrderAddToContact({
            "form_data": {
                orderId: id,
                contactIds: selectedIds
            }
        })
        .then(response => {
            if (response.data.status === 'success') {
                Utils.showNotification(response.data.message, 'success');
                setReset(reset + 1);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, 'error');
                    });
                }
            } else {
                Utils.showNotification(response.data.message, 'error');
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Fragment>
            <div className="maporder_section">
                {
                    !props.user || !orderDetails ? (
                        <div style={{paddingTop: 100}}>
                            <Loader/>
                        </div>
                    ) : (
                        <Fragment>
                        <div className="page_heading">
                            <div className="title">
                                <h4>Map Order: <span>{orderDetails.title ? orderDetails.title : orderDetails.order_no}</span></h4>
                                <p>**The leads below will be deleted in 2 hours. If you wish to keep them you can add them as a contact or download the list as a csv file.</p>
                            </div>
                            <div className="action">
                                <a href="#!" className="filter_btn" onClick={(e) => {
                                    e.preventDefault();
                                    setShowFilters(!showFilters);
                                }}>
                                    <svg  focusable="false" height="24" width="24" viewBox="0 0 24 24" aria-hidden="true" data-testid="FilterAltOutlinedIcon" tabindex="-1" title="FilterAltOutlined"><path d="M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z" fill="#133159" fillOpacity="0.65"></path></svg>
                                </a>
                                <a href="#!" className="refresh" onClick={(e) => {
                                    e.preventDefault();
                                    setReset(reset + 1);
                                }}>
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8271 1.25633C13.7117 1.14102 13.5749 1.08317 13.4169 1.08317C13.2589 1.08317 13.1223 1.14102 13.0067 1.25633L11.8219 2.43229C11.1718 1.8185 10.4273 1.34292 9.58883 1.00576C8.75041 0.668564 7.88732 0.5 7.00013 0.5C5.32905 0.5 3.87078 1.00579 2.62506 2.01759C1.37932 3.02926 0.559038 4.34923 0.164062 5.97782V6.04157C0.164062 6.12055 0.192891 6.18897 0.250643 6.24662C0.308395 6.30438 0.376715 6.33321 0.455697 6.33321H2.26948C2.40322 6.33321 2.4943 6.26329 2.54295 6.12362C2.79813 5.51602 2.95909 5.16057 3.02601 5.05723C3.45131 4.36452 4.01642 3.82065 4.72132 3.42567C5.42629 3.03073 6.18582 2.83317 7 2.83317C8.22148 2.83317 9.28177 3.24944 10.1812 4.08192L8.92313 5.33967C8.80797 5.45514 8.75006 5.59194 8.75006 5.74994C8.75006 5.9079 8.80794 6.04464 8.92313 6.16008C9.03873 6.27555 9.1755 6.33324 9.33349 6.33324H13.4169C13.5749 6.33324 13.7117 6.27555 13.8271 6.16008C13.9424 6.04464 14.0001 5.90793 14.0001 5.74994V1.66651C14.0001 1.50851 13.9428 1.37184 13.8271 1.25633Z" fill="#133159" fill-opacity="0.65"></path>
                                        <path d="M13.4804 8.66835H11.7304C11.5967 8.66835 11.5056 8.73817 11.4571 8.87797C11.202 9.48563 11.0408 9.84092 10.9741 9.94426C10.5487 10.6368 9.98352 11.1809 9.27881 11.5758C8.57381 11.9709 7.81428 12.1682 7.00006 12.1682C6.4107 12.1682 5.8425 12.0589 5.29568 11.8403C4.74881 11.6213 4.25959 11.3114 3.82822 10.9103L5.07697 9.66163C5.19238 9.54629 5.25013 9.40939 5.25013 9.25149C5.25013 9.09353 5.19238 8.95663 5.07697 8.84135C4.96149 8.72578 4.82482 8.66797 4.66683 8.66797H0.583397C0.425305 8.66797 0.28857 8.72578 0.173129 8.84135C0.0576884 8.95667 0 9.09356 0 9.25152V13.335C0 13.4928 0.0576884 13.6297 0.173161 13.7451C0.288698 13.8603 0.425432 13.9184 0.583429 13.9184C0.741394 13.9184 0.878065 13.8604 0.993569 13.7451L2.16933 12.5692C2.81955 13.1889 3.55941 13.666 4.38886 14.0002C5.2183 14.3345 6.0766 14.5014 6.9637 14.5014C8.62875 14.5014 10.081 13.9956 11.3205 12.9839C12.5601 11.9723 13.3744 10.652 13.7634 9.02361C13.7695 9.01148 13.7724 8.99019 13.7724 8.95992C13.7724 8.88081 13.7435 8.81269 13.6857 8.75474C13.6279 8.69731 13.5595 8.66835 13.4804 8.66835Z" fill="#133159" fill-opacity="0.65"></path>
                                    </svg>								
                                    Refresh
                                </a>
                                <Link to={`/user/map-orders`} className="order">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.875 10C1.875 14.4871 5.51289 18.125 10 18.125C14.4871 18.125 18.125 14.4871 18.125 10C18.125 5.51289 14.4871 1.875 10 1.875C5.51289 1.875 1.875 5.51289 1.875 10ZM10.625 6.87148L8.14141 9.375H13.9844V10.625H8.14141L10.625 13.1285L9.73828 14.009L5.76016 10L9.73828 5.99102L10.625 6.87148Z" fill="white"></path>
                                    </svg>
                                    Back
                                </Link>
                            </div>
                        </div>
                        <div className={`page_heading alt ${showFilters ? 'filter_list_wrapper_active' : 'filter_list_wrapper'}`}>
                            {renderFilter()}
                        </div>
                        <div className="table_heading">
                            <div className="search_form">
                                <div className="form_group">
                                    <MapOrderActions
                                        makeAddToContactRequest={makeAddToContactRequest}
                                        selectedIds={selectedIds}
                                        loading={loading}
                                        data={data}
                                        orderDetails={orderDetails}
                                        reset={reset}
                                        setReset={setReset}
                                    />
                                </div>
                                <div className="form_group">
                                    <GlobalSearchBox
                                        wrapperClass="search-box-order-list"
                                        type="search"
                                        autocomplete="off" 
                                        placeholder="Search Leads" 
                                        onChange={(e) => setQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="responsive-table responsive-random-table awesome__scroll_bar pb-0 mb-5"  >
                            <table className="data_table">
                                <thead>
                                    <tr>
                                        <th>
                                            <button className="btn accent--bg--text--color map_order_select_all_btn" onClick={selectAll}>{data && data.length !== 0 && data.length === selectedIds.length ? 'Deselect All' : 'Select All'}</button>
                                        </th>
                                        <th>
                                            First Name
                                        </th>
                                        <th>
                                            Last Name
                                        </th>
                                        <th>
                                            Address
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Number
                                        </th>
                                        <th>
                                            City
                                        </th>
                                        <th>
                                            Street
                                        </th>
                                        <th>
                                            Zip
                                        </th>
                                        <th>
                                            Income
                                        </th>
                                        <th>
                                            Gender
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>

                        <div className="table_footer">
                            <div className="mt-3">
                                <TablePagination
                                    component="div"
                                    count={total}
                                    page={currentPage}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={perPage}
                                    rowsPerPageOptions={[10, 25, 50, 100, 200, 300]}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </Fragment>
                    )
                }
			</div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapOrder);