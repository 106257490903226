import React, {useRef, useState} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import MicIcon from '@material-ui/icons/Mic';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import BootstrapTooltip from '../globals/BootstrapTooltip';
import { deleteVirtualNumberSetting, virtualNumberVoicemailMessageSubmit } from '../../api/profileApi';
import AudioRecord from './AudioRecord';
import If from 'if-else-react'
import { confirmAlert } from 'react-confirm-alert';

const VoicemailMessageEdit = ({ item }) => {

    const inputField = useRef(null);
    const [validation,setValidation] = useState(false);
    const [validationText,setValidationText] = useState('');
    const [edit,setEdit] = useState(false);
    const [saving,setSaving] = useState(false);
    const [fieldValue,setFieldValue] = useState(item.no_answer_whisper_message);
    const [mouseOver,setMouseOver] = useState(false);
    const [defaultValue,setDefaultValue] = useState(item.no_answer_whisper_message);
    const [audio, setAudio] = useState(item.no_answer_whisper_audio);
    const [defaultAudio,setDefaultAudio] = useState(item.no_answer_whisper_audio);
    const [openAudio, setOpenAudio] = useState(item.do_play_audio === 1);
    const [defaultOpenAudio, setDefaultOpenAudio] = useState(item.do_play_audio === 1);


    const handleCrossPress = (forceClose = true) => {
        setEdit(false);
        setValidation(false);
        setValidationText('');
        if(forceClose) {
            setFieldValue(defaultValue);
            setAudio(defaultAudio);
            setOpenAudio(defaultOpenAudio);
        }
    }

    const handleEnterPress = (event) => {
        var code = (event.keyCode ? event.keyCode : event.which);
        if(code === 13) {
            handleSubmit();
        }
    }

    const handleCheckPress = () => {
        handleSubmit();
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('id', item.id);
        formData.append('no_answer_whisper_message',( openAudio ? '' : fieldValue));
        if(openAudio) {
            formData.append('do_play_audio',1);
            formData.append('record-audio-file', audio);
        } else {
            formData.append('do_play_message',1);
        }

        setSaving(true);
        virtualNumberVoicemailMessageSubmit(formData).then(res => {
            setSaving(false);
            if(res.data.status === 'validation-error') {
                window.showNotification('ERROR',res.data.message[Object.keys(res.data.message)[0]][0]);
            } else if(res.data.status === 'error') {
                window.showNotification('ERROR',res.data.message);
            } else if(res.data.status === 'success'){
                if(openAudio) {
                    setAudio(res.data.data.audioFile);
                    setDefaultAudio(res.data.data.audioFile);
                } else {
                    setDefaultValue(res.data);
                }
                window.showNotification('SUCCESS','Voicemail message changed');
                handleCrossPress(false);
            }
        });
    }

    const deleteSetting = () => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Are you sure to delete this voicemail setting?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setSaving(true);
                        deleteVirtualNumberSetting({id : item.id,setting : 'voicemail_message'}).then(res => {
                            setSaving(false);
                            if(res.data.status === 'validation-error') {
                                window.showNotification('ERROR',res.data.message[Object.keys(res.data.message)[0]][0]);
                            } else if(res.data.status === 'error') {
                                window.showNotification('ERROR',res.data.message);
                            } else if(res.data.status === 'success'){
                                setDefaultValue('');
                                setFieldValue('');
                                setOpenAudio(false);
                                setDefaultOpenAudio(false);
                                window.showNotification('SUCCESS','Voicemail Setting Deleted');
                                handleCrossPress(false);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {return}
                }
            ]
          });
    }

    const focusTextField = (toggle = false) => {
        setTimeout(() => {
            if(!openAudio || toggle) {
                inputField.current.focus();
            }
        }, 200);
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between" 
            >
                {validation &&
                    <small className="text-danger">{validationText}</small>
                }
            </div>
            <div className="text-field-item-vn-page call__whisper_message" onMouseEnter={()=>setMouseOver(true)} onMouseLeave={()=> setMouseOver(false)}>
                
                <If condition={edit}>
                    {
                        openAudio ? <AudioRecord voiceRecordCallback={(data) => setAudio(data)}/> : 
                        <input ref={inputField} className="text-field-vn-page text-field-vn-page-v2 text-gray" value={fieldValue ? fieldValue : ""} onKeyPress={handleEnterPress} onChange={e => setFieldValue(e.target.value)} type="text" defaultValue={fieldValue ? fieldValue : ""} placeholder={'Enter Voicemail Message'}/>
                    }
                </If>
                <If condition={!edit}>
                    {
                        openAudio ? 
                        <div className='audio-container'>
                        <div className='audio-scroll'>
                        <audio controls playsinline><source src={audio} type="audio/mpeg"/></audio>
                        </div> 
                        </div>: 
                        fieldValue ?  <BootstrapTooltip arrow title={fieldValue}>
                        <input className="text-field-vn-page text-field-vn-page-v2 text-gray" value={fieldValue ? fieldValue : ""} type="text" disabled={true} defaultValue={fieldValue ? fieldValue : ""}/>
                        </BootstrapTooltip> :
                          <input className="text-field-vn-page text-field-vn-page-v2 text-gray" value={fieldValue ? fieldValue : ""} type="text" disabled={true} defaultValue={fieldValue ? fieldValue : ""}/>
                    }
                </If>                

                <>
                {(!edit && !saving && mouseOver) &&
                    <>
                        {
                            ((defaultValue !== '' && defaultValue !== null) || defaultOpenAudio) &&
                            <BootstrapTooltip arrow title={`Delete Voicemail Setting`}>
                                <span className="small-round-icon-vn-page checkIcon" onClick={() => {deleteSetting()}}>{<DeleteIcon fontSize="small" />}</span>
                            </BootstrapTooltip>
                        }
                        <BootstrapTooltip arrow title={`Update Voicemail Message`}>
                            <span className="small-round-icon-vn-page" onClick={() => {setEdit(true);setMouseOver(false);focusTextField()}}>{<CreateIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                    </>
                }

                { saving &&
                    <BootstrapTooltip arrow title={`Updating Voicemail Message`}>
                        <span className="small-round-icon-vn-page" >{<MoreHorizIcon fontSize="small" />}</span>
                    </BootstrapTooltip>
                }

                {(edit && !saving) &&
                    <div>
                        {
                            openAudio ? 
                            <BootstrapTooltip arrow title="text">
                                <span className="small-round-icon-vn-page micIcon" onClick={() => {setOpenAudio(false);focusTextField(true)}}>{<CreateIcon fontSize="small" />}</span>
                            </BootstrapTooltip> :
                            <BootstrapTooltip arrow title="audio">
                                <span className="small-round-icon-vn-page micIcon" onClick={() => setOpenAudio(true)}>{<MicIcon fontSize="small" />}</span>
                            </BootstrapTooltip> 
                        }
                        <BootstrapTooltip arrow title="confirm">
                            <span className="small-round-icon-vn-page checkIcon" onClick={handleCheckPress}>{<DoneIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="cancel">
                            <span className="small-round-icon-vn-page cancelIconNew" onClick={handleCrossPress}>{<ClearIcon fontSize="small" />}</span>
                        </BootstrapTooltip>
                    </div>
                }
                </>
            </div>
        </>
    );
}

export default VoicemailMessageEdit;