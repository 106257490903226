import { sendRequest } from "./rootApi";
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const getProductFoldersApi = async () => {
  return sendRequest("get", Api.productFolders);
};
export const addProductFolderApi = async (payload) => {
  return sendRequest("post", Api.productFolders, payload);
};

export const deleteProductFolderApi = async (payload) => {
  return sendRequest("delete", `${Api.productFolders}/${payload}`);
};

export const updateProductFolderApi = async (payload) => {
  return sendRequest("put", `${Api.productFolders}/${payload.id}`, payload.payload);
};

export const getProductsApi = async ( payload ) => {
  return sendRequest("get", Api.userProducts, payload);
};

export const addProductApi = async (payload) => {
  return sendRequest("post", Api.userProducts, payload);
};

export const updateProductApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/${payload.id}`, payload.payload);
};

export const deleteProductApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/status/${payload.id}`, payload);
};

export const deleteProductForeverApi = async (payload) => {
  return sendRequest("delete", `${Api.userProducts}/${payload}`, payload);
};


export const toggleFavoriteApi = async (payload) => {
  return sendRequest("put", `${Api.userProducts}/is-favorite/${payload.id}`, payload);
}

export const getSalesGoalListApi = async (payload) => {
  return HttpRequest.get(Api.getAllSalesGoalList, payload);
};

export const addSalesGoalApi = async (payload) => {
  return HttpRequest.post(Api.addSalesGoal, payload);
};

export const updateSealsGoalApi = (payload) => {
  return HttpRequest.put(Api.updateSalesGoal, payload);
};

export const deleteSealsGoalApi = (payload) => {
  return HttpRequest.delete(Api.updateSalesGoal, payload);
};

export const userSaleGoalStats = (payload) => {
  return HttpRequest.get(Api.userSaleGoalStats, payload);
};

export const teamSalesReport = (payload) => {
  return HttpRequest.post(Api.teamSalesReport, payload);
};

export const productSalesReport = (payload) => {
  return HttpRequest.post(Api.productSalesReport, payload);
};

export const productSalesContact = (payload) => {
  return HttpRequest.post(Api.productSalesContact, payload);
};

export const salesVsGoalReport = (payload) => {
  return HttpRequest.get(Api.selesVsGoalReport, payload);
};

export const contactStageSearch = async (payload) => {
  return HttpRequest.post(Api.contactStageSearch, payload);
};

export const contactExport = async (payload) => {
  return HttpRequest.post(Api.contactExport, payload);
};

export const getTeamUsers = async (payload) => {
  return sendRequest("post", Api.getTeamUsers, payload.form_data);
};


  
export const productSalesExport = (payload) => {
  return HttpRequest.post(Api.productSalesExport, payload);
};

export const teamWiseSoldProducts = (payload) => {
  return HttpRequest.post(Api.teamWiseSoldProducts, payload);
};

export const teamWiseSoldProductExport = (payload) => {
  return HttpRequest.post(Api.teamWiseSoldProductExport, payload);
};

export const fetchPipelineApi = async (payload) => {
  return sendRequest("POST", Api.getAllPipeLine, payload);
};

export const getTeamUserListApiV2 = async (payload) => {
  return sendRequest("POST", Api.getTeamUserListV2, payload);
};

export const getPolicyTypeFoldersApi = async () => {
  return sendRequest("get", Api.userPolicyCategory);
};
export const addPolicyTypeFoldersApi = async (payload) => {
  return sendRequest("post", Api.userPolicyCategory, payload);
};
export const updatePolicyTypeFoldersApi = async (payload) => {
  return sendRequest("put", `${Api.userPolicyCategory}/${payload.id}`, payload.payload);
};
export const deletePolicyTypeFoldersApi = async (payload) => {
  return sendRequest("delete", `${Api.userPolicyCategory}/${payload}`);
};


export const getPolicyTypeApi = async ( payload ) => {
  return sendRequest("get", Api.userPolicy, payload);
};
export const addPolicyTypeApi = async ( payload ) => {
  return sendRequest("post", Api.userPolicy, payload);
};
export const updatePolicyTypeApi = async ( payload ) => {
  return sendRequest("put", `${Api.userPolicy}/${payload.id}`, payload);
};
export const deletePolicyTypeApi = async (payload) => {
  return sendRequest("put", `${Api.userPolicy}/status/${payload.id}`, payload);
};
export const updatePolicyTypeStatusApi = async ( payload ) => {
  return sendRequest("put", `${Api.userPolicy}/status/${payload.id}`, payload);
};
export const togglePolicyTypeFavoriteApi = async (payload) => {
  return sendRequest("put", `${Api.userPolicy}/is-favorite/${payload.id}`, payload);
}
export const deletePolicyTypeForeverApi = async (payload) => {
  return sendRequest("delete", `${Api.userPolicy}/${payload}`, payload);
};


export const getInsuranceCompanyFoldersApi = async () => {
  return HttpRequest.get(Api.userInsuranceCompanyCategory);
};
export const addInsuranceCompanyFoldersApi = async ( payload ) => {
  return HttpRequest.post(Api.userInsuranceCompanyCategory, payload);
};
export const updateInsuranceCompanyFoldersApi = async ( payload ) => {
  return HttpRequest.put(Api.userInsuranceCompanyCategory, payload);
};
export const deleteInsuranceCompanyFoldersApi = async ( payload ) => {
  return HttpRequest.delete(Api.userInsuranceCompanyCategory, payload);
};


export const getInsuranceCompanyApi = async (payload) => {
  return HttpRequest.get(Api.userInsuranceCompany, payload);
};
export const addInsuranceCompanyApi = async ( payload ) => {
  return HttpRequest.post(Api.userInsuranceCompany, payload);
};
export const updateInsuranceCompanyStatusApi = async ( payload ) => {
  return HttpRequest.post(`${Api.userInsuranceCompany}/select`, payload);
};
export const updateInsuranceCompanyNameApi = async ( payload ) => {
  return HttpRequest.put(Api.userInsuranceCompany, payload);
};
export const deleteInsuranceCompanyApi = async ( payload ) => {
  return HttpRequest.delete(Api.userInsuranceCompany, payload);
};

export const getLostReasonsApi = async ( payload ) => {
  return HttpRequest.get(Api.lostReason, payload);
};

export const addLostReasonApi = async ( payload ) => {
  return HttpRequest.post(Api.lostReason, payload);
};

export const updateLostReasonApi = async ( payload ) => {
  return HttpRequest.put(Api.lostReason, payload);
};

export const deleteLostReasonApi = async ( payload ) => {
  return HttpRequest.delete(Api.lostReason, payload);
};

export const getRecycleEventApi = async ( payload ) => {
  return HttpRequest.get(Api.recycleEvent, payload);
};

export const addRecycleEvent = async ( payload ) => {
  return HttpRequest.post(Api.recycleEvent, payload);
};

export const updateRecycleEvent = async ( payload ) => {
  return HttpRequest.put(Api.recycleEvent, payload);
};

export const deleteRecycleEventApi = async ( payload ) => {
  return HttpRequest.delete(Api.recycleEvent, payload);
};

