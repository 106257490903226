import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddOrEditLostReasonModal from "../AddOrEditLostReasonModal";
import CustomConfirmAlert from "../../../common/confirm-alert/CustomConfirmAlert";
import {useDispatch} from "react-redux";
import {deleteLostReason} from "../../../../../actions/ProductAction";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));


const EachLostReasonTableItem = ({ policy }) => {
  const { id, reason, deletable } = policy || {};
  const dispatch = useDispatch();
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);

  const handleEditLostReason = () => {
    setOpenAddOrEditModal(true);
  };

  const confirmDeleteLostReason = async () => {
    dispatch(deleteLostReason({ lost_deal_reason_id: id }));
  };

  const handleDeleteLostReason = () => {
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteLostReason(),
      title: "Are you sure you want to delete this lost reason?",
      description: "You cannot undo this action",
    });
  };

  return (
    <TableRowWrapper>
      <TableCell style={{width:'80%'}}>
        <div className="titleWrap">{reason}</div>
      </TableCell>
      {/*<TableCell align="right">*/}
      {/*  <SwitchC*/}
      {/*    status={status}*/}
      {/*    brandColor={"#346fef"}*/}
      {/*    onChange={() => setStatus(!status)}*/}
      {/*  />*/}
      {/*</TableCell>*/}
      <TableCell style={{width:'20%'}}>
        {
          deletable &&
          ( 
            <div style={{display:'flex',alignItems:'center'}}>
                <IconButton onClick={handleEditLostReason}>
                  <EditIcon fontSize="small" className="icon" />
                </IconButton>
                <IconButton onClick={handleDeleteLostReason}>
                  <DeleteIcon style={{color:"red"}} fontSize="small" className="icon" />
                </IconButton>
            </div>
          )}
        
      </TableCell>

      {openAddOrEditModal && (
        <AddOrEditLostReasonModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={policy}
        />
      )}
    </TableRowWrapper>
  );
};

export default EachLostReasonTableItem;
