export const HOME_PAGE_TABS = {
  DASHBOARD: 1,
  PRODUCTS: 2,
  GOALS: 3,
  TEAM_SALES_REPORT: 4,
  PRODUCT_SALES_REPORT: 5,
  SALES_VS_GOAL_REPORT: 6,
  DEAL_REPORT:7,
  POLICY_TYPES: 8,
  INSURANCE_COMPANIES: 9,
};

export const COLOR = {
  primary: "#346fef",
  secondary: "#924cff",
  warning: "#f79009",
  success: "#008951",
  info: "#00bec5",
  error: "#ec131f",
  gray: "rgba(148, 157, 178, 0.12)",
  textPrimary: "#1d2939",
  textSecondary: "#475467",
};

export const FREQUENCY = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  YEARLY: "YEARLY"
};

export const USER_SELECTOR = {
  ALL: "__all__",
  CLEAR_ALL: "__clear_all__",

};