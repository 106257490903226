import React, {Component} from "react";
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import Utils from "../../helpers/Utils";

let recordAudio;
let videoStream;
var totalSeconds = 0;

function pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
        return "0" + valString;
    } else {
        return valString;
    }
}

class AudioRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRecordPlayer: false,
            showRecordedPlayer: false,
            showSaveButton: false,
            showStopButton: false,
            showStartButton: true,
            showRefreshButton: false,
            startCount: false,
            videoUrl: '',
            minutes: '00',
            seconds: '00',
            showRecordingaudio:false,
            uploadfile:false,
        }

        this.inputOpenFileRef = React.createRef();
    }

    calculateTime = () => {
        this.myIntervat = setInterval(() => {
            ++totalSeconds;
            this.setState(prevState => ({
                    startCount: false,
                    seconds: pad(totalSeconds % 60),
                    minutes: pad(parseInt(totalSeconds / 60))
                })
            );
            if (this.state.seconds > 300) {
                this.stopRecord();
            }
        }, 1000);
    }
    requestUserMedia = () => {
        let self = this;
        videoStream=null;
        navigator.mediaDevices.getUserMedia({audio: true})
            .then(function (mediaStream) {

                self.setState({
                    showStartButton: false,
                    showStopButton: true,
                    showRecordedPlayer: false,
                    showSaveButton: false,
                    showRefreshButton: false,
                    startCount: true,
                    minutes: '00',
                    seconds: '00',
                    videoUrl: '',
                    audioFile: [],
                    showRecordingaudio:true,
                    uploadfile:false,
                }, () => {
                    let audio = document.getElementById('record-audio');
                    audio.srcObject=mediaStream;
                    videoStream = mediaStream;
                    audio.onloadedmetadata = function(e) {
                        audio.play();
                        };
                });
                self.startRecord()
            })
            .catch(function (err) {
                self.setState({loader: 0, welcomeStatus: 2});
                return 0;
            });
    }
    showAudio=()=>{
        var sound = document.createElement('audio');
        sound.controls = 'controls';
        sound.src      = videoStream;
    }
    startRecord = () => {
        totalSeconds = 0;
        this.calculateTime();
        recordAudio = RecordRTC(videoStream, {type: 'audio/webm', mimeType: 'audio/webm', recorderType: StereoAudioRecorder, audioBitsPerSecond: 128000});
        recordAudio.startRecording();

    }
    openMic = (e) => {
        e.preventDefault();
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia({audio: true}) || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            this.setState({welcomeStatus: 2});
            return 0;
        }


        if (!hasGetUserMedia) {
            this.setState({welcomeStatus: 2});
            return 0;
        }

        this.setState({loader: 1}, () => {
            this.requestUserMedia();
        });
    }
    stopRecord = () => {
        // e.preventDefault();
        if (this.state.seconds < 5) {
            Utils.showNotification("Audio duration must be between 5 seconds to 5 minutes", "error");
            return false;
        }
        clearInterval(this.myIntervat);
        recordAudio.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordAudio.blob);
            videoStream.getTracks().forEach(track => track.stop());
            // this.convertAudio(videoUrl);

            let mimeType = 'audio/webm';
            let blob = recordAudio instanceof Blob ? recordAudio : recordAudio.getBlob();
            blob = new File([blob], 'RecordRTC-audio.webm', {
                type: mimeType
            });
            this.setState({
                videoUrl: videoUrl,
                showRecordedPlayer: true,
                showRefreshButton: true,
                showStopButton: false,
                showStartButton: true,
                showRecordingaudio:false,
                uploadfile:false,
            });
            this.props.voiceRecordCallback(blob);
            return false;
        });
    }

    saverecord = () => {
        // this.props.callBack();
    }
    reFreshData=()=>{
        this.setState({
            showStartButton: true,
            showStopButton: false,
            showRecordedPlayer: false,
            showSaveButton: false,
            showRefreshButton: false,
            startCount: true,
            minutes: '00',
            seconds: '00',
            videoUrl: '',
            showRecordingaudio:false,
            uploadfile:false,
        });
    }
    componentDidMount(){
        if(videoStream){
            videoStream.getTracks().forEach(track => track.stop());
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(totalSeconds>=300){
            this.stopRecord();
        }
    }
    voiceUploadCallback=(e)=>{
        e.preventDefault();
        let file = this.inputOpenFileRef.current.files[0];
        if(file.size > 5242880){
            alert("File is too big!");
            return false;
        };
        this.props.voiceRecordCallback(file);
        this.setState({uploadfile:true,showRecordedPlayer:false},()=>{});


        if (e.target.value) {
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                let preview = document.getElementById("voice_file_preview-for-backend");
                preview.src = reader.result;
                let previewVoiceForBackend = document.getElementById('preview-voice-for-backend');

                if (previewVoiceForBackend) {
                    document.getElementById('preview-voice-for-backend').classList.remove("d-none");
                    document.getElementById('preview-voice-for-backend').classList.add("d-flex");
                }
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    }
    uploadFrompc=(e)=>{
        e.preventDefault();
        this.inputOpenFileRef.current.click();
    }

    render() {
        return (
            <React.Fragment>
                <div className="audio-voice">
                    <div className="mb-0 row">
                        <div className="col s12">
                            <div className="card virtual_number_audio_card">
                                <p className="title">Record/Upload a new audio file</p>
                                <div className="audio-wrapper">
                                    <div className="audio-icons d-flex">
                                        <div className="icon-record" disabled={this.state.showStartButton?false:true} onClick={this.state.showStartButton?this.openMic:null}>
                                            <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/audio.png`} alt="audio" className=" responsive-img"/>
                                            <span>Record</span>
                                        </div>
                                        <div className="icon-record" disabled={this.state.showStopButton?false:true} onClick={this.state.showStopButton?this.stopRecord:null}>
                                            <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/stop.png`} alt="stop" className=" responsive-img"/>
                                            <span>Stop</span>
                                        </div>
                                        <div className="icon-record" onClick={this.uploadFrompc}>
                                            <img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/upload.png`} alt="upload" className=" responsive-img"/>
                                            <span>Upload</span>
                                        </div>
                                        <input onChange={this.voiceUploadCallback} type="file" ref={this.inputOpenFileRef} accept="audio/mp3, video/webm" className={'mb-2'} style={{'visibility':'hidden'}}/>
                                    </div>
                                    <div className="audio-setup mt-2">
                                        {
                                            this.state.showRecordingaudio && (
                                                <audio controls='controls' id='record-audio' muted />
                                            )
                                        }
                                        {
                                            this.state.showRecordedPlayer && (
                                                <span className='2'>
                                                    <audio controls="controls" src={this.state.videoUrl} />
                                                </span>
                                            )
                                        }
                                        {
                                            this.state.uploadfile && (
                                                <span className='3'>
                                                    <audio controls id={'voice_file_preview-for-backend'} src=""/>
                                                </span>
                                            )
                                        }
                                    </div>
                                </div>
                                <p className="alert-title d-flex align-items-center"><i
                                    className="material-icons mr-2">info_outline</i>Audio duration must
                                    be
                                    between
                                    5
                                    seconds to 5 minutes</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AudioRecord;
