import {useEffect, useState} from "react"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {getSnapShotDetails} from "../../api/profileApi";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const Step2 = ({handleBack, data, handleUpdateData, handleStep, activeSnapShot, globalTemplatesObj, subUser, removePreve=false, removeNext=false, removeSkipe=false}) => {

    const [snapShotDetails, setSnapShotDetails] = useState([]);

    const [globalTempValue, setGlobalTempValue] = useState("1");

    /*campaign temp states start*/
    const [campaignTempValue, setCampaignTempValue] = useState("1");
    const [campaignTempOptionValue, setCampaignTempOptionValue] = useState({});
    const [campaignLoaded, setCampaignLoaded] = useState(false);
    const [backDataLoad, setBackDataLoad] = useState(false);
    /*campaign temp states end*/

    /*custom field states start*/
    const [customFieldTempValue, setCustomFieldTempValue] = useState("1");
    const [customFieldTempOptionValue, setCustomFieldTempOptionValue] = useState({});
    const [customFieldLoaded, setCustomFieldLoaded] = useState(false);
    /*custom field states end*/

    /*personalized field states start*/
    const [personalizedFieldTempValue, setPersonalizedFieldTempValue] = useState("1");
    const [personalizedFieldTempOptionValue, setPersonalizedFieldTempOptionValue] = useState({});
    const [personalizedFieldLoaded, setPersonalizedFieldLoaded] = useState(false);
    /*personalized field states end*/

    /*Calendar field states start*/
    const [calendarTempValue, setCalendarTempValue] = useState("1");
    const [calendarTempOptionValue, setCalendarTempOptionValue] = useState({});
    const [calendarFieldLoaded, setCalendarFieldLoaded] = useState(false);
    /*Calenda field states end*/

    /*form temp states start, form_info*/
    const [formTempValue, setFormTempValue] = useState("1");
    const [formTempOptionValue, setFormTempOptionValue] = useState({});
    const [formFieldLoaded, setFormFieldLoaded] = useState(false);
    /*form temp states end*/

    /*lead flow temp states start, leadflow_info*/
    const [leadFlowTempValue, setLeadFlowTempValue] = useState("1");
    const [leadFlowTempOptionValue, setLeadFlowTempOptionValue] = useState({});
    const [leadFlowLoaded, setLeadFlowLoaded] = useState(false);
    /*lead flow temp states end*/

    /*Tags temp states start, tag_info*/
    const [tagFieldTempValue, setTagFieldTempValue] = useState("1");
    const [tagFieldTempOptionValue, setTagFieldTempOptionValue] = useState({});
    const [tagFieldLoaded, setTagFieldLoaded] = useState(false);
    /*Tags temp states end*/

    /*Triggers temp states start, trigger_info*/
    const [triggerTempValue, setTriggerTempValue] = useState("1");
    const [triggerTempOptionValue, setTriggerTempOptionValue] = useState({});
    const [triggerLoaded, setTriggerLoaded] = useState(false);
    /*Tags temp states end*/

    /*Custom forms temp states start, custom_form_info*/
    const [customFormTempValue, setCustomFormTempValue] = useState("1");
    const [customFormTempOptionValue, setCustomFormTempOptionValue] = useState({});
    const [customFormLoaded, setCustomFormLoaded] = useState(false);
    /*Custom forms states end*/

    /*Quick Replies temp states start, quick_reply_info*/
    const [quickReplyTempValue, setQuickReplyTempValue] = useState("1");
    const [quickReplyTempOptionValue, setQuickReplyTempOptionValue] = useState({});
    const [quickReplyLoaded, setQuickReplyLoaded] = useState(false);
    /*Quick Replies states end*/

    /*Pipelines & Stages temp states start, pipeline_info*/
    const [pipelineTempValue, setPipelineTempValue] = useState("1");
    const [pipelineTempOptionValue, setPipelineTempOptionValue] = useState({});
    const [pipelineLoaded, setPipelineFieldLoaded] = useState(false);
    /*Pipeline & Stages forms states end*/

    /*Multi page forms temp states start, custom_form_info*/
    const [multiPageFormTempValue, setMultiPageFormTempValue] = useState("1");
    const [multiPageFormTempOptionValue, setMultiPageFormTempOptionValue] = useState({});
    const [multiPageFormLoaded, setMultiPageFormLoaded] = useState(false);
    /*Multi page forms states end*/

    const [backData, setBackData] = useState(false);

    //START LOAD SNAPSHOT DETAILS//
    useEffect(()=>{
        setBackData(false);
        if(activeSnapShot && activeSnapShot.value !== undefined){
            loadSnapShotDetails(activeSnapShot.value);
        }
        if(globalTemplatesObj.campaignTempValue !== undefined){
            setBackDataLoad(true);
            setCampaignTempValue(globalTemplatesObj.campaignTempValue);
            setCustomFieldTempValue(globalTemplatesObj.customFieldTempValue);
            setFormTempValue(globalTemplatesObj.formTempValue);
            setTagFieldTempValue(globalTemplatesObj.tagFieldTempValue);
            setTriggerTempValue(globalTemplatesObj.triggerTempValue);
            setCustomFormTempValue(globalTemplatesObj.customFormTempValue);
            setMultiPageFormTempValue(globalTemplatesObj.multiPageFormTempValue);
            setQuickReplyTempValue(globalTemplatesObj.quickReplyTempValue);
            setPipelineTempValue(globalTemplatesObj.pipelineTempValue);
            setPersonalizedFieldTempValue(globalTemplatesObj.personalizedFieldTempValue);
            setCalendarTempValue(globalTemplatesObj.calendarTempValue);
        }
    }, [activeSnapShot]);

    const loadSnapShotDetails = (snapShotId) => {
        getSnapShotDetails({
            "form_data": {
                snapShotId: snapShotId
            }
        })
            .then(response => {
                setSnapShotDetails(response.data.data);
            }).finally(() => {
        });
    }

    useEffect(()=>{
        campaignTempGlobalChange();
        customFieldTempGlobalChange();
        personalizedFieldTempGlobalChange();
        calendarTempGlobalChange();
        formTempGlobalChange();
        leadFlowTempGlobalChange();
        tagFieldTempGlobalChange();
        triggerTempGlobalChange();
        customFormTempGlobalChange();
        quickReplyTempGlobalChange();
        pipeLineTempGlobalChange();
        multiPageFormTempGlobalChange();
    }, [snapShotDetails])
    
    //END LOAD SNAPSHOT DETAILS//

    useEffect(()=>{
        setCampaignTempValue(globalTempValue);
        setCustomFieldTempValue(globalTempValue);
        setFormTempValue(globalTempValue);
        setTagFieldTempValue(globalTempValue);
        setTriggerTempValue(globalTempValue);
        setCustomFormTempValue(globalTempValue);
        setMultiPageFormTempValue(globalTempValue);
        setQuickReplyTempValue(globalTempValue);
        setPipelineTempValue(globalTempValue);
        setPersonalizedFieldTempValue(globalTempValue);
        setCalendarTempValue(globalTempValue);
    }, [globalTempValue]);

    /*Start campaign temp*/
    useEffect(()=>{
        campaignTempGlobalChange();
    }, [campaignTempValue]);

    const campaignTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.campaign_info !== undefined){
            setCampaignLoaded(false);
            let campaignTemp = JSON.parse(snapShotDetails.campaign_info);
            const obj = {};

            if(globalTemplatesObj.campaignTempOptionValue !== undefined && backDataLoad == true) {
                campaignTemp.forEach(e => {
                    obj['camp-'+e['id']] = globalTemplatesObj.campaignTempOptionValue['camp-'+e['id']];
                })
            }else{
                campaignTemp.forEach(e => {
                    obj['camp-'+e['id']] = campaignTempValue;
                })
            }

            setCampaignTempOptionValue({ ...campaignTempOptionValue, ...obj});
            setCampaignLoaded(true);
        }
    }

    const changeCampaignTempIndividualOptionValue= (itemId, value) => {
        setBackDataLoad(false);
        let objProperty = 'camp-'+itemId;
        setCampaignTempOptionValue({ ...campaignTempOptionValue, [objProperty] : value});
    }

    const snapShotCampaigns = () => {
        if(snapShotDetails.campaign_info !== undefined){
            let campaignTemp = JSON.parse(snapShotDetails.campaign_info);

            return campaignTemp.map((item)=>{
                let optionValue = campaignTempOptionValue['camp-'+item.id];

                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeCampaignTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeCampaignTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }    
    /*End campaign temp*/

    /*Start custom field temp*/
    useEffect(()=>{
        customFieldTempGlobalChange();
    }, [customFieldTempValue]);

        const customFieldTempGlobalChange = () => {
            if(snapShotDetails && snapShotDetails.custom_field_info !== undefined){
                setCustomFieldLoaded(false);
                let customFieldTemp = JSON.parse(snapShotDetails.custom_field_info);
                const obj = {};

                if(globalTemplatesObj.customFieldTempOptionValue !== undefined && backDataLoad == true) {
                    customFieldTemp.forEach(e => {
                        obj['custom-field-'+e['id']] = globalTemplatesObj.customFieldTempOptionValue['custom-field-'+e['id']];
                    })
                }else{
                    customFieldTemp.forEach(e => {
                        obj['custom-field-'+e['id']] = customFieldTempValue;
                    })
                }

                setCustomFieldTempOptionValue({ ...customFieldTempOptionValue, ...obj});
                setCustomFieldLoaded(true);
            }
        }

    const changeCustomFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'custom-field-'+itemId;
        setCustomFieldTempOptionValue({ ...customFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotCustomFields = () => {
        if(snapShotDetails.custom_field_info !== undefined){
            let customFieldTemp = JSON.parse(snapShotDetails.custom_field_info);

            return customFieldTemp.map((item)=>{
                let optionValue = customFieldTempOptionValue['custom-field-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeCustomFieldTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeCustomFieldTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }
    /*End Custom field temp*/

    /*Start personalized field temp*/
    useEffect(()=>{
        personalizedFieldTempGlobalChange();
    }, [personalizedFieldTempValue]);

    const personalizedFieldTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.personalized_field_info !== undefined){
            setPersonalizedFieldLoaded(false);
            let personalizedFieldTemp = JSON.parse(snapShotDetails.personalized_field_info);
            const obj = {};

            if(globalTemplatesObj.personalizedFieldTempOptionValue !== undefined && backDataLoad == true) {
                personalizedFieldTemp.forEach(e => {
                    obj['personalized-field-'+e['id']] = globalTemplatesObj.personalizedFieldTempOptionValue['personalized-field-'+e['id']];
                })
            }else{
                personalizedFieldTemp.forEach(e => {
                    obj['personalized-field-'+e['id']] = personalizedFieldTempValue;
                })
            }

            setPersonalizedFieldTempOptionValue({ ...personalizedFieldTempOptionValue, ...obj});
            setPersonalizedFieldLoaded(true);
        }
    }

    const changePersonalizedFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'personalized-field-'+itemId;
        setPersonalizedFieldTempOptionValue({ ...personalizedFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotPersonalizedFields = () => {
        if(snapShotDetails.personalized_field_info !== undefined){
            let personalizedFieldTemp = JSON.parse(snapShotDetails.personalized_field_info);

            return personalizedFieldTemp.map((item)=>{
                let optionValue = personalizedFieldTempOptionValue['personalized-field-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changePersonalizedFieldTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changePersonalizedFieldTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }


    /*End personalized field temp*/

    /*Start calendar temp*/

    useEffect(()=>{
        calendarTempGlobalChange();
    }, [calendarTempValue]);

    const calendarTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.calendar_info !== undefined){
            setCalendarFieldLoaded(false);

            let calendartemp = JSON.parse(snapShotDetails.calendar_info);
            const obj = {};

            if(globalTemplatesObj.calendarTempValue !== undefined && backDataLoad == true) {
                calendartemp.forEach(e => {
                    obj['calendar-'+e['id']] = globalTemplatesObj.calendarTempValue['calendar-'+e['id']];
                })
            }else{
                calendartemp.forEach(e => {
                    obj['calendar-'+e['id']] = calendarTempValue;
                })
            }

            setCalendarTempOptionValue({ ...calendarTempOptionValue, ...obj});

            setCalendarFieldLoaded(true);
        }
    }

    const changeCalendarTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'calendar-'+itemId;
        setCalendarTempOptionValue({ ...calendarTempOptionValue, [objProperty] : value});
    }

    const snapShotCalendars = () => {
        if(snapShotDetails.calendar_info !== undefined){
            let calendarTemp = JSON.parse(snapShotDetails.calendar_info);

            return calendarTemp.map((item)=>{
                let optionValue = calendarTempOptionValue['calendar-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeCalendarTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeCalendarTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }


    /*End calendar temp*/

    /*Start form field temp*/
    useEffect(()=>{
        formTempGlobalChange();
    }, [formTempValue]);

    const formTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.form_info !== undefined){
            setFormFieldLoaded(false);
            let formTemp = JSON.parse(snapShotDetails.form_info);
            const obj = {};

            if(globalTemplatesObj.formTempOptionValue !== undefined && backDataLoad == true) {
                formTemp.forEach(e => {
                    obj['form-'+e['id']] = globalTemplatesObj.formTempOptionValue['form-'+e['id']];
                })
            }else{
                formTemp.forEach(e => {
                    obj['form-'+e['id']] = formTempValue;
                })
            }

            setFormTempOptionValue({ ...formTempOptionValue, ...obj});
            setFormFieldLoaded(true);
        }
    }

    const changeFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'form-'+itemId;
        setFormTempOptionValue({ ...formTempOptionValue, [objProperty] : value});
    }

    const snapShotForms = () => {
        if(snapShotDetails.form_info !== undefined){
            let formTemp = JSON.parse(snapShotDetails.form_info);

            return formTemp.map((item)=>{
                let optionValue = formTempOptionValue['form-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeFormTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeFormTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }
    /*End Form field temp*/

    /*Start lead flow temp*/
    useEffect(()=>{
        leadFlowTempGlobalChange();
    }, [leadFlowTempValue]);

    const leadFlowTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.leadflow_info !== undefined){
            setLeadFlowLoaded(false);
            let leadFlowTemp = JSON.parse(snapShotDetails.leadflow_info);
            const obj = {};

            if(globalTemplatesObj.leadFlowTempOptionValue !== undefined && backDataLoad == true) {
                leadFlowTemp.forEach(e => {
                    obj['lead-flow-'+e['id']] = globalTemplatesObj.leadFlowTempOptionValue['lead-flow-'+e['id']];
                })
            }else{
                leadFlowTemp.forEach(e => {
                    obj['lead-flow-'+e['id']] = leadFlowTempValue;
                })
            }

            setLeadFlowTempOptionValue({ ...leadFlowTempOptionValue, ...obj});
            setLeadFlowLoaded(true);
        }
    }

    const changeLeadFlowTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'lead-flow-'+itemId;
        setLeadFlowTempOptionValue({ ...leadFlowTempOptionValue, [objProperty] : value});
    }

    const snapShotLeadFlows = () => {
        if(snapShotDetails.leadflow_info !== undefined){
            let leadFlowTemp = JSON.parse(snapShotDetails.leadflow_info);

            return leadFlowTemp.map((item)=>{
                let optionValue = leadFlowTempOptionValue['lead-flow-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeLeadFlowTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeLeadFlowTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }
    }
    /*End lead flow temp*/

    /*Start tags temp*/
    useEffect(()=>{
        tagFieldTempGlobalChange();
    }, [tagFieldTempValue]);

    const tagFieldTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.tag_info !== undefined){
            setTagFieldLoaded(false);
            let tagFieldTemp = JSON.parse(snapShotDetails.tag_info);
            const obj = {};

            if(globalTemplatesObj.tagFieldTempOptionValue !== undefined && backDataLoad == true) {
                tagFieldTemp.forEach(e => {
                    obj['tag-'+e['id']] = globalTemplatesObj.tagFieldTempOptionValue['tag-'+e['id']];
                })
            }else{
                tagFieldTemp.forEach(e => {
                    obj['tag-'+e['id']] = tagFieldTempValue;
                })
            }

            setTagFieldTempOptionValue({ ...tagFieldTempOptionValue, ...obj});
            setTagFieldLoaded(true);
        }
    }

    const changeTagFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'tag-'+itemId;
        setTagFieldTempOptionValue({ ...tagFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotTags = () => {
        if(snapShotDetails.tag_info !== undefined){
            let tagFieldTemp = JSON.parse(snapShotDetails.tag_info);

            return tagFieldTemp.map((item)=>{
                let optionValue = tagFieldTempOptionValue['tag-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.name}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.name.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeTagFieldTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeTagFieldTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }
    /*End tags temp*/

    /*Start trigger temp*/
    useEffect(()=>{
        triggerTempGlobalChange();
    }, [triggerTempValue]);

    const triggerTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.trigger_info !== undefined){
            setTriggerLoaded(false);
            let triggerTemp = JSON.parse(snapShotDetails.trigger_info);
            const obj = {};

            if(globalTemplatesObj.triggerTempOptionValue !== undefined && backDataLoad == true) {
                triggerTemp.forEach(e => {
                    obj['trigger-'+e['id']] = globalTemplatesObj.triggerTempOptionValue['trigger-'+e['id']];
                })
            }else{
                triggerTemp.forEach(e => {
                    obj['trigger-'+e['id']] = triggerTempValue;
                })
            }

            setTriggerTempOptionValue({ ...triggerTempOptionValue, ...obj});
            setTriggerLoaded(true);
        }
    }

    const changeTriggerTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'trigger-'+itemId;
        setTriggerTempOptionValue({ ...triggerTempOptionValue, [objProperty] : value});
    }

    const snapShotTriggers = () => {
        if(snapShotDetails.trigger_info !== undefined){
            let triggerTemp = JSON.parse(snapShotDetails.trigger_info);

            return triggerTemp.map((item)=>{
                let optionValue = triggerTempOptionValue['trigger-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeTriggerTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeTriggerTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }
    /*End trigger temp*/

    /*Start custom form temp*/

    useEffect(()=>{
        customFormTempGlobalChange();
    }, [customFormTempValue]);

    const customFormTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.custom_form_info !== undefined){
            setCustomFormLoaded(false);
            let customFormTemp = JSON.parse(snapShotDetails.custom_form_info);
            const obj = {};

            if(globalTemplatesObj.customFormTempOptionValue !== undefined && backDataLoad == true) {
                customFormTemp.forEach(e => {
                    obj['custom-form-'+e['id']] = globalTemplatesObj.customFormTempOptionValue['custom-form-'+e['id']];
                })
            }else{
                customFormTemp.forEach(e => {
                    obj['custom-form-'+e['id']] = customFormTempValue;
                })
            }

            setCustomFormTempOptionValue({ ...customFormTempOptionValue, ...obj});
            setCustomFormLoaded(true);
        }
    }

    const changeCustomFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'custom-form-'+itemId;
        setCustomFormTempOptionValue({ ...customFormTempOptionValue, [objProperty] : value});
    }

    const snapShotCustomForm = () => {
        if(snapShotDetails.custom_form_info !== undefined){
            let customFormTemp = JSON.parse(snapShotDetails.custom_form_info);

            return customFormTemp.map((item)=>{
                let optionValue = customFormTempOptionValue['custom-form-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeCustomFormTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeCustomFormTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }

    /*End custom form*/

    /*Start quick reply temp*/

    useEffect(()=>{
        quickReplyTempGlobalChange();
    }, [quickReplyTempValue]);

    const quickReplyTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.quick_reply_info !== undefined){
            setQuickReplyLoaded(false);
            let quickReplyTemp = JSON.parse(snapShotDetails.quick_reply_info);
            const obj = {};

            if(globalTemplatesObj.quickReplyTempOptionValue !== undefined && backDataLoad == true) {
                quickReplyTemp.forEach(e => {
                    obj['quick-reply-'+e['id']] = globalTemplatesObj.quickReplyTempOptionValue['quick-reply-'+e['id']];
                })
            }else{
                quickReplyTemp.forEach(e => {
                    obj['quick-reply-'+e['id']] = quickReplyTempValue;
                })
            }

            setQuickReplyTempOptionValue({ ...quickReplyTempOptionValue, ...obj});
            setQuickReplyLoaded(true);
        }
    }

    const changeQuickReplyTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'quick-reply-'+itemId;
        setQuickReplyTempOptionValue({ ...quickReplyTempOptionValue, [objProperty] : value});
    }

    const snapShotQuickReply = () => {
        if(snapShotDetails.quick_reply_info !== undefined){
            let quickReplyTemp = JSON.parse(snapShotDetails.quick_reply_info);

            return quickReplyTemp.map((item)=>{
                let optionValue = quickReplyTempOptionValue['quick-reply-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeQuickReplyTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeQuickReplyTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }

    /*End quick reply temp*/

    /*Start pipeline temp*/

    useEffect(()=>{
        pipeLineTempGlobalChange();
    }, [pipelineTempValue]);

    const pipeLineTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.pipeline_info !== undefined){
            setPipelineFieldLoaded(false);
            let pipelineTemp = JSON.parse(snapShotDetails.pipeline_info);
            const obj = {};

            if(globalTemplatesObj.pipelineTempOptionValue !== undefined && backDataLoad == true) {
                pipelineTemp.forEach(e => {
                    obj['pipeline-'+e['id']] = globalTemplatesObj.pipelineTempOptionValue['pipeline-'+e['id']];
                })
            }else{
                pipelineTemp.forEach(e => {
                    obj['pipeline-'+e['id']] = pipelineTempValue;
                })
            }

            setPipelineTempOptionValue({ ...pipelineTempOptionValue, ...obj});
            setPipelineFieldLoaded(true);
        }
    }

    const changePipelineTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'pipeline-'+itemId;
        setPipelineTempOptionValue({ ...pipelineTempOptionValue, [objProperty] : value});
    }

    const snapShotPipelines = () => {
        if(snapShotDetails.pipeline_info !== undefined){
            let pipelineTemp = JSON.parse(snapShotDetails.pipeline_info);

            return pipelineTemp.map((item)=>{
                let optionValue = pipelineTempOptionValue['pipeline-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changePipelineTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changePipelineTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }

    /*End pipeline temp*/

    /*Start multi page form temp*/

    useEffect(()=>{
        multiPageFormTempGlobalChange();
    }, [multiPageFormTempValue]);

    const multiPageFormTempGlobalChange = () => {
        if(snapShotDetails && snapShotDetails.multi_page_form_info !== undefined){
            setMultiPageFormLoaded(false);
            let multiPageFormTemp = JSON.parse(snapShotDetails.multi_page_form_info);
            const obj = {};

            if(globalTemplatesObj.multiPageFormTempOptionValue !== undefined && backDataLoad == true) {
                multiPageFormTemp.forEach(e => {
                    obj['multi-page-form-'+e['id']] = globalTemplatesObj.multiPageFormTempOptionValue['multi-page-form-'+e['id']];
                })
            }else{
                multiPageFormTemp.forEach(e => {
                    obj['multi-page-form-'+e['id']] = multiPageFormTempValue;
                })
            }

            setMultiPageFormTempOptionValue({ ...multiPageFormTempOptionValue, ...obj});
            setMultiPageFormLoaded(true);
        }
    }

    const changeMultiPageFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'multi-page-form-'+itemId;
        setMultiPageFormTempOptionValue({ ...multiPageFormTempOptionValue, [objProperty] : value});
    }

    const snapShotMultiPageForm = () => {
        if(snapShotDetails.multi_page_form_info !== undefined){
            let multiPageFormTemp = JSON.parse(snapShotDetails.multi_page_form_info);

            return multiPageFormTemp.map((item)=>{
                let optionValue = multiPageFormTempOptionValue['multi-page-form-'+item.id];
                return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                    {item.title}
                    {removeSkipe==false && 
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={optionValue}
                            name={item.id+'+'+item.title.replace(' ', '-')}
                        >
                            <FormControlLabel onClick={()=>changeMultiPageFormTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                            <FormControlLabel onClick={()=>changeMultiPageFormTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                        </RadioGroup>
                    </FormControl>
                    }
                </li>)
            })
        }

    }

    /*End multi page form*/


    const handleNextClick = () => {
        let globalTemplatesObj = {
            campaignTempOptionValue,
            customFieldTempOptionValue,
            formTempOptionValue,
            leadFlowTempOptionValue,
            tagFieldTempOptionValue,
            triggerTempOptionValue,
            customFormTempOptionValue,
            multiPageFormTempOptionValue,
            quickReplyTempOptionValue,
            pipelineTempOptionValue,
            personalizedFieldTempOptionValue,
            calendarTempOptionValue,
            campaignTempValue,
            customFieldTempValue,
            formTempValue,
            leadFlowTempValue,
            tagFieldTempValue,
            triggerTempValue,
            customFormTempValue,
            multiPageFormTempValue,
            quickReplyTempValue,
            pipelineTempValue,
            personalizedFieldTempValue,
            calendarTempValue
        };
        handleStep(globalTemplatesObj, snapShotDetails)
    }

    return(
        <div className="twilio___migration__gerenal_step twili__migration___container">
            <div className="row">
                <div className="col">
                    <p className={"no-conflict-message"}>All Assets found linked to this quick clone</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={globalTempValue}
                        value={globalTempValue}
                        name="campaign-temp-radio-all"
                        className="snap-shot--inline-radio"
                    >
                        <FormControlLabel onClick={()=>{setGlobalTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                        <FormControlLabel onClick={()=>{setGlobalTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                    </RadioGroup>
                </div>
            </div>
            <div className="row">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Campaigns</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={campaignTempValue}
                                    value={campaignTempValue}
                                    name="campaign-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setCampaignTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setCampaignTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {campaignLoaded && snapShotCampaigns()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Custom Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={customFieldTempValue}
                                    value={customFieldTempValue}
                                    name="custom-field-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setCustomFieldTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setCustomFieldTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                           <ul>{customFieldLoaded && snapShotCustomFields()}</ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Personalized Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={personalizedFieldTempValue}
                                    value={personalizedFieldTempValue}
                                    name="custom-field-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setPersonalizedFieldTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setPersonalizedFieldTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>{personalizedFieldLoaded && snapShotPersonalizedFields()}</ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Calendars</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={calendarTempValue}
                                    value={calendarTempValue}
                                    name="custom-field-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setCalendarTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setCalendarTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>{campaignLoaded && snapShotCalendars()}</ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Forms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={formTempValue}
                                    value={formTempValue}
                                    name="form-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setFormTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setFormTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {formFieldLoaded && snapShotForms()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/*<Accordion>*/}
                {/*    <AccordionSummary*/}
                {/*        expandIcon={<ExpandMoreIcon />}*/}
                {/*        aria-controls="panel2a-content"*/}
                {/*        id="panel2a-header"*/}
                {/*    >*/}
                {/*        <Typography>Leadflows</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails>*/}
                {/*        <Typography>*/}
                {/*            <FormControl  className=" d-flex align-items-end">*/}
                {/*                <RadioGroup*/}
                {/*                    aria-labelledby="demo-radio-buttons-group-label"*/}
                {/*                    defaultValue={leadFlowTempValue}*/}
                {/*                    name="lead-flow-temp-radio-all"*/}
                {/*                    className="snap-shot--inline-radio"*/}
                {/*                >*/}
                {/*                    <FormControlLabel onClick={()=>setLeadFlowTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />*/}
                {/*                    <FormControlLabel onClick={()=>setLeadFlowTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />*/}
                {/*                </RadioGroup>*/}
                {/*            </FormControl>*/}
                {/*            <ul>*/}
                {/*                {leadFlowLoaded && snapShotLeadFlows()}*/}
                {/*            </ul>*/}
                {/*        </Typography>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Tags</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={tagFieldTempValue}
                                    value={tagFieldTempValue}
                                    name="tag-field-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setTagFieldTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setTagFieldTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {tagFieldLoaded && snapShotTags()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Triggers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={triggerTempValue}
                                    value={triggerTempValue}
                                    name="custom-field-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setTriggerTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setTriggerTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>{triggerLoaded && snapShotTriggers()}</ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Custom Forms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={customFormTempValue}
                                    value={customFormTempValue}
                                    name="custom-form-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setCustomFormTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setCustomFormTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {customFormLoaded && snapShotCustomForm()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Quick Replies</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={quickReplyTempValue}
                                    value={quickReplyTempValue}
                                    name="quick-reply-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setQuickReplyTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setQuickReplyTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {quickReplyLoaded && snapShotQuickReply()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Pipeline & Stages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                {removeSkipe==false && 
                                <FormControl  className=" d-flex align-items-end">
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={pipelineTempValue}
                                        value={pipelineTempValue}
                                        name="pipeline-temp-radio-all"
                                        className="snap-shot--inline-radio"
                                    >
                                        <FormControlLabel onClick={()=>{setPipelineTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                        <FormControlLabel onClick={()=>{setPipelineTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                    </RadioGroup>
                                </FormControl>
                                }
                                <ul>
                                    {pipelineLoaded && snapShotPipelines()}
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Multi Page Forms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {removeSkipe==false && 
                            <FormControl  className=" d-flex align-items-end">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={multiPageFormTempValue}
                                    value={multiPageFormTempValue}
                                    name="custom-form-temp-radio-all"
                                    className="snap-shot--inline-radio"
                                >
                                    <FormControlLabel onClick={()=>{setMultiPageFormTempValue("0"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                    <FormControlLabel onClick={()=>{setMultiPageFormTempValue("1"); setBackDataLoad(false)}} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Select" />
                                </RadioGroup>
                            </FormControl>
                            }
                            <ul>
                                {multiPageFormLoaded && snapShotMultiPageForm()}
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </div>

           
            <div className="btn-group justify-content-between d-flex mx-0">
                {removePreve==false &&
                <div
                    className="btn twilio__migration__submit__btn"
                    onClick={handleBack}
                >
                    Back

                </div>
                }
                <div></div>

                {removeNext==false &&
                <div
                    className="btn twilio__migration__submit__btn"
                    onClick={handleNextClick}
                >
                    Next
                </div>
                }
            </div>
        </div>
    )
}
export default Step2;