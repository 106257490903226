import {
  Box,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyTypes,
  resetAddPolicyTypeSuccess,
  resetDeletePolicyTypeSuccess,
} from "../../../../../actions/ProductAction";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../../common/productSelector";
import { CustomScrollbar } from "../../../productsStyle";
import EachPolicyTypeTableItem from "./EachPolicyTypeTableItem";
import { CustomButton } from "../../../productService/content/productBody/CustomButton";
import TableSkeleton from "../../../productService/content/productBody/TableSkeleton";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditNewPolicyTypeModal from "../AddOrEditNewPolicyTypeModal";

const PolicyTypeListWrapper = styled("div")({
  marginTop: 18,
  "& .policyTypeItem": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    border: "1px solid #E5E8EF",
    borderRadius: 6,
  },
  "& .policyCategoryItem": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    border: "1px solid #346fef",
    borderRadius: 6,
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const PolicyTypeTable = () => {
  const [openAddPolicyTypeModal, setOpenAddPolicyTypeModal] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    policyTypeList,
    isLoading,
    isAddPolicyTypeSuccessful,
    isDeletePolicyTypeSuccessful,
  } = useSelector(policyTypeState);

  const { selectedFolder } = useSelector(policyTypeFolderState);

  let campaignStatus = null;

  useEffect(() => {
    dispatch(getPolicyTypes({ page: 1, perPage: 10, ...campaignStatus }));
  }, []);

  useEffect(() => {
    if (isAddPolicyTypeSuccessful) {
      dispatch(resetAddPolicyTypeSuccess());
    }
    if (isDeletePolicyTypeSuccessful) {
      dispatch(resetDeletePolicyTypeSuccess());
    }

    if (isAddPolicyTypeSuccessful || isDeletePolicyTypeSuccessful) {
      dispatch(getPolicyTypes({ page: 1, perPage: 10, ...campaignStatus }));
    }
  }, [isAddPolicyTypeSuccessful, isDeletePolicyTypeSuccessful]);

  switch (selectedFolder) {
    case "all":
      campaignStatus = null;
      break;
    case "favorite":
      campaignStatus = { isFavorite: "YES" };
      break;
    case "archive":
      campaignStatus = { status: "ARCHIVE" };
      break;
    case "trash":
      campaignStatus = { status: "TRASH" };
      break;
    default:
      campaignStatus = { userProductFolderId: selectedFolder };
  }

  if (policyTypeList?.length === 0 && !isLoading) {
    return (
      <div className={`${classes.tableBodyHeight}`} style={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60%",
          }}
        >
          <div>
            <Typography align="center" variant="h5">
              You haven't added any Policy Types yet!
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ color: "gray" }}
            >
              Click the button below to get started.
            </Typography>
            <Box display={"flex"} justifyContent={"center"} mt={2}>
              <CustomButton
                variant="contained"
                onClick={() => setOpenAddPolicyTypeModal(true)}
                startIcon={<AddIcon />}
                disableElevation
              >
                New Policy Types
              </CustomButton>
            </Box>

            {openAddPolicyTypeModal && (
              <AddOrEditNewPolicyTypeModal
                open={openAddPolicyTypeModal}
                onClose={() => setOpenAddPolicyTypeModal(false)}
              />
            )}
          </div>
        </Box>
        <Box sx={{ width: "40%", display: "flex", alignItems: "center" }}>
          <img
            height={300}
            src={
              "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png"
            }
            alt="Empty data"
          />
        </Box>
      </div>
    );
  } else if (isLoading) {
    return <TableSkeleton />;
  } else {
    return (
      <PolicyTypeListWrapper>
        <CustomScrollbar className={`${classes.tableBodyHeight}`}>
          <TableContainer component={"span"}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeader} width={"25%"}>
                    Policy Type
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Policy Category
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {policyTypeList?.map((policy) => (
                  <EachPolicyTypeTableItem key={policy.id} policy={policy} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomScrollbar>
      </PolicyTypeListWrapper>
    );
  }
};

export default PolicyTypeTable;
