import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "../../actions/profileAction";
import {deleteList, getContactList} from "../../api/profileApi";
import { getCookie } from "../../helpers/Cookie";
import { useIsMounted } from "../../hooks/IsMounted";
import Loader from "../globals/Loader";
import TablePagination from '@material-ui/core/TablePagination';
import Popup from './Popup';
import './Lists.css';
import Utils from "../../helpers/Utils";
import { useHistory,Link } from "react-router-dom";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import {confirmAlert} from "react-confirm-alert";
import CustomCircleLoader from "../common/Loader/CustomCircleLoader";
import ListSkeleton from "./ListSkeleton";
import { debounce } from 'lodash';

const Lists = (props) => {

    const history = useHistory();
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortType, setSortType] = useState('desc');
    const [sortBy, setSortBy] = useState('created_at');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);

    /* power dialer */
    const [startingSession, setStartingSession] = useState(false);
    const [sessionListId, setSessionListId] = useState(null);

    useEffect(() => {
        
        document.title = `List | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }

        props.getUser({
            'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, sortType, debouncedQuery]);  // eslint-disable-line react-hooks/exhaustive-deps


    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); 

    const handleClickSessionStart = (item) => {    
        if (startingSession) {
          return;
        }
        setSessionListId(item.id);
        setStartingSession(true);
        window.globalPowerDialerModal(true, {
          type: "single-list",
          value: item.id,
          name: item.title,
          callback: (response, formData) => {
            if (response.success) {
              const win = window.open(
                `/power-dialer?listId=${response?.data}&listName=${
                  formData.name
                }&from=myList&myListName=${
                  item.title
                }&for=startSession`,
                "_blank"
              );
              win.focus();
            }
            setStartingSession(false);
            setSessionListId(null);
          },
        });
      };


    const loadData = () => {
        setLoading(true);

        getContactList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {
            setData(response.data.data.data);
            setTotal(response.data.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteItem = (_id) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this list?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setLoading(true);
                        deleteList({
                            "form_data": {
                                id: _id
                            }
                        })
                            .then(response => {
                                setReset(reset + 1);
                            }).finally(() => {
                            setLoading(false);
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const renderData = () => {
        if (loading) {
            return (
                <tr>
                    <td colspan="4">
                        {/* <div style={{paddingTop: 50}}>
                        </div> */}
                            <ListSkeleton/>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="4">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => (
                <tr>
                    <td>{item.title}</td>
                    <td>{window.globalTimezoneConversionToDifferentTimezone(item.created_at, 'UTC','','MM/DD/YYYY hh:mm a')}</td>
                    <td>
                        <div>
                            <div
                                onClick={() => handleClickSessionStart(item)}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "3px 7px",
                                    cursor: "pointer",
                                    color: "#fff",
                                    backgroundColor: "#6070ed",
                                    float: "left",
                                    borderRadius: "4px",
                                    marginRight: "15px",
                                    marginTop: "3px",

                                }}
                            >
                                {startingSession && sessionListId == item.id && (
                                    <CustomCircleLoader
                                        style={{ display: "inline-flex", marginRight: "5px" }}
                                        fill='#fff'
                                        size='extra-small'
                                    />
                                )}
                                {startingSession && sessionListId == item.id
                                    ? "Starting"
                                    : "Start Dialing Session"}
                            </div>

                            <BootstrapTooltip arrow title="Edit List">
                                 <span className="custom_svg_fill_wrpper" style={{
                                    padding: "3px 7px",
                                    cursor: "pointer",
                                    color: "#fff",
                                    borderRadius: "4px",
                                    marginRight: "15px",
                                    marginBottom: "15px",
                                }}
                                onClick={() => {
                                    // e.preventDefault();
                                    setDataToEdit(item);
                                    setModalVisible(true);
                                }}>
                                    <svg 
                                     width="30px"
                                     height="30px" 
                                     viewBox="0 -32 576 576" 
                                     xmlns="http://www.w3.org/2000/svg">
                                     <path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"/></svg>
                                </span>
                            </BootstrapTooltip>

                            <BootstrapTooltip arrow title="Delete List">
                                {/* <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    deleteItem(item.id);
                                }}> */}
                                <span className="cursor-pointer" onClick={() => {
                                    deleteItem(item.id);
                                }}>
                                    <svg width="30" height="30  " viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 8.3125L4.57715 22.1069C4.60744 22.3949 4.74329 22.6615 4.95851 22.8553C5.17374 23.0491 5.45308 23.1563 5.74268 23.1562H19.2573C19.5469 23.1563 19.8263 23.0491 20.0415 22.8553C20.2567 22.6615 20.3926 22.3949 20.4229 22.1069L21.875 8.3125H3.125ZM15.2344 18.9307L12.5 16.1963L9.76562 18.9307L8.52246 17.6875L11.2568 14.9531L8.52246 12.2188L9.76562 10.9756L12.5 13.71L15.2344 10.9756L16.4775 12.2188L13.7432 14.9531L16.4775 17.6875L15.2344 18.9307Z" fill="#FF264A"></path>
                                        <path d="M22.8516 2.84375H2.14844C1.82483 2.84375 1.5625 3.10608 1.5625 3.42969V6.16406C1.5625 6.48767 1.82483 6.75 2.14844 6.75H22.8516C23.1752 6.75 23.4375 6.48767 23.4375 6.16406V3.42969C23.4375 3.10608 23.1752 2.84375 22.8516 2.84375Z" fill="#FF264A"></path>
                                    </svg>
                                </span>
                                {/* </a> */}
                            </BootstrapTooltip>
                        </div>
                    </td>
                </tr>
            ))
        }
    }
    
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };
    const debouncedSetQuery = debounce((query) => {
        setQuery(query);
      }, 500);

    return (
        <Fragment>
            <div class="settings_section">
                {
                    !props.user ? (
                        <div style={{paddingTop: 100}}>
                            <ListSkeleton/>
                        </div>
                    ) : (
                        <Fragment>
                            <div class="container-fluid mail__inbox mt-1">
                                <div class="tagBox">
                                    <div class="tag_list">
                                        <h5>My Lists</h5>
                                    </div>

                                    <div class="add_new_user">
                                        {
                                            history.location.state !== undefined && history.location.state.from === 'accountPage' &&
                                            <Link className={"modal-trigger accent--bg--text--color"} to="/user/profile">
                                                <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span>
                                                <span>Back</span>
                                            </Link>
                                        }
                                        {
                                            history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                                            
                                                <Link className={"modal-trigger accent--bg--text--color"} to="/user/settings">
                                                    <span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span>
                                                <span>Back</span>
                                            </Link>
                                        }
                                    </div>
                                </div>

                                { (!loading && !data.length) ?
                                    <div className={"text-center"}>
                                        <div className="items" style={{display:'flex',alignItems:'end',justifyContent: 'end',padding:'10px'}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                            </div>
                                            <div className="record_search">
                                                <input type="text" placeholder="Search list" className="search"
                                                       onChange={(e) => debouncedSetQuery(e.target.value)}/>
                                                <div className="search_icon" style={{marginRight:"-15px"}}>
                                                    <svg width="16" height="20" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                                                            fill="#133159" fill-opacity="0.65"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>No List created yet!</h5>
                                    </div> :
                                    <div className="details">
                                        <div className="items">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            </div>
                                            <div className="record_search">
                                                <input type="text" placeholder="Search list" className="search"
                                                       onChange={(e) => debouncedSetQuery(e.target.value)}/>
                                                <div className="search_icon">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                                                            fill="#133159" fill-opacity="0.65"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <table>
                                            <thead>
                                            <tr className="header">
                                                <th>
                                                    Title
                                                    <svg onClick={() => {
                                                        setSortBy('title');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                         style={{cursor: 'pointer', float: 'right'}}
                                                         width="11" height="18" viewBox="0 0 11 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z"
                                                              fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z"
                                                              fill="#546376"></path>
                                                    </svg>
                                                </th>
                                                <th>
                                                    Created
                                                    <svg onClick={() => {
                                                        setSortBy('created_at');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                         style={{cursor: 'pointer', float: 'right'}}
                                                         width="11" height="18" viewBox="0 0 11 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z"
                                                              fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z"
                                                              fill="#546376"></path>
                                                    </svg>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="tbody_content">
                                            {renderData()}
                                            </tbody>
                                        </table>
                                    </div>
                                }


                                {
                                    true && (
                                        <div className="mt-3">
                                            <TablePagination
                                                component="div"
                                                count={total}
                                                page={currentPage}
                                                onChangePage={handleChangePage}
                                                rowsPerPage={perPage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Fragment>
                    )
                }
			</div>
            {
                modalVisible && (
                    <Popup
                        // hideModal={() => {
                        //     setReset(reset + 1);
                        //     setDataToEdit(null);
                        //     setModalVisible(false);
                        // }}
                        title={'Edit List'}
                        open={modalVisible}
                        onClose={()=>{setModalVisible(false); setDataToEdit(null);}}
                        user={props.user}
                        data={dataToEdit}
                        callback={()=>{setReset(reset + 1); setDataToEdit(null); setModalVisible(false)}}
                    />
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lists);