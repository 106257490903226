import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Utils from "../../helpers/Utils";
import { confirmAlert } from "react-confirm-alert";
import { PRODUCT_SALES_REPORT, PROFILE_TAB } from "../../constants/CoreConstants";
import CancelAccountPopup from "./CancelAccountPopup";
import { SettingIconList } from "../../constants/SettingIconList";
import { HistoryProvider } from "../../App";

const OAUTH_AGENCY_LIST = [1, 8, 125, 150];

export const GeneralOptions = ({ user, userIsOwner, isTemplateUser }) => {

  
  let hiddenSection = "";
  const isTempUser = Utils.getAccountData("isTemplateUser");
  if (isTempUser == 1) {
    hiddenSection =
      hiddenSection +
      "set-calendar set-email set-import-export set-list set-integrations";
  }
  const history = useContext(HistoryProvider);
  const [cancelAccountModalVisible, setCancelAccountModalVisible] =
    useState(false);

  const goToLink = (e, link, tab = 0, state = true, data = null) => {
    e.preventDefault();
    if (state) {
      history.push({
        pathname: link,
        state: { from: "settingPage", tab, data },
      });
      window.scrollTo(0, 0);
    } else {
      history.push({
        pathname: link,
      });
    }
  };

  const cancelUserAccount = () => {
    confirmAlert({
      title: "Are you sure?",
      message: "You will lost your everything. This request can not be undone.",
      buttons: [
        {
          label: "Yes, Cancel",
          onClick: () => {
            setCancelAccountModalVisible(true);
            return;
          },
        },
        {
          label: "Close",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  return (
    <div className="row">
      <div className="col s12 m12 l12 xl12 sitting_content">
        <div className="email-sitting">
          <h3>My Profile</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile",
                  state: { from: "settingPage" },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.myProfile}
                </div>
                <h6>My Profile</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile",
                  state: {
                    from: "settingPage",
                    tab: PROFILE_TAB.CHANGE_PASSWORD,
                  },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.changePassword}
                </div>
                <h6>Change Password</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile",
                  state: { from: "settingPage", tab: PROFILE_TAB.COMPANY_INFO },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.companyInfo}
                </div>
                <h6>Company info</h6>
              </Link>
            </li>

            {userIsOwner && (
              <li>
                <Link
                  className={""}
                  to={{
                    pathname: "/user/profile/team",
                    state: { from: "settingPage" },
                  }}
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.myTeam}
                  </div>
                  <h6>My Team</h6>
                </Link>
              </li>
            )}

            {userIsOwner && (
              <li>
                <Link
                  className={""}
                  to={{
                    pathname: "/user/profile/snapshot",
                    state: { from: "settingPage" },
                  }}
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.myTeam}
                  </div>
                  <h6>Quick Clone</h6>
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className="email-sitting">
          <h3>General Settings</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/general-settings",
                  state: { from: "settingPage", tab: 6 },
                }}
              >
                <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.notification}
                </div>
                <h6>Notification</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/general-settings",
                  state: { from: "settingPage", tab: 7 },
                }}
              >
                <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.notification}
                </div>
                <h6>Notify accounts</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/general-settings",
                  state: { from: "settingPage", tab: 1 },
                }}
              >
                <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.cutOffTime}
                </div>
                <h6>Cut Off Time</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/general-settings",
                  state: { from: "settingPage", tab: 2 },
                }}
              >
                <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.timeZone}
                </div>
                <h6>Time Zone</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "profile/activity-logs",
                  state: { from: "settingPage" },
                }}
              >
                <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.activityLogs}
                </div>
                <h6>Activity Logs</h6>
              </Link>
            </li>
            {Utils.getAccountData("carrierLookup") && (
              <>
                {(Utils.getAccountData("userId") === 1 ||
                  Utils.getAccountData("userId") === 6541) && (
                    <li>
                      <Link
                        className={""}
                        to={{
                          pathname: "/user/lookup-validation",
                          state: { from: "settingPage" },
                        }}
                      >
                        <div className="g-setting-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                          {SettingIconList.lookupValidation}
                        </div>
                        <h6>Lookup Validation</h6>
                      </Link>
                    </li>
                  )}
              </>
            )}
          </ul>
        </div>

        {userIsOwner && (
          <div className="email-sitting">
            <h3>Billing Info</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a href="/billing" onClick={(e) => goToLink(e, "/billing", 1)}>
                  <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.cardInfo}
                  </div>
                  <h6>Card Info</h6>
                </a>
              </li>
              <li>
                <a href="/billing" onClick={(e) => goToLink(e, "/billing", 2)}>
                  <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.topUp}
                  </div>
                  <h6>Instant Top up</h6>
                </a>
              </li>
              <li>
                <a href="/billing" onClick={(e) => goToLink(e, "/billing", 3)}>
                  <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.recharge}
                  </div>
                  <h6>Recharge</h6>
                </a>
              </li>
              <li>
                <Link
                  className={""}
                  to={{
                    pathname: "/user/packages",
                    state: { from: "settingPage" },
                  }}
                >
                  <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.packages}
                  </div>
                  <h6>Packages</h6>
                </Link>
              </li>
              {Utils.getAccountData("agencyId") !== 62 && (
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      cancelUserAccount();
                    }}
                  >
                    <div className="billing-info-cancel-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.cancel}
                    </div>
                    <h6>Cancel</h6>
                  </a>
                </li>
              )}
              {cancelAccountModalVisible && (
                <CancelAccountPopup
                  hideModal={() => {
                    setCancelAccountModalVisible(false);
                  }}
                  title={"Cancel Account"}
                  user={user}
                />
              )}
            </ul>
          </div>
        )}

        <div className="email-sitting">
          <h3>Display Settings</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/general-settings",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.colorsMenu}
                </div>
                <h6>Colors/Menu</h6>
              </Link>
            </li>

            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile",
                  state: { from: "settingPage", tab: PROFILE_TAB.FAVICON },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.favicon}
                </div>
                <h6>Favicon</h6>
              </Link>
            </li>

            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile",
                  state: { from: "settingPage", tab: PROFILE_TAB.COMPANY_INFO },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.companyLogo}
                </div>
                <h6>Company Logo</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/profile/view-settings",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center view-control-icon">
                  {SettingIconList.viewControl}
                </div>
                <h6>View Control</h6>
              </Link>
            </li>
            <li>
              <Link className={""} to={"/user/profile/custom-menu"}>
                <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.menu}
                </div>
                <h6>Custom Menu</h6>
              </Link>
            </li>
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Custom Fields</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/contact-custom-fields",
                  state: { from: "settingPage", tab: 1 },
                }}
              >
                <div className="custom-field-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.customField}
                </div>
                <h6>Custom Fields</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/contact-custom-fields",
                  state: { from: "settingPage", tab: 2 },
                }}
              >
                <div className="custom-field-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.personalizedField}
                </div>
                <h6>Personalized Fields</h6>
              </Link>
            </li>
            <li>
              <a
                href="/power-dialer/general-scripts"
                onClick={(e) => goToLink(e, "/power-dialer/general-scripts")}
              >
                <div className="custom-field-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.autoDialer}
                </div>
                <h6>Power Dialer Scripts</h6>
              </a>
            </li>
          </ul>
        </div>

        {hiddenSection.search("set-email") == -1 && (
          <div className="email-sitting">
            <h3>Email Setting</h3>
            <ul className="d-flex justify-content-start align-content-center">
              {/*{*/}
              {/*  // eslint-disable-next-line eqeqeq*/}
              {/*  user.agency != undefined &&*/}
              {/*  user.agency.email_provider !== 0 && (*/}
              {/*    <li>*/}
              {/*      <Link*/}
              {/*        className={""}*/}
              {/*        to={{*/}
              {/*          pathname: "/user/profile/email-settings",*/}
              {/*          state: { from: "settingPage", tab: 0 },*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">*/}
              {/*          {SettingIconList.emailProvider}*/}
              {/*        </div>*/}
              {/*        <h6>Email Provider</h6>*/}
              {/*      </Link>*/}
              {/*    </li>*/}
              {/*  )*/}
              {/*}*/}

              {
                  user?.agency?.email_provider !== 0 &&
                  <li>
                    <Link
                        className={""}
                        to={{
                          pathname: "/user/profile/email-settings",
                          state: {from: "settingPage", tab: 3},
                        }}
                    >
                      <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">
                        {SettingIconList.emailProvider}
                      </div>
                      <h6>Connected Emails</h6>
                    </Link>
                  </li>
              }

              <li>
                <Link
                    className={""}
                    to={{
                      pathname: "/user/profile/email-settings",
                      state: {from: "settingPage", tab: 1},
                    }}
                >
                  <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.canSpam}
                  </div>
                  <h6>Can Spam Address</h6>
                </Link>
              </li>
              <li>
                <Link
                    className={""}
                  to={{
                    pathname: "/user/profile/email-settings",
                    state: { from: "settingPage", tab: 2 },
                  }}
                >
                  <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.emailSignature}
                  </div>
                  <h6>Email Signature</h6>
                </Link>
              </li>
             { 
              (
                Utils.getAccountData("userId") === 1 ||
                Utils.getAccountData("userId") === 446 ||
                Utils.getAccountData("userId") === 12 ||
                Utils.getAccountData("userId") === 15 ||
                Utils.getAccountData("agencyId") === 235

            ) &&
              (<li>
                <Link
                    className={""}
                  to={{
                    pathname: "/user/connected-google-account",
                    state: { from: "settingPage", tab: 4 },
                  }}
                >
                  <div 
                  className="sms-box sitting_icon_box d-flex justify-content-center align-items-center"
                  >
                    {SettingIconList.googleSheet}
                  </div>
                  <h6>Connected google Accounts</h6>
                </Link>
              </li>)
            }
            </ul>
          </div>
        )}

        <div className="phone-sitting">
          <h3>Phone Setting</h3>
          <ul className="d-flex justify-content-start align-content-center">
            {isTemplateUser !== 1 && (
              <li>
                <a
                  href="/user/telephony-service/phone-settings"
                  onClick={(e) => {
                    let data = { from: "settingPage", tab: "number" };

                    return goToLink(
                      e,
                      "/user/telephony-service/phone-settings",
                      0,
                      true,
                      data
                    );
                  }}
                  className=""
                >
                  <div className="phn-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.virtualNumber}
                  </div>
                  <h6>Virtual Number</h6>
                </a>
              </li>
            )}
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/call-outcome",
                  state: { from: "settingPage" },
                }}
              >
                <div className="phn-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.callOutcome}
                </div>
                <h6>Call Outcomes</h6>
              </Link>
            </li>
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Lead</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/lead-source-type",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.leadSources}
                </div>
                <h6>Lead Sources</h6>
              </Link>
            </li>

            {userIsOwner && (
              <>
                <li>
                  <Link
                    className={""}
                    to={{
                      pathname: "/user/lead-group",
                      state: { from: "settingPage", tab: 0 },
                    }}
                  >
                    <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.leadGroups}
                    </div>
                    <h6>Lead Groups</h6>
                  </Link>
                </li>
                <li>
                  <a href="/leadflow" onClick={(e) => goToLink(e, "/leadflow")}>
                    <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.leadFlows}
                    </div>
                    <h6>Lead Flows</h6>
                  </a>
                </li>
              </>
            )}
            <li>
              <a
                href="/leadflow"
                onClick={(e) => goToLink(e, "/lead-permission-requests")}
              >
                <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.leadPermissionRequest}
                </div>
                <h6>Lead Requests</h6>
              </a>
            </li>
            {Utils.getAccountData("carrierLookup") ? (
              <li>
                <a
                  href="/user/contact-validation-report"
                  onClick={(e) =>
                    goToLink(e, "/user/contact-validation-report")
                  }
                >
                  <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.leadSources}
                  </div>
                  <h6>Lead Validation Report</h6>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Tags & List</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/tags",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="tags-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.tags}
                </div>
                <h6>Tags</h6>
              </Link>
            </li>
            {hiddenSection.search("set-list") == -1 && (
              <li>
                <Link
                  className={""}
                  to={{
                    pathname: "/user/lists",
                    state: { from: "settingPage", tab: 0 },
                  }}
                >
                  <div className="tags-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.myList}
                  </div>
                  <h6>My Lists</h6>
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Pipeline Settings</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <a href="/pipelines" onClick={(e) => goToLink(e, "/pipelines")}>
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.pipelineSetting}
                </div>
                <h6>Pipeline Settings</h6>
              </a>
            </li>
          </ul>
        </div>

        {hiddenSection.search("set-integrations") == -1 && (
          <div className="email-sitting">
            <h3>Integrations</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a
                    href="/integrations"
                    onClick={(e) => goToLink(e, "/integrations")}
                >
                  <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.integrations}
                  </div>
                  <h6>Integration Applications</h6>
                </a>
              </li>
              {
                // eslint-disable-next-line eqeqeq
                // (!Utils.getAccountData('userIsAgent') && Utils.getAccountData('userId') == 1)&&
                  parseInt(Utils.getAccountData("agencyId")) !== 35 && (
                      <li>
                        <Link
                            className={""}
                            to={{
                              pathname: "/public-api",
                              state: {from: "settingPage"},
                            }}
                        >
                          <div
                              className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                            {SettingIconList.publicApi}
                          </div>
                          <h6>Public Api</h6>
                        </Link>
                      </li>
                  )
              }

              {Utils.getAccountData("agencyId") !== 1 &&
                  window.getShowMapFeatureOption &&
                  window.getShowMapFeatureOption() && (
                      <li>
                        <a
                            href="/map/integration"
                            onClick={(e) =>
                                goToLink(e, "/map/integration", null, false)
                            }
                        >
                          <div
                              className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                            {SettingIconList.mapIntegration}
                          </div>
                          <h6>Geofarming</h6>
                        </a>
                      </li>
                  )}

              {(Utils.getAccountData("first_promoter_signup_link") !=
                  undefined ||
                  Utils.getAccountData("first_promoter_signup_link") != null) && (
                  <li>
                    <Link
                        className={""}
                        to={{
                          pathname: "/user/profile/first-promoter",
                          state: {from: "settingPage"},
                        }}
                    >
                      <div
                          className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                        {SettingIconList.firstPromoter}
                      </div>
                      <h6>First Promoter</h6>
                    </Link>
                  </li>
              )}

              <li>
                <a
                    href="/user/profile/snapshot-marketplace"
                    onClick={(e) =>
                        goToLink(e, "/user/profile/snapshot-marketplace")
                    }
                >
                  <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.integrations}
                  </div>
                  <h6>Quick Clone Marketplace</h6>
                </a>
              </li>
              <li>
                <a
                    href="/user/profile/open-ai-integration"
                    onClick={(e) =>
                        goToLink(e, "/user/profile/open-ai-integration")
                    }
                >
                  <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.openAI}
                  </div>
                  <h6>OpenAI</h6>
                </a>
              </li>
            </ul>
          </div>
        )}

        {hiddenSection.search("set-calendar") == -1 && (
            <div className="email-sitting">
              <h3>Calendar</h3>
              <ul className="d-flex justify-content-start align-content-center">
                <li>
                  <Link
                      className={""}
                      to={{
                        pathname: "/user/profile/calendar-sync",
                        state: {from: "settingPage"},
                      }}
                  >
                    <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.calendarSync}
                  </div>
                  <h6>Calendar Sync</h6>
                </Link>
              </li>
            </ul>
          </div>
        )}

        <div className="email-sitting">
          <h3>Mailbox</h3>
          <ul className="d-flex justify-content-start align-content-center">
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/mail-box",
                  state: { from: "settingPage" },
                }}
              >
                <div className="lead-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.mailbox}
                </div>
                <h6>Mailbox</h6>
              </Link>
            </li>
          </ul>
        </div>

        {hiddenSection.search("set-import-export") == -1 && (
          <div className="email-sitting">
            <h3>File export/import</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a
                  href="/data-export/csv-file"
                  onClick={(e) => goToLink(e, "/data-export/csv-file")}
                  className=""
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.exportFile}
                  </div>
                  <h6>Export File</h6>
                </a>
              </li>
              <li>
                <a
                  href="/contacts-import"
                  onClick={(e) => goToLink(e, "/contacts-import")}
                  className=""
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.importFile}
                  </div>
                  <h6>Import File</h6>
                </a>
              </li>
            </ul>
          </div>
        )}

        {Utils.getAccountData("userId") === 1 && (
          <div className="email-sitting">
            <h3>Text Message Service</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a
                  href="/messaging/services"
                  onClick={(e) => goToLink(e, "/messaging/services")}
                  className=""
                >
                  <div className="tags-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.pipelineSetting}
                  </div>
                  <h6>Messaging Services</h6>
                </a>
              </li>
              <li>
                <a
                  href="/campaign/registration"
                  onClick={(e) => goToLink(e, "/campaign/registration")}
                  className=""
                >
                  <div className="tags-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.integrations}
                  </div>
                  <h6>Campaign Registration</h6>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="email-sitting">
          <h3>Invoice</h3>
          <ul className="d-flex justify-content-start align-content-center">
            {/*<li>*/}
            {/*  <a*/}
            {/*    href="/user/invoice"*/}
            {/*    onClick={(e) => goToLink(e, "/user/invoice")}*/}
            {/*    className=""*/}
            {/*  >*/}
            {/*    <div className="tags-icon-box sitting_icon_box d-flex justify-content-center align-items-center">*/}
            {/*      {SettingIconList.leadSources}*/}
            {/*    </div>*/}
            {/*    <h6>Invoice</h6>*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.companyInfo}
                </div>
                <h6>Company Info</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 5 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.paymentGateWay}
                </div>
                <h6>Payment Credentials</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 1 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.paymentGateWay}
                </div>
                <h6>Payment Gateway</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 2 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.products}
                </div>
                <h6>Products/Services</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 3 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.products}
                </div>
                <h6>Email/Text Templates</h6>
              </Link>
            </li>
            <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/invoice-settings",
                  state: { from: "settingPage", tab: 4 },
                }}
              >
                <div className="pipeline-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.products}
                </div>
                <h6>Invoice Reminders</h6>
              </Link>
            </li>
          </ul>
        </div>

        {(Utils.getAccountData("userId") >
          process.env.REACT_APP_CHANGE_IN_10DLC_FOR_SUBACCOUNT_AFTER_USER_ID ||
          Utils.getAccountData("centralBilling")) &&
          !Utils.getAccountData("userIsOwner") ? (
          ""
        ) : (
          <div className="email-sitting">
            <h3>Trust Center</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a
                  href="/user/telephony-service/trust-center"
                  onClick={(e) =>
                    goToLink(e, "/user/telephony-service/trust-center", 0, true)
                  }
                  className=""
                >
                  <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.trustCenter}
                  </div>
                  <h6>Trust Center</h6>
                </a>
              </li>
              <li>
                <a
                  href="/user/telephony-service/shaken-stir-cnam"
                  onClick={(e) => {
                    let data = {};

                    return goToLink(
                      e,
                      "/user/telephony-service/shaken-stir-cnam",
                      0,
                      true,
                      data
                    );
                  }}
                  className=""
                >
                  <div className="sms-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.trustCenter}
                  </div>
                  <h6>Shaken/Stir & Cnam</h6>
                </a>
              </li>
            </ul>
          </div>
        )}

        {OAUTH_AGENCY_LIST.includes(Utils.getAccountData("agencyId")) && (
          <div className="email-sitting">
            <h3>Public Api</h3>
            <ul className="d-flex justify-content-start align-content-center">
              <li>
                <a
                  href="/user/app-list"
                  onClick={(e) => goToLink(e, "/user/app-list")}
                  className=""
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.oAuth2}
                  </div>
                  <h6>OAuth2 Application</h6>
                </a>
              </li>
              <li>
                <a
                  href="/public-api/authorization-doc"
                  target="_blank"
                  className=""
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.help}
                  </div>
                  <h6>Authorization Help</h6>
                </a>
              </li>
              <li>
                <a
                  href="/public-api/doc"
                  target="_blank"
                  className=""
                >
                  <div className="my-profile-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                    {SettingIconList.doc}
                  </div>
                  <h6>Api Doc</h6>
                </a>
              </li>
            </ul>
          </div>
        )}

        <div className="email-sitting">
          <h3>Products & Services</h3>
          <ul className="d-flex justify-content-start align-content-center">            
            <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.PRODUCTS)}>
                    <div className="lead-icon-box  sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.integrations}
                    </div>
                    <h6>Products & Services</h6>
                  </a>
            </li> 
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Goals</h3>
          <ul className="d-flex justify-content-start align-content-center">            
          <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.GOALS)}>
                    <div className="custom-field-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.goal}
                    </div>
                    <h6>Goals</h6>
                  </a>
            </li>
          </ul>
        </div>

        <div className="email-sitting">
          <h3>Sales Reports</h3>
          <ul className="d-flex justify-content-start align-content-center">
            {/* <li>
              <Link
                className={""}
                to={{
                  pathname: "/user/deal-report",
                  state: { from: "settingPage", tab: 0 },
                }}
              >
                <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.companyInfo}
                </div>
                <h6>Deal report</h6>
              </Link>
            </li> */}
             <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.DEAL_REPORT)}>
              <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                  {SettingIconList.companyInfo}
                </div>
                <h6>Sales report</h6>
                </a>
            </li>
            <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.TEAM_SALES_REPORT)}>
                    <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.teamReport}
                    </div>
                    <h6>Team Sales Report</h6>
                  </a>
            </li>
            <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.PRODUCT_SALES_REPORT)}>
                    <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.productReport}
                    </div>
                    <h6>Product Sales Report</h6>
                  </a>
            </li>
            <li>
              <a href="/user/products" onClick={(e) => goToLink(e, "/user/products", PRODUCT_SALES_REPORT.SALES_VS_GOAL_REPORT)}>
                    <div className="billing-info-icon-box sitting_icon_box d-flex justify-content-center align-items-center">
                      {SettingIconList.leadSources}
                    </div>
                    <h6>Sales vs Goal Report</h6>
                  </a>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
};
