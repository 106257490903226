import * as ACTION from "../constants/productActionTypes";

export const getProductFolders = (callback) => ({
  type: ACTION.GET_PRODUCT_FOLDERS,
  payload: { callback },
});
export const getProductFoldersSuccess = (data) => ({
  type: ACTION.GET_PRODUCT_FOLDERS_SUCCESS,
  payload: { data },
});
export const getProductFoldersFailure = (error) => ({
  type: ACTION.GET_PRODUCT_FOLDERS_FAILURE,
  payload: { error },
});

export const addProductFolder = (payload) => ({
  type: ACTION.ADD_PRODUCT_FOLDER,
  payload: payload,
});
export const addProductFolderSuccess = (payload) => ({
  type: ACTION.ADD_PRODUCT_FOLDER_SUCCESS,
  payload: payload,
});
export const addProductFolderFailure = (error) => ({
  type: ACTION.ADD_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const updateProductFolder = ({id, payload}) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER,
  payload: { id, payload },
});
export const updateProductFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER_SUCCESS,
  payload: payload,
});
export const updateProductFolderFailure = (error) => ({
  type: ACTION.UPDATE_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const deleteProductFolder = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER,
  payload: id,
});
export const deleteProductFolderSuccess = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER_SUCCESS,
  payload: id,
});
export const deleteProductFolderFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FOLDER_FAILURE,
  payload: error,
});

export const onSelectProductFolder = (id) => ({
  type: ACTION.SELECT_PRODUCT_FOLDER,
  payload: id,
});

export const getProducts = (payload) => ({
  type: ACTION.GET_PRODUCTS,
  payload: payload,
});
export const getProductsSuccess = (data) => ({
  type: ACTION.GET_PRODUCTS_SUCCESS,
  payload: data,
});
export const getProductsFailure = (error) => ({
  type: ACTION.GET_PRODUCTS_FAILURE,
  payload: error,
});

export const addProduct = (payload) => ({
  type: ACTION.ADD_PRODUCT,
  payload: payload,
});
export const addProductSuccess = (data) => ({
  type: ACTION.ADD_PRODUCT_SUCCESS,
  payload: data,
});
export const addProductFailure = (error) => ({
  type: ACTION.ADD_PRODUCT_FAILURE,
  payload: error,
});
export const resetAddProductSuccess = () => ({
  type: ACTION.RESET_ADD_PRODUCT_SUCCESS,
});

export const updateProduct = (payload) => ({
  type: ACTION.UPDATE_PRODUCT,
  payload: payload,
});
export const updateProductSuccess = (data) => ({
  type: ACTION.UPDATE_PRODUCT_SUCCESS,
  payload: data,
});
export const updateProductFailure = (error) => ({
  type: ACTION.UPDATE_PRODUCT_FAILURE,
  payload: error,
});

export const deleteProduct = (payload) => ({
  type: ACTION.DELETE_PRODUCT,
  payload: payload,
});
export const deleteProductSuccess = (data) => ({
  type: ACTION.DELETE_PRODUCT_SUCCESS,
  payload: data,
});
export const deleteProductFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FAILURE,
  payload: error,
});

export const deleteProductForever = (id) => ({
  type: ACTION.DELETE_PRODUCT_FOREVER,
  payload: id,
});
export const deleteProductForeverSuccess = () => ({
  type: ACTION.DELETE_PRODUCT_FOREVER_SUCCESS,
});
export const deleteProductForeverFailure = (error) => ({
  type: ACTION.DELETE_PRODUCT_FOREVER_FAILURE,
  payload: error,
});
export const resetDeleteProductSuccess = () => ({
  type: ACTION.RESET_DELETE_PRODUCT_SUCCESS,
})

export const toggleFavorite = (payload) => ({
  type: ACTION.TOGGLE_FAVORITE,
  payload: payload,
});
export const toggleFavoriteSuccess = (payload) => ({
  type: ACTION.TOGGLE_FAVORITE_SUCCESS,
  payload: payload,
});
export const toggleFavoriteFailure = (error) => ({
  type: ACTION.TOGGLE_FAVORITE_FAILURE,
  payload: error,
});

export const deSelectAllProducts = () => ({
  type: ACTION.DESELECT_ALL_PRODUCTS,
})

export const setPipelines = (payload) => ({
  type: ACTION.SET_PIPELINES,
  payload: payload,
});

export const setTeamUsers = (payload) => ({
  type: ACTION.SET_TEAM_USERS,
  payload: payload,
});

export const handleAssetLoadingState = (payload) => ({
  type: ACTION.HANDLE_ASSET_LOADING_STATE,
  payload: payload,
});

export const getPolicyTypeFolders = (payload) => ({
  type: ACTION.GET_POLICY_TYPE_FOLDERS,
  payload: payload,
});
export const getPolicyTypeFoldersSuccess = (data) => ({
  type: ACTION.GET_POLICY_TYPE_FOLDERS_SUCCESS,
  payload: { data },
});
export const getPolicyTypeFoldersFailure = (error) => ({
  type: ACTION.GET_POLICY_TYPE_FOLDERS_FAILURE,
  payload: { error },
});

export const addPolicyTypeFolder = (payload) => ({
  type: ACTION.ADD_POLICY_TYPE_FOLDER,
  payload: payload,
});
export const addPolicyTypeFolderSuccess = (payload) => ({
  type: ACTION.ADD_POLICY_TYPE_FOLDER_SUCCESS,
  payload: payload,
});
export const addPolicyTypeFolderFailure = (error) => ({
  type: ACTION.ADD_POLICY_TYPE_FOLDER_FAILURE,
  payload: error,
});

export const updatePolicyTypeFolder = ({id, payload}) => ({
  type: ACTION.UPDATE_POLICY_TYPE_FOLDER,
  payload: { id, payload },
});
export const updatePolicyTypeFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_POLICY_TYPE_FOLDER_SUCCESS,
  payload: payload,
});
export const updatePolicyTypeFolderFailure = (error) => ({
  type: ACTION.UPDATE_POLICY_TYPE_FOLDER_FAILURE,
  payload: error,
});

export const deletePolicyTypeFolder = (id) => ({
  type: ACTION.DELETE_POLICY_TYPE_FOLDER,
  payload: id,
});
export const deletePolicyTypeFolderSuccess = (id) => ({
  type: ACTION.DELETE_POLICY_TYPE_FOLDER_SUCCESS,
  payload: id,
});
export const deletePolicyTypeFolderFailure = (error) => ({
  type: ACTION.DELETE_POLICY_TYPE_FOLDER_FAILURE,
  payload: error,
});

export const onSelectPolicyTypeFolder = (id) => ({
  type: ACTION.SELECT_POLICY_TYPE_FOLDER,
  payload: id,
});

export const getPolicyTypes = (payload) => ({
  type: ACTION.GET_POLICY_TYPES,
  payload: payload,
});
export const getPolicyTypesSuccess = (payload) => ({
  type: ACTION.GET_POLICY_TYPES_SUCCESS,
  payload: payload,
});
export const getPolicyTypesFailure = (error) => ({
  type: ACTION.GET_POLICY_TYPES_FAILURE,
  payload: error,
});

export const addPolicyType = (payload) => ({
  type: ACTION.ADD_POLICY_TYPE,
  payload: payload,
});
export const addPolicyTypeSuccess = (data) => ({
  type: ACTION.ADD_POLICY_TYPE_SUCCESS,
  payload: data,
});
export const addPolicyTypeFailure = (error) => ({
  type: ACTION.ADD_POLICY_TYPE_FAILURE,
  payload: error,
});
export const resetAddPolicyTypeSuccess = () => ({
  type: ACTION.RESET_ADD_POLICY_TYPE_SUCCESS,
});

export const updatePolicyType = (payload) => ({
  type: ACTION.UPDATE_POLICY_TYPE,
  payload: payload,
});
export const updatePolicyTypeSuccess = (data) => ({
  type: ACTION.UPDATE_POLICY_TYPE_SUCCESS,
  payload: data,
});
export const updatePolicyTypeFailure = (error) => ({
  type: ACTION.UPDATE_POLICY_TYPE_FAILURE,
  payload: error,
});

export const deletePolicyType = (payload) => ({
  type: ACTION.DELETE_POLICY_TYPE,
  payload: payload,
});
export const deletePolicyTypeSuccess = (data) => ({
  type: ACTION.DELETE_POLICY_TYPE_SUCCESS,
  payload: data,
});
export const deletePolicyTypeFailure = (error) => ({
  type: ACTION.DELETE_POLICY_TYPE_FAILURE,
  payload: error,
});
export const resetDeletePolicyTypeSuccess = () => ({
  type: ACTION.RESET_DELETE_POLICY_TYPE_SUCCESS,
})

export const updatePolicyTypeStatus = (payload) => ({
  type: ACTION.UPDATE_POLICY_TYPE_STATUS,
  payload: payload,
});
export const updatePolicyTypeStatusSuccess = (data) => ({
  type: ACTION.UPDATE_POLICY_TYPE_STATUS_SUCCESS,
  payload: data,
});
export const updatePolicyTypeStatusFailure = (error) => ({
  type: ACTION.UPDATE_POLICY_TYPE_STATUS_FAILURE,
  payload: error,
});

export const togglePolicyTypeFavorite = (payload) => ({
  type: ACTION.TOGGLE_POLICY_TYPE_FAVORITE,
  payload: payload,
});
export const togglePolicyTypeFavoriteSuccess = (payload) => ({
  type: ACTION.TOGGLE_POLICY_TYPE_FAVORITE_SUCCESS,
  payload: payload,
});
export const togglePolicyTypeFavoriteFailure = (error) => ({
  type: ACTION.TOGGLE_POLICY_TYPE_FAVORITE_FAILURE,
  payload: error,
});

export const deletePolicyTypeForever = (id) => ({
  type: ACTION.DELETE_POLICY_TYPE_FOREVER,
  payload: id,
});
export const deletePolicyTypeForeverSuccess = () => ({
  type: ACTION.DELETE_POLICY_TYPE_FOREVER_SUCCESS,
});
export const deletePolicyTypeForeverFailure = (error) => ({
  type: ACTION.DELETE_POLICY_TYPE_FOREVER_FAILURE,
  payload: error,
});

export const resetAddPolicyTypeFolder = () => ({
  type: ACTION.RESET_ADD_POLICY_TYPE_FOLDER,
})
