import React, { Fragment, useEffect, useState } from "react"
import { getPhoneSettingsDetails } from "../../api/profileApi";
import VirtualNumbers from "./VirtualNumbers";
import VirtualNumberTopSkeleton from "./VirtualNumberTopSkeleton"
import Utils from "../../helpers/Utils";
import { useHistory, Link } from "react-router-dom";
import VirtualNumbersV2 from "./VirtualNumberV2/VirtualNumbersV2";


const PhoneSettings = (props) => {

    const history = useHistory();
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState(null);
    
    useEffect(() => {
        document.title = `Phone Setting | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
        loadData();
    }, [])

    const loadData = () => {
        setComponentLoading(true);

        getPhoneSettingsDetails({})
        .then(response => {
            setData(response.data.data);
        }).finally(() => {
            setComponentLoading(false);
        });
    }

    return (
        <div className="updated settings_section virtual_number_wrapper">
            {
                componentLoading || !data ? (
                        <VirtualNumberTopSkeleton/>
                    ) : (
                    <Fragment>
                        {
                            !data.hasAccess ? (
                                <div>
                                    <h5>This Package does not have phone settings. Please upgrade package.</h5>
                                </div>
                            ) : (
                                <Fragment>
                                    {
                                        history.location.state !== undefined && history.location.state.from === 'accountPage' && 
                                        <div className="back_button_to_account has_gap mt-3 ml-3"><Link className={"accent--bg--text--color"} to="/user/profile"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg><span>Back</span></Link></div>
                                    }

                                    {
                                        (history.location.state === undefined || (history.location.state !== undefined && history.location.state.from === 'settingPage')) &&
                                        <div className="back_button_to_account has_gap mt-3 ml-3"><Link className={"accent--bg--text--color"} to="/user/settings"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg><span>Back</span></Link></div>
                                    }

                                    <div className="settings_content">
                                    {
                                        (Utils.getAccountData("userId") > 0 ) 
                                        ?
                                        <VirtualNumbersV2 registrationType={props.registrationType} data={data} callback={loadData}/> 
                                        :
                                        <VirtualNumbers registrationType={props.registrationType} data={data} callback={loadData}/>
                                        }

                                        
                                    </div>
                                </Fragment>
                            )
                        }
                    </Fragment>
                )
            }
        </div>
    )
}

export default PhoneSettings;