import React, { Fragment } from 'react';
import { Skeleton } from '@material-ui/lab';
import Styles from './TrustCenterV2.module.css';




const TrustCenterSkeleton = () => {
  return (
    
    <Fragment>
      <div className={Styles.pageHeader}>
        <Skeleton variant="text" width={200} height={40} />
        <Skeleton variant="text" width="60%" height={20} />
        <Skeleton variant="rect" width="40%" height={16} className={Styles.pageHintSkeleton} />
        <Skeleton variant="rect" width="40%" height={16} className={Styles.pageHintSkeleton} />
      </div>
      <div className={Styles.pageBody}>
        <div className={Styles.serviceContainer}>
          <div style={{border: 'none'}} className={Styles.serviceContainerItem}>
            <div className={Styles.serviceContainerItemHeader}>
              <Skeleton variant="text" width="70%" height={30} />
              <Skeleton variant="text" width="90%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
            </div>
            <div className={Styles.serviceContainerBody}>
              <div className={Styles.serviceStatusV2}>
                <Skeleton variant="text" width="50%" height={20} />
                <Skeleton variant="text" width="30%" height={20} />
              </div>
              <div className={Styles.serviceStatus}>
                <Skeleton variant="text" width="50%" height={20} />
              </div>
              <div className={Styles.serviceStatus}>
                <Skeleton variant="text" width="50%" height={20} />
              </div>
            </div>
            <div className={Styles.serviceContainerFooter}>
              <Skeleton variant="rect" width={180} height={40} />
            </div>
          </div>
          <div style={{border: 'none'}} className={Styles.serviceContainerItem}>
            <div className={Styles.serviceContainerItemHeader}>
              <Skeleton variant="text" width="70%" height={30} />
              <Skeleton variant="text" width="90%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
            </div>
            <div className={Styles.serviceContainerBody}>
              <div className={Styles.serviceStatus}>
                <Skeleton variant="text" width="50%" height={20} />
              </div>
              <div className={Styles.serviceStatus}>
                <Skeleton variant="text" width="50%" height={20} />
              </div>
              <div className={Styles.serviceStatus}>
                <Skeleton variant="text" width="50%" height={20} />
              </div>
            </div>
            <div className={Styles.serviceContainerFooter}>
              <Skeleton variant="rect" width={180} height={40} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TrustCenterSkeleton;
