import {
  Checkbox,
  FormControlLabel,
  Grid,
  withStyles,
} from "@material-ui/core";
import React from "react";

const PrimaryCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#3C7EF3",
    },
  },

  "& .MuiFormControlLabel-label": {
    color: "red",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const EachCompanyItem = ({ company, onSelectCompany }) => {
  const checked = company.selected === 1 ? true : false;

  const handleChange = () => {
    onSelectCompany({
      checked: !checked === true ? "TRUE" : "FALSE",
      id: company.id,
    });
  };
  return (
    <Grid item xs={4}>
      <FormControlLabel
        // style={{ color: "gray" }}
        control={
          <PrimaryCheckbox
            checked={checked}
            onChange={handleChange}
            color="primary"
            size="small"
          />
        }
        label={company?.company_name}
      />
    </Grid>
  );
};

export default EachCompanyItem;
