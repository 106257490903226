import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import {useSelector} from "react-redux";
import Utils from "../../../helpers/Utils";



const TeamBarChart = ({activeMember,data,setActiveMember}) => {
  const { teamUsers } = useSelector((state) => state.productReducer);

  const handleActiveMember = (id) => {
    if (activeMember === id) {
      setActiveMember(null);
      return;
    }
    setActiveMember(id);
  };

  const member = (id) => {
    try{
      const fMember = teamUsers.find((team)=>(team.id === id));

      if (fMember){
        return fMember.full_name;
      }

      return "Unknown";
    }catch (err){
      return "Unknown";
    }
  };

  const DataFormater = (number) => {
    if(number > 1000000000){
      return '$' + (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return '$' + (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return '$' + (number/1000).toString() + 'K';
    }else{
      return '$' + number.toString();
    }
  }
  
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload[0]) {
      const { product, sold, count,member } = payload[0].payload;
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '16px',
          borderRadius: '4px',
          textAlign: 'center',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        }}>
          <p style={{ margin: 0, fontWeight: '600', color: '#133159',marginBottom:'6px' }}>{member}</p> 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems:'center',gap:'50px'}}>
          <div style={{paddingRight:'20px', borderRight:'1px solid #E5E8EF'}}>
           { (Utils.getAccountData("agencyBusinessType") === undefined || 
           Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
            <p style={{ color: '#133159', fontWeight: '500',marginBottom:'6px' }}>SALES</p> :
            <p style={{ color: '#133159', fontWeight: '500',marginBottom:'6px' }}>Premium</p> 
          }
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>
              {`${Utils.formatCurrency(product, "$")}`}
            </p>
          </div>
          <div>
           { 
           (Utils.getAccountData("agencyBusinessType") === undefined || 
           Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
            <p style={{ color: '#133159', fontWeight: '500', marginBottom:'6px' }}>PRODUCTS/SERVICES</p> :
            <p style={{ color: '#133159', fontWeight: '500', marginBottom:'6px' }}>Policy Count</p>}
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>{count}</p>
          </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const formatData = data.map((item)=>({ product: item.totalDealValue, member: member(item.dealClosedBy), user_id: item.dealClosedBy ,count:item.totalDealCount}));

  return (
    <ResponsiveContainer width="100%" height={370}>
    <BarChart
      data={formatData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid 
      strokeDasharray="none"
      stroke="#fff" 
       />
      <XAxis dataKey="member" />
      <YAxis tickFormatter={DataFormater}/>
      <Tooltip content={CustomTooltip}/>
      <Bar
        maxBarSize={24}
        dataKey="product"
        fill="#8884d8"
        isAnimationActive={false}
      >
        {
          formatData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={activeMember === entry.user_id ? "#346fef" : "#e9e9e9"}
              onClick={()=>handleActiveMember(entry.user_id)}
            />
          ))
        }
      </Bar>
    </BarChart>
  </ResponsiveContainer>
  );
};

export default TeamBarChart;
