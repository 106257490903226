import { Select, styled } from "@material-ui/core";

export const StyledSelect = styled(Select)(() => ({
  minWidth:150,
  "& .MuiOutlinedInput-input":{
    padding:'10px'
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e2e3e9",
  },
}));
