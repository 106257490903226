import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddOrEditRecycleEventModal from "../AddOrEditRecycleEventModal";
import CustomConfirmAlert from "../../../common/confirm-alert/CustomConfirmAlert";
import { deleteLostReason, deleteRecycleEvent } from "../../../../../actions/ProductAction";
import { useDispatch } from "react-redux";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));


const EachRecycleEventTableItem = ({ event }) => {

  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);

  const dispatch = useDispatch();

  const handleEditRecycleEvent = () => {
    setOpenAddOrEditModal(true);
  };

  const confirmDeleteRecycleEvent = async () => {
    dispatch(deleteRecycleEvent({ deal_recycle_event_id: event.id }));
  };

  const handleDeleteRecycleEvent = () => {
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteRecycleEvent(),
      title: "Are you sure you want to delete this recycle event?",
      description: "You cannot undo this action",
    });
  };

 
  return (
    <TableRowWrapper>
      <TableCell style={{width:'80%'}}>
        <div  className="titleWrap">{event.event_title}</div>
      </TableCell>
      <TableCell  style={{width:'20%'}}>
        {
          event.deletable && ( 
          <div style={{display:'flex',alignItems:'center'}}>
              <IconButton onClick={handleEditRecycleEvent}>
                <EditIcon fontSize="small" className="icon" />
              </IconButton>
              <IconButton onClick={handleDeleteRecycleEvent}>
                <DeleteIcon style={{color:"red"}} fontSize="small" className="icon" />
              </IconButton>
          </div>
        )}
      </TableCell>

      {openAddOrEditModal && (
        <AddOrEditRecycleEventModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={event}
        />
      )}
    </TableRowWrapper>
  );
};

export default EachRecycleEventTableItem;
