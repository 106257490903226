import React, { useRef, useState } from "react";
import { Box, Button, Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SimpleReactValidator from "simple-react-validator";
import { saveUserPaymentCredential } from "../../api/InvoiceApi";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";
import Taxes from "./Taxes/Taxes";
import InvoiceUseStyles from "./invoiceUseStyles";
import InvoicePageSubHeader from "./common/header/InvoicePageSubHeader";
import HelpIcon from '@material-ui/icons/Help';
import Styles from "./PaymentGateway.module.css";

const GATEWAY_TYPE_STRIPE = "STRIPE";
// const GATEWAY_TYPE_PAYPAL = "PAYPAL";

const gatewayCreds =
{
  [GATEWAY_TYPE_STRIPE]: [
    {
      type: "text",
      label: "Publishable Key",
      name: "publishable_key",
      required: true,
      placeholder: "Enter the publishable key",
      info: true
    },
    {
      type: "password",
      label: "Secret Key",
      name: "secret_key",
      required: true,
      placeholder: '***********'
    }
  ],
  // [GATEWAY_TYPE_PAYPAL]: [
  //   {
  //     type: "text",
  //     label: "ABCD",
  //     name: "publishable_key",
  //     required: true,
  //     placeholder:"Enter the publishable key"
  //   },
  //   {
  //     type: "password",
  //     label: "EFGH",
  //     name: "secret_key",
  //     required: true,
  //     placeholder:'***********'
  //   }
  // ]
};

const PaymentGateWay = () => {
  const classes = InvoiceUseStyles();
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(GATEWAY_TYPE_STRIPE);
  const [paymentCredentials, setPaymentCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  //validator
  const [forceUpdate, setForceUpdate] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator({
    autoForceUpdate: { forceUpdate: setForceUpdate },
    className: 'small text-danger mdi mdi-alert pt-1 pl-1'
  }));


  const savePaymentGateWay = (e) => {
    e.preventDefault();
    setLoading(true);
    if (simpleValidator.current.allValid()) {
      saveUserPaymentCredential({
        "payment_credentials": paymentCredentials,
        "type": selectedPaymentGateway,
        "payment_name": name,
      })
        .then((res) => {
          res = res.data;
          if (res.success) {
            Utils.showNotification("Payment credential saved successfully", "success");
          } else {
            Utils.showNotification(res.message || "Error occurred while saving payment credentials", "error");
          }
        })
        .catch((error) => {
          Utils.showNotification("Error occurred while saving payment credentials", "error");
        })
        .finally(() => {
          setLoading(false);

        });
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
      setLoading(false);
    }
    //}
  };

  const handlePaymentGatewayCredsFields = (name, value) => {
    setPaymentCredentials({ ...paymentCredentials, [name]: value });
  };

  return (
    <div className={Styles.payment_gateway_container}>
      {loading &&
        <Loader />
      }

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 50 }}>
        <Box>
          <div className={Styles.payment_info}>
            <div>
              <InvoicePageSubHeader
                title={"Payment Gateway"}
                description={
                  "Select your preferred payment processor from the dropdown menu."
                }
              />
            </div>
          </div>

          <div >


            <Select
              fullWidth
              labelId="select_payment_gateway"
              value={selectedPaymentGateway}
              className={`${classes.formControl} ${classes.select}`}
              label="STRIPE"
              onChange={(e) => {
                setSelectedPaymentGateway(e.target.value);
                setPaymentCredentials({});
              }}
            >
              <MenuItem className={classes.MuiDisabled} id="ibMenuDisabledId" disabled value="">
                Select a payment gateway
              </MenuItem>
              {Object.keys(gatewayCreds).map((gatewayType) => (
                <MenuItem className={classes.menuItem} id="ibMenuItemId" key={gatewayType} value={gatewayType}>
                  {gatewayType}
                </MenuItem>
              ))}
            </Select>
          </div>

          <br />
          <div>
            <h6>API Keys</h6>
            <p>In order to connect your Stripe account you’ll need to locate both your publishable and secret key.</p>
            <p>Copy/Paste in the boxes below.</p>
          </div>
          <div>
            <div>
              <h5 className={Styles.label_text}>Name</h5>
              <input
                className={Styles.input_tag} style={{ width: '100% !important' }}
                placeholder="Enter Name"
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="new-password"
              />
              {simpleValidator.current.message('name', name, 'required')}
            </div>

            {
              gatewayCreds[selectedPaymentGateway]?.map((field, index) => (
                <div className="input-field custom_input" key={index}>
                  <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5 className={Styles.label_text}>{field.label}</h5>
                    {field.info && <a href={process.env.REACT_APP_CDN_LINK + "asset/files/releases/1.0.1/Payment-Gateway-Setting-up-Stripe.pdf"} style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }} target={"_blank"}><HelpIcon fontSize={"small"} /> How do I find this info?</a>}
                  </Box>
                  <input className={Styles.input_tag} style={{ width: '100% !important' }} onChange={(e) => { handlePaymentGatewayCredsFields(field.name, e.target.value) }} type={field.type} placeholder={field.placeholder} autoComplete="new-password" />
                  {simpleValidator.current.message(field.name, paymentCredentials[field.name], 'required')}
                </div>
              ))
            }
          </div>
          <div className={Styles.saveButton}>
            <Button
              disabled={loading}
              variant="contained"
              style={{ backgroundColor: "#3C7EF3", color: "white", width: "115px", fontSize: "16px" }}
              onClick={savePaymentGateWay}
            >
              Save
            </Button>
          </div>
        </Box>

        <Divider orientation="vertical" flexItem />

        <Taxes />
      </Box>

    </div>
  );
};


export default PaymentGateWay;
