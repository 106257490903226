import { useEffect, useState } from "react"
import { Button, CircularProgress, Grid, InputBase, Typography, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { buyVirtualNumber, getVirtualSearchNumberList } from "../../api/profileApi";
import { useIsMounted } from "../../hooks/IsMounted";
import TablePagination from '@material-ui/core/TablePagination';
import GlobalModal from "../globals/Modal/GlobalModal";
import BasicSelect from "../products/common/BasicSelect";
const BasicInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "&.MuiInputBase-multiline": {
        padding: "0 !important",
      },
      "& .MuiInputAdornment-positionEnd": {
        position: "absolute",
        right: "12px",
      },
      "& .pricePadding": {
              paddingLeft: "24px !important"
          }
    },
  
    input: {
      position: "relative",
      // color: "var(--dark_blue)",
      backgroundColor: "white !important",
      fontSize: "14px !important",
      margin: "0 !important",
      height: "2.5rem !important",
      padding: "10px 12px 10px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
  
      "&:focus": {
        borderRadius: 4,
        boxShadow: "unset !important",
      },
  
      "&.MuiSelect-select": {
        height: "1rem !important",
      },
    },
  }))(InputBase);
  
const AddVirtualNumberPopup = (props) => {

    const perPage = 10;

    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [displayResult, setDisplayResult] = useState(false);
    const [country, setCountry] = useState('US');
    const [areaCode, setAreaCode] = useState('');
    const [data, setData] = useState([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [submitting,setSubmitting] = useState(false);

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]);    // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = (needToReload = false) => {
        props.handleModal(needToReload)
    }


    const searchPhoneNumber = () => {
        setLoading(true);

        let formData = {
            country: country,
            areaCode: areaCode
        }

        getVirtualSearchNumberList({
            "form_data": formData
        })
        .then(response => {
            setData(response.data.data);
        })
        .finally(() => {
            setDisplayResult(true);
            setLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        // setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setPerPage(parseInt(event.target.value, 10));
        // setCurrentPage(0);
    };

    const returnToSearch = (e) => {
        e.preventDefault();
        setData([]);
        setQuery('');
        setDisplayResult(false);
    }

    const buyNumber = (_number) => {
        setLoading(true);
        setSubmitting(true);

        buyVirtualNumber({
            "form_data": {
                type: 'single', 
                'getaway_provider_id': 1, 
                virtual_number: _number, 
            }
        }).then(response => {
            if (response.data.status === 'success') {
              window.showNotification("SUCCESS", 'Number added successfully')
              toggle(true);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                      window.showNotification("ERROR", error)
                    });
                }
            } else {
              window.showNotification("ERROR", response.data.message)
            }
        }).finally(() => {
            setLoading(false);
            setSubmitting(false);
        })
    }

    const renderTable = () => {
        if (!data.length) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{paddingTop: 50, paddingLeft:'430px'}}>
                            NO DATA AVAILABLE. <a href="#!" onClick={returnToSearch} style={{cursor:'pointer'}}>SEARCH AGAIN</a>.
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {

            // eslint-disable-next-line array-callback-return
            return data.filter((item) => {
                let keyword = debouncedQuery.toUpperCase().trim();
                if (keyword !== '') {
                    if (item.phone_number.toUpperCase().indexOf(keyword) > -1) {
                        return true;
                    }
                } else {
                    return true;
                }
            }).map((item,index) => (
                <tr key={index}>
                    <td>{item.phone_number}</td>
                    <td>{item.friendly_name}</td>
                    <td>{item.iso_country}</td>
                    <td>{item.postal_code}</td>
                    <td>
                        <span class="action">
                            <Button style={{backgroundColor:'#346fef'}} className={" add__virtual__number__button"} variant="contained"  onClick={() => buyNumber(item.phone_number)}>
                                Add Number
                            </Button>
                        </span>
                    </td>
                </tr>
            ))
        }
    }

    const renderSearch = () => (
        <Grid container>
            <Grid item xs={12} className="mb-5">
                <Alert severity="info">In the US and Canada, these regions are represented by area codes. For example,+1(415)XXX-XXXX numbers are located in the San Francisco Bay Area.</Alert>
            </Grid>
            <Grid item xs={12} className="mb-2">
                <label>
                    Country
                </label>
            </Grid>
            <Grid item xs={12} className="mb-2">
                 <BasicSelect
                    value={country}
                    defaultText="Select Country"
                    options={[{value:'US',label:'US',},{value:'CA',label:'CANADA'}]}
                    onChange={(e) => { setCountry(e.target.value)   }}
                />
            </Grid>
            <Grid item xs={12} className="mb-2">
                <label>
                    Area code
                </label>
            </Grid>
            <Grid item xs={12} className="mb-2">
                <BasicInput
                type="text"
                fullWidth
                placeholder="Write an area"
                value={areaCode}
                onChange={e => setAreaCode(e.target.value)}
                />

            </Grid>
            <Grid item xs={12} className="mb-4">
                <Button style={{backgroundColor:'#346fef'}} variant="contained"  disabled={loading} onClick={searchPhoneNumber}>
                    {loading ? 'Searching...' : 'Search Phone Number'}
                </Button>
            </Grid>
        </Grid>
    )

    const renderResult = () => (
        <>
            <div class="container-fluid mail__inbox">
                <div class="tagBox">
                    <div class="tag_list">
                        <h5>Free Numbers List</h5>
                    </div>
                    <div class="add_new_user">
                        {/* <a href="#!" class="modal-trigger accent--bg--text--color" onClick={returnToSearch}>
                            Back
                        </a> */}
                        <Button style={{backgroundColor:'#346fef'}} variant="contained"  onClick={returnToSearch}>
                    Back
                </Button>
                    </div>
                </div>
                <div class="details">
                    <div class="items">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        </div>
                        <div class="record_search">
                            <input type="text" placeholder="Search Number" class="search" onChange={(e) => setQuery(e.target.value)}/>
                            <div class="search_icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                </svg>
                            </div>							
                        </div>
                    </div>
                   { submitting ? (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress/>
                        <Typography style={{marginTop:'120px', marginBottom:'20px'}}>Please hold on until the purchase is complete.</Typography>
                    </div>

                   ) : (
                   <table>
                        <thead>
                            <tr class="header">
                                <th>
                                    Phone Number
                                    {/* <svg onClick={() => {
                                        setSortBy('phone_number');
                                        if (sortType === 'asc') {
                                            setSortType('desc');
                                        } else {
                                            setSortType('asc');
                                        }
                                    }}
                                    style={{cursor: 'pointer', float: 'right'}}
                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                    </svg> */}
                                </th>
                                <th>
                                    Friendly Number
                                    {/* <svg onClick={() => {
                                        setSortBy('friendly_name');
                                        if (sortType === 'asc') {
                                            setSortType('desc');
                                        } else {
                                            setSortType('asc');
                                        }
                                    }}
                                    style={{cursor: 'pointer', float: 'right'}}
                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                    </svg> */}
                                </th>
                                <th>
                                    ISO Country
                                    {/* <svg onClick={() => {
                                        setSortBy('iso_country');
                                        if (sortType === 'asc') {
                                            setSortType('desc');
                                        } else {
                                            setSortType('asc');
                                        }
                                    }}
                                    style={{cursor: 'pointer', float: 'right'}}
                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                    </svg> */}
                                </th>
                                <th>
                                    Postal Code
                                    {/* <svg onClick={() => {
                                        setSortBy('postal_code');
                                        if (sortType === 'asc') {
                                            setSortType('desc');
                                        } else {
                                            setSortType('asc');
                                        }
                                    }}
                                    style={{cursor: 'pointer', float: 'right'}}
                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                    </svg> */}
                                </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody class="tbody_content">
                            {renderTable()}
                        </tbody>
                    </table>
                )}
                </div>
                {
                    false && (
                        <div className="mt-3">
                            <TablePagination
                                component="div"
                                count={data.length}
                                // page={currentPage}
                                onChangePage={handleChangePage}
                                rowsPerPage={perPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    )
                }
            </div>
        </>
    )

    return (
        <GlobalModal
            open={props.isOpen}
            title={"Buy number"}
            onClose={() => toggle(false)}
            hideFooter={true}
            className="global-large-modal"
        >   
            {
                !displayResult ? renderSearch() : renderResult()
            }
        </GlobalModal>
    )
}

export default AddVirtualNumberPopup;