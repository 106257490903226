import * as ACTION from "../constants/productActionTypes";

const initialState = {
  productFolder: {
    productFolders: [],
    selectedFolder: "all",

    isLoading: false,
    isError: false,
    error: "",
  },
  policyTypeFolder: {
    policyTypeFolders: [],
    selectedFolder: "all",

    isAddPolicyTypeFolderSuccessful: false,

    isLoading: false,
    isError: false,
    error: "",
  },

  product: {
    productList: [],
    isLoading: false,
    isError: false,
    error: "",

    isAddProductSuccessful: false,
    isDeleteProductSuccessful: false,

    pagination: { page: 1, perPage: 10 },
    totalPage: 0,
    totalCount: 0,
  },
  policyType: {
    policyTypeList: [],
    isLoading: false,
    isError: false,
    error: "",

    isAddPolicyTypeSuccessful: false,
    isDeletePolicyTypeSuccessful: false,

    pagination: { page: 1, perPage: 10 },
    totalPage: 0,
    totalCount: 0,
  },

  pipelines: [],
  teamUsers: [],
  isLoadingAssets: true,
};

const productReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case ACTION.GET_PRODUCT_FOLDERS:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_PRODUCT_FOLDERS_SUCCESS:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          productFolders: payload.data,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_PRODUCT_FOLDERS_FAILURE:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.ADD_PRODUCT_FOLDER:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_PRODUCT_FOLDER_SUCCESS:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          productFolders: [payload, ...state.productFolder.productFolders],
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_PRODUCT_FOLDER_FAILURE:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.UPDATE_PRODUCT_FOLDER:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_PRODUCT_FOLDER_SUCCESS:
      console.log(payload);
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          productFolders: state.productFolder.productFolders.map((folder) =>
            folder.id === payload.id
              ? { ...folder, ...payload.payload }
              : folder
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_PRODUCT_FOLDER_FAILURE:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DELETE_PRODUCT_FOLDER:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_FOLDER_SUCCESS:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          productFolders: state.productFolder.productFolders.filter(
            (folder) => folder.id !== payload
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_FOLDER_FAILURE:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.SELECT_PRODUCT_FOLDER:
      return {
        ...state,
        productFolder: {
          ...state.productFolder,
          selectedFolder: payload,
        },
      };

    case ACTION.GET_PRODUCTS:
      return {
        ...state,
        product: {
          ...state.product,
          productList: [],
          pagination: { page: payload.page, perPage: payload.perPage },
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          ...payload,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.ADD_PRODUCT:
      return {
        ...state,
        product: {
          ...state.product,
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_PRODUCT_SUCCESS:
      console.log(payload);
      return {
        ...state,
        product: {
          productList: [payload, ...state.product.productList],
          isError: false,
          error: "",
          isAddProductSuccessful: true,
        },
      };
    case ACTION.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.RESET_ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          isAddProductSuccessful: false,
        },
      };

    case ACTION.UPDATE_PRODUCT:
      return {
        ...state,
        product: {
          ...state.product,
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          productList: state.product.productList.map((product) =>
            product.id === payload.id
              ? { ...product, ...payload.payload.payload, ...payload.payload }
              : product
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DELETE_PRODUCT:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isDeleteProductSuccessful: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };
    case ACTION.RESET_DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          isDeleteProductSuccessful: false,
        },
      };

    case ACTION.DELETE_PRODUCT_FOREVER:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_FOREVER_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          isDeleteProductSuccessful: true,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_PRODUCT_FOREVER_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.TOGGLE_FAVORITE:
      return {
        ...state,
        product: {
          ...state.product,
          isError: false,
          error: "",
        },
      };
    case ACTION.TOGGLE_FAVORITE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        product: {
          ...state.product,
          productList: state.product.productList.map((product) =>
            product.id === action.payload.id
              ? { ...product, is_favorite: action.payload.is_favorite }
              : product
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.TOGGLE_FAVORITE_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DESELECT_ALL_PRODUCTS:
      return {
        ...state,
        product: {
          ...state.product,
          productList: [],
        },
      };

    case ACTION.GET_POLICY_TYPE_FOLDERS:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_POLICY_TYPE_FOLDERS_SUCCESS:
      console.log(payload);
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          policyTypeFolders: payload.data,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_POLICY_TYPE_FOLDERS_FAILURE:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.ADD_POLICY_TYPE_FOLDER:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_POLICY_TYPE_FOLDER_SUCCESS:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          policyTypeFolders: [
            ...state.policyTypeFolder.policyTypeFolders,
            payload,
          ],
          isAddPolicyTypeFolderSuccessful: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_POLICY_TYPE_FOLDER_FAILURE:
      return {
        ...state,
        policyTypeFolder: {
          policyTypeFolders: [],
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };
    
      case ACTION.RESET_ADD_POLICY_TYPE_FOLDER:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isAddPolicyTypeFolderSuccessful: false,
        },
      };

    case ACTION.UPDATE_POLICY_TYPE_FOLDER:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_POLICY_TYPE_FOLDER_SUCCESS:
      console.log(payload);
      return {
        ...state,
        policyTypeFolder: {
          ...state.productFolder,
          policyTypeFolders: state.policyTypeFolder.policyTypeFolders.map(
            (folder) =>
              folder.id === payload.id
                ? { ...folder, ...payload.payload }
                : folder
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_POLICY_TYPE_FOLDER_FAILURE:
      return {
        ...state,
        policyTypeFolder: {
          policyTypeFolders: [],
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DELETE_POLICY_TYPE_FOLDER:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_FOLDER_SUCCESS:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          policyTypeFolders: state.policyTypeFolder.policyTypeFolders.filter(
            (folder) => folder.id !== payload
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_FOLDER_FAILURE:
      return {
        ...state,
        productFolder: {
          policyTypeFolders: [],
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.SELECT_POLICY_TYPE_FOLDER:
      return {
        ...state,
        policyTypeFolder: {
          ...state.policyTypeFolder,
          selectedFolder: payload,
        },
      };

    case ACTION.GET_POLICY_TYPES:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          policyTypeList: [],
          pagination: { page: payload.page, perPage: payload.perPage },
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_POLICY_TYPES_SUCCESS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          ...payload,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.GET_POLICY_TYPES_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.ADD_POLICY_TYPE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isError: false,
          error: "",
        },
      };
    case ACTION.ADD_POLICY_TYPE_SUCCESS:
      return {
        ...state,
        policyType: {
          isError: false,
          error: "",
          isAddPolicyTypeSuccessful: true,
        },
      };
    case ACTION.ADD_POLICY_TYPE_FAILURE:
      return {
        ...state,
        policyType: {
          policyTypeList: [],
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };
    case ACTION.RESET_ADD_POLICY_TYPE_SUCCESS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isAddPolicyTypeSuccessful: false,
        },
      };

    case ACTION.UPDATE_POLICY_TYPE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_POLICY_TYPE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        policyType: {
          ...state.policyType,
          policyTypeList: state.policyType.policyTypeList.map((policy) =>
            policy.id === payload.id
              ? { ...policy, ...payload.payload.payload, ...payload.payload }
              : policy
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_POLICY_TYPE_FAILURE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DELETE_POLICY_TYPE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_SUCCESS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: false,
          isDeletePolicyTypeSuccessful: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_FAILURE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };
    case ACTION.RESET_DELETE_POLICY_TYPE_SUCCESS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isDeletePolicyTypeSuccessful: false,
        },
      };

    case ACTION.UPDATE_POLICY_TYPE_STATUS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isError: false,
          error: "",
        },
      };
    case ACTION.UPDATE_POLICY_TYPE_STATUS_SUCCESS:
      return action.payload;
    case ACTION.UPDATE_POLICY_TYPE_STATUS_FAILURE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.TOGGLE_POLICY_TYPE_FAVORITE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isError: false,
          error: "",
        },
      };
    case ACTION.TOGGLE_POLICY_TYPE_FAVORITE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        policyType: {
          ...state.policyType,
          policyTypeList: state.policyType.policyTypeList.map((policy) =>
            policy.id === action.payload.id
              ? { ...policy, is_favorite: action.payload.is_favorite }
              : policy
          ),
          isError: false,
          error: "",
        },
      };
    case ACTION.TOGGLE_POLICY_TYPE_FAVORITE_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.DELETE_POLICY_TYPE_FOREVER:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_FOREVER_SUCCESS:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isDeletePolicyTypeSuccessful: true,
          isLoading: false,
          isError: false,
          error: "",
        },
      };
    case ACTION.DELETE_POLICY_TYPE_FOREVER_FAILURE:
      return {
        ...state,
        policyType: {
          ...state.policyType,
          isLoading: false,
          isError: true,
          error: payload.error,
        },
      };

    case ACTION.HANDLE_ASSET_LOADING_STATE:
      return {
        ...state,
        isLoadingAssets: payload,
      };
    case ACTION.SET_PIPELINES:
      return {
        ...state,
        pipelines: payload,
      };
    case ACTION.SET_TEAM_USERS:
      return {
        ...state,
        teamUsers: payload,
      };
    default:
      return state;
  }
};

export default productReducer;
