import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  addPolicyTypeFailure,
  addPolicyTypeFolderFailure,
  addPolicyTypeFolderSuccess,
  addPolicyTypeSuccess,
  addProductFailure,
  addProductFolderFailure,
  addProductFolderSuccess,
  addProductSuccess,
  deletePolicyTypeFailure,
  deletePolicyTypeFolderFailure,
  deletePolicyTypeFolderSuccess,
  deletePolicyTypeForeverFailure,
  deletePolicyTypeForeverSuccess,
  deletePolicyTypeSuccess,
  deleteProductFailure,
  deleteProductFolderFailure,
  deleteProductFolderSuccess,
  deleteProductForeverFailure,
  deleteProductForeverSuccess,
  deleteProductSuccess,
  getPolicyTypeFoldersFailure,
  getPolicyTypeFoldersSuccess,
  getPolicyTypesFailure,
  getPolicyTypesSuccess,
  getProductFoldersFailure,
  getProductFoldersSuccess,
  getProductsFailure,
  getProductsSuccess,
  toggleFavoriteFailure,
  toggleFavoriteSuccess,
  togglePolicyTypeFavoriteFailure,
  togglePolicyTypeFavoriteSuccess,
  updatePolicyTypeFailure,
  updatePolicyTypeFolderFailure,
  updatePolicyTypeFolderSuccess,
  updatePolicyTypeStatusFailure,
  updatePolicyTypeStatusSuccess,
  updatePolicyTypeSuccess,
  updateProductFailure,
  updateProductFolderFailure,
  updateProductFolderSuccess,
  updateProductSuccess,
} from "../actions/ProductAction";
import * as PRODUCT_API from "../api/productsApi";
import * as PRODUCT_ACTION from "../constants/productActionTypes";
import Utils from "../helpers/Utils";

function* productWatcher() {
  yield takeEvery(
    PRODUCT_ACTION.GET_PRODUCT_FOLDERS,
    getProductFoldersMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.ADD_PRODUCT_FOLDER,
    addProductFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_PRODUCT_FOLDER,
    updateProductFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_PRODUCT_FOLDER,
    deleteProductFolderMiddleware
  );
  yield takeEvery(PRODUCT_ACTION.GET_PRODUCTS, getProductsMiddleware);
  yield takeEvery(PRODUCT_ACTION.ADD_PRODUCT, addProductMiddleware);
  yield takeEvery(PRODUCT_ACTION.UPDATE_PRODUCT, updateProductMiddleware);
  yield takeEvery(PRODUCT_ACTION.DELETE_PRODUCT, deleteProductMiddleware);
  yield takeEvery(
    PRODUCT_ACTION.DELETE_PRODUCT_FOREVER,
    deleteProductForeverMiddleware
  );
  yield takeEvery(PRODUCT_ACTION.TOGGLE_FAVORITE, toggleFavoriteMiddleware);

  yield takeEvery(
    PRODUCT_ACTION.GET_POLICY_TYPE_FOLDERS,
    getPolicyTypeFoldersMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.ADD_POLICY_TYPE_FOLDER,
    addPolicyTypeFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_POLICY_TYPE_FOLDER,
    updatePolicyTypeFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_POLICY_TYPE_FOLDER,
    deletePolicyTypeFolderMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.GET_POLICY_TYPES,
    getPolicyTypesMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.ADD_POLICY_TYPE,
    addPolicyTypeMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_POLICY_TYPE,
    updatePolicyTypeMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_POLICY_TYPE,
    deletePolicyTypeMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.UPDATE_POLICY_TYPE_STATUS,
    updatePolicyTypeStatusMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.TOGGLE_POLICY_TYPE_FAVORITE,
    togglePolicyTypeFavoriteMiddleware
  );
  yield takeEvery(
    PRODUCT_ACTION.DELETE_POLICY_TYPE_FOREVER,
    deletePolicyTypeForeverMiddleware
  );
}

function* getProductFoldersMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.getProductFoldersApi);
    if (response.data.success) {
      yield put(getProductFoldersSuccess(response.data.data));
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(getProductFoldersFailure(error.message));
    Utils.handleException(error);
  }
}

function* addProductFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.addProductFolderApi,
      action.payload
    );

    if (response.data.success) {
      yield put(addProductFolderSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(addProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* updateProductFolderMiddleware(action) {
  try {
    console.log(action.payload);
    const response = yield call(
      PRODUCT_API.updateProductFolderApi,
      action.payload
    );
    if (response.data.success) {
      yield put(updateProductFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updateProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deleteProductFolderApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deleteProductFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* getProductsMiddleware(action) {
  const { payload } = action;
  const { callback } = payload || {};

  try {
    const response = yield call(PRODUCT_API.getProductsApi, payload);
    const responseData = response?.data;

    if (responseData?.success) {
      const { data, totalPage, totalCount } = responseData;
      const productData = {
        productList: data,
        ...(totalPage && { totalPage, totalCount }),
      };
      yield put(getProductsSuccess(productData));
    } else {
      throw new Error("API call was not successful");
    }
  } catch (error) {
    yield put(getProductsFailure(error.message));
    Utils.handleException(error);
    console.error("Error fetching products:", error);
  }
}

function* addProductMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.addProductApi, action.payload);

    console.log(response.data);
    if (response.data.success) {
      yield put(addProductSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(addProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* updateProductMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.updateProductApi, action.payload);

    if (response.data.success) {
      const data = {
        id: action.payload.id,
        payload: { ...action.payload, folders: response.data.data.folders },
      };

      yield put(updateProductSuccess(data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (
      action.payload &&
      action.payload.payload &&
      action.payload.payload.callback
    ) {
      action.payload.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updateProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.deleteProductApi, action.payload);

    if (response.data.success) {
      yield put(deleteProductSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deleteProductForeverMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deleteProductForeverApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deleteProductForeverSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deleteProductForeverFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* toggleFavoriteMiddleware(action) {
  console.log(action.payload);
  try {
    const response = yield call(PRODUCT_API.toggleFavoriteApi, action.payload);
    if (response.data.success) {
      yield put(toggleFavoriteSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(toggleFavoriteFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* getPolicyTypeFoldersMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.getPolicyTypeFoldersApi);
    if (response.data.success) {
      yield put(getPolicyTypeFoldersSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getPolicyTypeFoldersFailure(error.message));
    Utils.handleException(error);
  }
}
function* addPolicyTypeFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.addPolicyTypeFoldersApi,
      action.payload
    );

    if (response.data.success) {
      yield put(addPolicyTypeFolderSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }
  } catch (error) {
    yield put(addPolicyTypeFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}
function* updatePolicyTypeFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.updatePolicyTypeFoldersApi,
      action.payload
    );
    if (response.data.success) {
      yield put(updatePolicyTypeFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updatePolicyTypeFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}
function* deletePolicyTypeFolderMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deletePolicyTypeFoldersApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deletePolicyTypeFolderSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deletePolicyTypeFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* getPolicyTypesMiddleware(action) {
  const { payload } = action;
  const { callback } = payload || {};

  try {
    // const response = yield call(PRODUCT_API.getPolicyTypeApi, payload);
    const response = yield call(PRODUCT_API.getPolicyTypeApi, payload);
    const responseData = response?.data;

    if (responseData?.success) {
      const { data, totalPage, totalCount } = responseData;
      console.log(data);
      const payload = {
        policyTypeList: data,
        ...(totalPage && { totalPage, totalCount }),
      };
      yield put(getPolicyTypesSuccess(payload));
    } else {
      throw new Error("API call was not successful");
    }
  } catch (error) {
    yield put(getPolicyTypesFailure(error.message));
    Utils.handleException(error);
    console.error("Error fetching products:", error);
  }
}
function* addPolicyTypeMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.addPolicyTypeApi, action.payload);

    if (response.data.success) {
      yield put(addPolicyTypeSuccess(response.data.data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(addPolicyTypeFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}
function* updatePolicyTypeMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.updatePolicyTypeApi, action.payload);
    console.log(action.payload);
    if (response.data.success) {
      const data = {
        id: action.payload.id,
        payload: { ...action.payload, folders: response?.data?.data?.folders },
      };

      yield put(updatePolicyTypeSuccess(data));
      window.showNotification("SUCCESS", response.data.message);
    }

    if (
      action.payload &&
      action.payload.payload &&
      action.payload.payload.callback
    ) {
      action.payload.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updatePolicyTypeFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}
function* deletePolicyTypeMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.deleteProductApi, action.payload);

    if (response.data.success) {
      yield put(deletePolicyTypeSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deletePolicyTypeFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}
function* updatePolicyTypeStatusMiddleware(action) {
  try {
    const response = yield call(PRODUCT_API.updatePolicyTypeStatusApi, action.payload);
    console.log(action.payload);
    if (response.data.success) {
      // yield put(updatePolicyTypeStatusSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }

    if (
      action.payload &&
      action.payload.payload &&
      action.payload.payload.callback
    ) {
      action.payload.payload.callback(response.data);
    }
  } catch (error) {
    yield put(updatePolicyTypeStatusFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* togglePolicyTypeFavoriteMiddleware(action) {
  console.log(action.payload);
  try {
    const response = yield call(PRODUCT_API.togglePolicyTypeFavoriteApi, action.payload);
    if (response.data.success) {
      yield put(togglePolicyTypeFavoriteSuccess(action.payload));
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(togglePolicyTypeFavoriteFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* deletePolicyTypeForeverMiddleware(action) {
  try {
    const response = yield call(
      PRODUCT_API.deletePolicyTypeForeverApi,
      action.payload
    );
    if (response.data.success) {
      yield put(deletePolicyTypeForeverSuccess());
      window.showNotification("SUCCESS", response.data.message);
    }
    if (action.payload && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (error) {
    yield put(deletePolicyTypeForeverFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}


export default function* productMiddleware() {
  yield all([productWatcher()]);
}
