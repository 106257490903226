import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import MicIcon from "@material-ui/icons/Mic";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {
  callRecordingDisclosureSubmit,
  deleteVirtualNumberSetting,
} from "../../../api/profileApi";
import { Popover, Typography } from "@material-ui/core";
import { AddCircle, VolumeUp } from "@material-ui/icons";
import AudioRecord from "./AudioRecord";
import { CustomButton } from "../../emailSettings/connectedEmailV2/ConnectedEmailStyle";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";

const CallRecordingDisclosureEdit = ({ item }) => {
  const inputField = useRef(null);
  const [validation, setValidation] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldValue, setFieldValue] = useState(item.record_warning_message);
  const [mouseOver, setMouseOver] = useState(false);
  const [defaultValue, setDefaultValue] = useState(item.record_warning_message);
  const [audio, setAudio] = useState(item.record_warning_audio);
  const [defaultAudio, setDefaultAudio] = useState(item.record_warning_audio);
  const [openAudio, setOpenAudio] = useState(
    item.record_warning_audio !== null
  );
  const [defaultOpenAudio, setDefaultOpenAudio] = useState(
    item.record_warning_audio !== null
  );
  const [editedFieldValue, setEditedFieldValue] = useState(
    item.record_warning_message
  );
  const inputRef = useRef();
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleCrossPress = () => {
    setEdit(false);
    setValidation(false);
    setValidationText("");
    setAudio(defaultAudio);
    setOpenAudio(defaultOpenAudio);
    setAnchorEl(null);
    setEditedFieldValue(fieldValue)
  };

  const handleEnterPress = (event) => {
    var code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      handleSubmit();
    }
  };

  const handleCheckPress = () => {
    handleSubmit();
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("id", item.id);
    formData.append("warning_message", openAudio ? "" : editedFieldValue);
    if (openAudio) {
      formData.append("is_play_warning_audio", 1);
      formData.append("warning-audio-file", audio);
    } else {
      formData.append("is_play_warning_message", 1);
    }

    setSaving(true);
    callRecordingDisclosureSubmit(formData).then((res) => {
      setSaving(false);
      if (res.data.status === "validation-error") {
        window.showNotification(
          "ERROR",
          res.data.message[Object.keys(res.data.message)[0]][0]
        );
      } else if (res.data.status === "error") {
        window.showNotification("ERROR", res.data.message);
      } else if (res.data.status === "success") {
        setFieldValue(editedFieldValue);
          setEdit(false);
        if (openAudio) {
          setAudio(res.data.data.audioFile);
          setDefaultAudio(res.data.data.audioFile);
        } else {
          setDefaultValue(res.data);
        }
        setAnchorEl(null);
        window.showNotification("SUCCESS", "Call Recording Disclosure Changed");      
      }
    });
  };

  const deleteSetting = () => {
    NewConfirmAlert({
      onSubmit: () => {
        setSaving(true);
        deleteVirtualNumberSetting({
          id: item.id,
          setting: "call_recording_disclosure",
        }).then((res) => {
          setSaving(false);
          if (res.data.status === "validation-error") {
            window.showNotification(
              "ERROR",
              res.data.message[Object.keys(res.data.message)[0]][0]
            );
          } else if (res.data.status === "error") {
            window.showNotification("ERROR", res.data.message);
          } else if (res.data.status === "success") {
            setDefaultValue("");
            setFieldValue("");
            setEditedFieldValue("");
            setAudio(null);
            setOpenAudio(false);
            setDefaultOpenAudio(false);
            setAnchorEl(null);
            window.showNotification(
              "SUCCESS",
              "Call Recording Disclosure Deleted"
            );
          }
        });
      },
      title: "Are You Sure?",
      description: "Are you sure to delete this setting?",
      cancelText: 'No, Keep it',
      submitText: 'Yes, Delete it',
      width: "480px",
    });
  };


  const divStyle = {
    width: "230px",
    border: mouseOver ? "1px solid #000" : "1px solid transparent",
    borderRadius: "8px",
    transition: "border 0.3s ease, padding 0.3s ease",
    paddingLeft: "8px",
    paddingRight: "8px",
    cursor: "pointer",
    minHeight: "40px",
  };

  const inputStyle = {
    margin: "0px",
    width: "100%",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    padding: "12px",
  };

  const inputEditStyle = {
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    fontSize: "inherit",
    paddingLeft: "4px",
    paddingRight: "4px",
    boxShadow: "none",
    textDecoration: "none",
    border: "1px solid gray",
    borderRadius: "8px",
  };

  const dropdownStyle = {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    width: "420px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const handleDivClick = (event) => {
    if (openAudio) {
      setEdit(false);
    } else if (!fieldValue) {
      setEdit(true);
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={divStyle}
        onClick={handleDivClick}
        ref={dropdownRef}
      >
        {openAudio ? (
          <span>
            <BootstrapTooltip arrow title={`Audio`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px 0px",
                }}
              >
                <VolumeUp
                  style={{ marginTop: "6px", marginBottom: "6px" }}
                  fontSize="medium"
                />
              </div>
            </BootstrapTooltip>
          </span>
        ) : fieldValue ? (
          <span>
            <BootstrapTooltip arrow title={fieldValue}>
              <Typography 
               onMouseEnter={() => setMouseOver(true)}
               onMouseLeave={() => setMouseOver(false)}
              style={inputStyle}
              >
                {fieldValue}
                </Typography>
            </BootstrapTooltip>
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <AddCircle
              style={{ marginTop: "6px", marginBottom: "6px" }}
              fontSize="medium"
            />
          </div>
        )}
      </div>
      <Popover
        ref={inputRef}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleCrossPress}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={dropdownStyle}>
          <div className="d-flex align-items-center justify-content-between">
            {validation && (
              <small className="text-danger">{validationText}</small>
            )}
          </div>
          <div>
           {edit &&( 
            <>
              {openAudio ? (
                <>
                <AudioRecord voiceRecordCallback={(data) => setAudio(data)} />
                { edit && !saving && ( <CustomButton onClick={() => {
                          setOpenAudio(false)
                        }}>
                      Use text
                    </CustomButton>)}
                  </>
              ) : (
                <>
                <input
                  ref={inputField}
                  value={editedFieldValue}
                  onKeyPress={handleEnterPress}
                  onChange={(e) => setEditedFieldValue(e.target.value)}
                  type="text"
                  defaultValue={editedFieldValue}
                  placeholder={"Enter Whisper Message"}
                  style={inputEditStyle}
                />
                {edit && !saving && ( <CustomButton onClick={() => {setOpenAudio(true)}}>
                      Use Audio 
                    </CustomButton>)}
                </>
              )}
            </>
          )}
            {!edit && (
                <>
              {openAudio ? (
                <audio style={{ width: "100%" }} controls playsInline>
                  <source src={audio} type="audio/mpeg" />
                </audio>
              ) : (
                <div
                  style={{
                    padding: "10px",
                    maxWidth: "90%",
                    wordBreak: "break-word",
                  }}
                >
                  {fieldValue}
                </div>
              )}
            </>)}
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {!edit && (
                  <div style={{ display: "flex", gap: "16px" }}>
                     <BootstrapTooltip arrow title={`Update Voicemail Message`}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        <CreateIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                    {((defaultValue !== "" && defaultValue !== null) ||
                      defaultAudio) && (
                      <BootstrapTooltip
                        arrow
                        title={`Delete Voicemail Setting`}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={deleteSetting}
                        >
                          <DeleteIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                   
                    {saving && (
                      <BootstrapTooltip
                        arrow
                        title={`Updating Voicemail Message`}
                      >
                        <span>
                          <MoreHorizIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                  </div>
                )}
              </div>
              {edit && !saving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                    marginTop: "10px",
                  }}
                >
                  <BootstrapTooltip arrow title="Confirm">
                    {(editedFieldValue && editedFieldValue !== defaultValue) || (openAudio && audio !== defaultAudio) ? (
                      <span
                        onClick={handleCheckPress}
                        style={{ cursor: "pointer" }}
                      >
                        <DoneIcon fontSize="small" />
                      </span>
                    ) : (
                      <></>
                    )}
                  </BootstrapTooltip>
                  <BootstrapTooltip arrow title="Cancel">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleCrossPress}
                    >
                      <ClearIcon fontSize="small" />
                    </span>
                  </BootstrapTooltip>
                </div>
              )}
            </>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default CallRecordingDisclosureEdit;
