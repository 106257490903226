import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CreateNewFolderModal, {
  BasicInput,
} from "../sidebar/CreateNewFolderModal";
import { insuranceCompanyStyle } from "./InsuranceCompanyStyle";
import AddIcon from "@material-ui/icons/Add";
import BasicSelect from "../../policyTypes/content/BasicSelect";
import {
  addInsuranceCompanyApi,
  updateInsuranceCompanyNameApi,
} from "../../../../api/productsApi";
import InsuranceCommonSkeleton from "../../common/InsuranceCommonSkeleton";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
  description: {
    border: "1px solid #ced4da !important",
    borderRadius: "4px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-multiline": {
      color: "var(--dark_blue)",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    height: "24px ",
    color: "var(--dark_blue)",
  },
  multipleSelect: {
    "& .MuiSelect-select": {
      // paddingTop: "6px !important",
      display: "flex",
      alignItems: "center",
    },
  },
  restoreText: {
    color: "#0b0be1",
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    userSelect: "none",
  },
});
const AddOrEditNewCompanyModal = ({
  open,
  onClose,
  edit,
  setInsuranceCompanyList,
  folderList,
  handleFolderList,
  selectedFolder,
  onUpdateCompanyName,
}) => {
  const [title, setTitle] = useState(edit?.company_name || "");
  const [category, setCategory] = useState(selectedFolder || null);
  const [error, setError] = useState({
    title: "",
  });
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [isFolderAdded, setIsFolderAdded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isFolderAdded) {
      setCategory(isFolderAdded?.id);
      setIsFolderAdded(false);
    }
  }, [isFolderAdded]);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
    };

    if (title.trim() === "") {
      errors.title = "Company name is required";
      isValid = false;
    }
    if (title.length > 100) {
      errors.title = "Company name must be less than 100 characters";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSelectCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!validateForm()) {
        return;
      }

      if (edit) {
        const payload = {
          company_name: title,
          insurance_company_id: edit?.id,
        };
        const response = await updateInsuranceCompanyNameApi(payload);
        if (response?.success) {
          window.showNotification("SUCCESS", response.message || "Success");
          onUpdateCompanyName(payload);
        } else {
          window.showNotification("ERROR", response.message);
        }
        onClose();
      } else {
        const payload = {
          company_name: title,
          insurance_company_folder_id: selectedFolder,
        };

        const response = await addInsuranceCompanyApi(payload);

        if (response?.success) {
          window.showNotification("SUCCESS", response.message || "Success");
          setInsuranceCompanyList((prevState) => [response.data, ...prevState]);
        } else {
          window.showNotification("ERROR", response.message);
        }
        onClose();
      }
    } catch (error) {
      console.log(error);
    } finally{
      setIsSubmitting(false);
    }
  };

  const classes = useStyles();
  const { topbarNewProductButton, lightBlue } = insuranceCompanyStyle();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        minWidth={500}
        // minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          sx={{ borderBottom: "1px solid #d3d3d3" }}
        >
          <Typography className={classes.titleHeader}>
            {edit ? "Edit Company" : "Add a New Company"}
          </Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
      {isSubmitting ? (
        <InsuranceCommonSkeleton/>
      ) : (  
      <Box padding={2}>
          <Box mb={2}>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Company Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt={1}>
              <BasicInput
                fullWidth
                value={title}
                className={`${error.title ? classes.errorStyle : ""}`}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (error.title) {
                    setError((prevState) => ({ ...prevState, title: "" }));
                  }
                }}
                required
              />
              {error.title && (
                <Typography variant="body2" className={classes.errorText}>
                  {error.title}
                </Typography>
              )}
            </Box>
          </Box>

          {!edit && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Select Category</Typography>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<AddIcon />}
                  style={{ color: "#346FEF" }}
                  onClick={() => setOpenAddFolderModal(true)}
                >
                  Create
                </Button>
              </Box>
              <Box mt={1}>
                <BasicSelect
                  options={folderList}
                  defaultText="Select Category"
                  mapping={{
                    value: "id",
                    label: "title",
                  }}
                  value={category || ""}
                  onChange={handleSelectCategory}
                />
              </Box>
            </Box>
          )}
        </Box>)}

        <Box
          sx={{ borderTop: "1px solid #d3d3d3" }}
          padding={2}
          display={"flex"}
          justifyContent={"end"}
        >
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              color="primary"
              className={lightBlue}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={topbarNewProductButton}
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>

      {openAddFolderModal && (
        <CreateNewFolderModal
          open={openAddFolderModal}
          onClose={() => setOpenAddFolderModal(false)}
          handleFolderList={handleFolderList}
          setIsFolderAdded={setIsFolderAdded}
        />
      )}
    </Dialog>
  );
};

export default AddOrEditNewCompanyModal;
