import {
  Grid,
  TablePagination,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import LostReasonTable from "./LostReasonTable";
import { LostReasonStyle } from "../LostReasonStyle";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

const LostReasonBody = () => {
  const { grayColor } = LostReasonStyle();

  const classes = useStyles();
  return (
    <div className={classes.bodyWrapper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography style={{height:'unset !important'}} variant="body2" className={grayColor}>
            When you lose a deal it's important to document why. <br />
            This data can be used to improve your sales processes and to help
            close more deals in the future
          </Typography>
          <Typography style={{ color: "#2D6CFF",fontWeight:'500',marginTop:'12px',height:'unset !important' }}>
            Here is a list of popular lost deal reasons. Feel free to add your own
          </Typography>
        </div>
      </div>

      <LostReasonTable />

      <div className={classes.paginationWrapper}>
        <div style={{ fontStyle: "italic", color: "#2D6CFF" }}>
          <span className={classes.textFontWeight}>
            **Be sure to choose your lost deal reasons carefully. <br />
            This data will be used to compile reports that outline the reasons
            you are losing deals
          </span>
        </div>
      </div>
    </div>
  );
};

export default LostReasonBody;
