import {OutlinedInput, styled} from "@material-ui/core";


const StyledOutlinedInput = styled(OutlinedInput)(({ textAlign }) => ({
  "label + &": {
    marginTop: 24,
  },
  "& input": {
    height: "40px !important",
    // padding: "0 14px !important",
    paddingRight: "14px !important",
    margin: "0 !important",
    border: "none !important",
    textAlign: textAlign || "left",
    "&.MuiOutlinedInput-input": {
      fontWeight: 400,
    },
    // "&.MuiInputBase-inputSizeSmall": {
    //   padding: "0 12px !important",
    // },
    "&:focus": {
      border: "none !important",
      boxShadow: "none !important",
    },
  },
  "& .MuiInputBase-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
      },
    },
    "&.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#e2e3e9",
      },
    },
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#e2e3e9",
  },
  "&.ib_editor_light_textField": {
    "& input,& textarea": {
      fontWeight: 300,
      color: "#333333",
    },
  },
}));

export default StyledOutlinedInput;
