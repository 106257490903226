import React, {useContext, useEffect} from "react";
import {LeadPermissionRequestContext} from "./StateProvider";
import {
    acceptContactRequest,
    getLeadPermissionRequests,
    rejectContactRequest,
    subUserListForContactDropdown
} from "../../api/profileApi";
import ActionTypes from "./ActionTypes";
import {ConstantsLabel} from "./Constants";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import NewConfirmAlert from "../activity/new-alert/NewConfirmAlert";
import LeadGroupSkeleton from "../group/LeadGroupSkeleton";

const PermissionTable = (props) => {
  const {state, dispatch} = useContext(LeadPermissionRequestContext);

  useEffect(()=>{
      dispatch({
          type: ActionTypes.UPDATE_TABLE_DATA,
          payload: {
              isLoading: true
          }
      });
      try{
          subUserListForContactDropdown()
              .then((res)=>{
                  res = res.data;
                  if (res){
                      dispatch({
                          type: ActionTypes.UPDATE_TABLE_DATA,
                          payload: {
                              isLoading: false,
                              subUserList: res.data
                          }
                      });
                  }
              }).catch((err)=>{
              dispatch({
                  type: ActionTypes.UPDATE_TABLE_DATA,
                  payload: {
                      isLoading: false,
                      subUserList: []
                  }
              });
          }).finally(()=>{
              dispatch({
                  type: ActionTypes.UPDATE_TABLE_DATA
              });
          });
      }catch (err){
          dispatch({
              type: ActionTypes.UPDATE_TABLE_DATA,
              payload: {
                  isLoading: false,
                  subUserList: []
              }
          });
      }
  },[]);

  useEffect(()=>{
      handleGetLeadPermissionRequests();
  },[state.perPage, state.page, props.isRefresh]);

  const handleGetLeadPermissionRequests = () =>{
      dispatch({
          type: ActionTypes.UPDATE_TABLE_DATA,
          payload: {
              isLoading: true
          }
      });
    try{
        getLeadPermissionRequests({ page: state.page, perPage: state.perPage })
            .then((res)=>{
                res = res.data;
                if (res.status == "success"){
                    dispatch({
                       type: ActionTypes.UPDATE_TABLE_DATA,
                       payload: {
                           leadPermissionRequests: res.data,
                           totalRecords: res.totalRecords,
                           totalPage: res.totalPage
                       }
                    });
                }
            }).catch((err)=>{

        }).finally(()=>{
            dispatch({
                type: ActionTypes.UPDATE_TABLE_DATA,
                payload: {
                    isLoading: false
                }
            });
        });
    }catch (err){
        dispatch({
            type: ActionTypes.UPDATE_TABLE_DATA,
            payload: {
                isLoading: false
            }
        });
    }
  };

  return (
    <>
      {state.isLoading ? <LeadGroupSkeleton/> : (
        <table>
        <thead>
          <tr className="header">
              <th>Contact</th>
              <th>Request For</th>
              <th>Request Type</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody className="tbody_content">
          {
            (!state.isLoading && state.leadPermissionRequests.length === 0) &&
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>No lead permission request found!</td>
              </tr>
          }

          {
              state.leadPermissionRequests.map((eachItem, index) => {
                return(<EachRow eachItem={eachItem} eachIndex={index} key={index}/>)
              })
          }
        </tbody>
      </table>)}
      </>
  );
};

export default PermissionTable;


const EachRow = ({ eachItem, eachIndex: index }) => {
    const {state, dispatch} = useContext(LeadPermissionRequestContext);

    const getSubUserName = (id) => {
        if (state.subUserList != undefined && state.subUserList != null && state.subUserList != '' && Array.isArray(state.subUserList)){
            let subUser = state.subUserList.find((user)=>(user.id === id));
            return (subUser && subUser.full_name) ? subUser.full_name : '';
        }

        return '';
    }

    const handleRejectContactRequest = () => {
        NewConfirmAlert({
            onSubmit : () => {
                rejectContactRequest({ contactRequestId: eachItem.id })
                    .then((res)=>{
                        res = res.data;

                        if(res.status == 'success'){
                            window.showNotification("SUCCESS",res.message);

                            let oldData = [...state.leadPermissionRequests];
                            oldData.splice(index,1);

                            let page = state.page;
                            let totalRecords = state.totalRecords;

                            if (oldData.length === 0){
                                page--;
                            }

                            totalRecords--;

                            dispatch({
                                type: ActionTypes.UPDATE_TABLE_DATA,
                                payload: {
                                    leadPermissionRequests: oldData,
                                    page: page,
                                    totalRecords: totalRecords
                                }
                            });
                        }else {
                            window.showNotification("ERROR",res.message);
                        }
                    }).catch((err)=>{
                    window.showNotification("ERROR","Something went wrong. Please try again later!");
                });
            },
            title: 'Please confirm your selection below:',
            description: '',
            cancelText: 'Cancel',
            submitText: 'Reject',
            width: '480px'
        });
    }


    const handleAcceptContactRequest = () => {
        NewConfirmAlert({
            onSubmit : () => {
                acceptContactRequest({ contactId: eachItem.contact_id, contactRequestId: eachItem.id, requestForUserId: eachItem.request_for_user_id })
                    .then((res)=>{
                        if(res.success){
                            window.showNotification("SUCCESS",res.message);
                            let oldData = [...state.leadPermissionRequests];
                            oldData.splice(index,1);

                            let page = state.page;
                            let totalRecords = state.totalRecords;

                            if (oldData.length === 0){
                                page--;
                            }

                            totalRecords--;

                            dispatch({
                                type: ActionTypes.UPDATE_TABLE_DATA,
                                payload: {
                                    leadPermissionRequests: oldData,
                                    page: page,
                                    totalRecords: totalRecords
                                }
                            });
                        }else {
                            window.showNotification("ERROR",res.message);
                        }
                    }).catch((err)=>{
                    window.showNotification("ERROR","Something went wrong. Please try again later!");
                });
            },
            title: 'Please confirm your selection below:',
            description: '',
            cancelText: 'Cancel',
            submitText: 'Accept',
            width: '480px'
        });
    }

    const renderContactInfo = () => {
        const item = [];
        if (eachItem.first_name || eachItem.last_name){
            let fullName = '';

            if (eachItem.first_name){
                fullName += eachItem.first_name;

                if (eachItem.last_name){
                    fullName += ' ';
                }
            }

            if (eachItem.last_name){
                fullName += eachItem.last_name;
            }

            item.push(<li>{fullName}</li>);
        }
        if (eachItem.email){
            item.push(<li>{eachItem.email}</li>);
        }
        if (eachItem.number){
            item.push(<li>{eachItem.number}</li>);
        }
        return item;
    }

    return(
        <tr>
            <td>
                <Link to={`/contacts/${eachItem.contact_id}`} target="_blank">
                    <ul>
                        {renderContactInfo()}
                    </ul>
                </Link>
            </td>
            <td>{ getSubUserName(eachItem.request_for_user_id) }</td>
            <td>{ ConstantsLabel[eachItem.request_type] }</td>
            <td style={{ textAlign: "center" }}>{ ConstantsLabel[eachItem.status] }</td>
            <td style={{ textAlign: "center" }}>
                <div>
                    <BootstrapTooltip arrow title={<span>Accept Request</span>}>
                        <IconButton
                            size="medium"
                            color="primary"
                            aria-label="accept"
                            onClick={()=>{
                                handleAcceptContactRequest()
                            }}
                        >
                            <CheckIcon />
                        </IconButton>
                    </BootstrapTooltip>

                    <BootstrapTooltip arrow title={<span>Reject Request</span>}>
                        <IconButton
                            size="medium"
                            color="secondary"
                            aria-label="reject"
                            onClick={()=>{
                                handleRejectContactRequest()
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </BootstrapTooltip>
                </div>
            </td>
        </tr>
    )
}