import React, { useEffect, useRef, useState } from "react";
import {
  Done as DoneIcon,
  Clear as ClearIcon,
  Create as CreateIcon,
  MoreHoriz as MoreHorizIcon,
  Delete as DeleteIcon,
  VolumeUp,
  AddCircle,
} from "@material-ui/icons";
import { Popover, Typography } from "@material-ui/core";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {
  callWhisperVirtualNumberSubmit,
  deleteVirtualNumberSetting,
} from "../../../api/profileApi";
import AudioRecord from "./AudioRecord";
import { confirmAlert } from "react-confirm-alert";
import { CustomButton } from "../../emailSettings/connectedEmailV2/ConnectedEmailStyle";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";

const CallWhisperEdit = ({ item }) => {
  const inputField = useRef(null);
  const [validation, setValidation] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldValue, setFieldValue] = useState(item.call_whisper_message);
  const [defaultValue, setDefaultValue] = useState(item.call_whisper_message);
  const [audio, setAudio] = useState(item.call_whisper_audio);
  const [defaultAudio, setDefaultAudio] = useState(item.call_whisper_audio);
  const [openAudio, setOpenAudio] = useState(item.call_whisper_audio !== null);
  const [defaultOpenAudio, setDefaultOpenAudio] = useState(
    item.call_whisper_audio !== null
  );
  const inputRef = useRef(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [editedFieldValue, setEditedFieldValue] = useState(
    item.call_whisper_message
  );
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
const handleCrossPress = () => {
  setAnchorEl(null);
  setEdit(false);
  setValidation(false);
  setValidationText("");
  setAudio(defaultAudio);
  setOpenAudio(defaultOpenAudio);
    setEditedFieldValue(fieldValue)
};

  const handleEnterPress = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      handleSubmit();
    }
  };

  const handleCheckPress = () => {
    handleSubmit();
  };

const handleSubmit = () => {
  let formData = new FormData();
  formData.append("id", item.id);
  formData.append("call_whisper_message", openAudio ? "" : editedFieldValue);
  if (openAudio) {
    formData.append("do_play_whisper_audio", 1);
    formData.append("whisper-audio-file", audio);
  }
  setSaving(true);
  callWhisperVirtualNumberSubmit(formData).then((res) => {
    setSaving(false);
    if (res.data.status === "validation-error") {
        window.showNotification(
          "ERROR",
          res.data.message[Object.keys(res.data.message)[0]][0]
        );
    } else if (res.data.status === "error") {
      window.showNotification("ERROR", res.data.message);
    } else if (res.data.status === "success") {
      setFieldValue(editedFieldValue);
      setEdit(false);
        

      if (openAudio) {
        setAudio(res.data.data.audioFile);
        setDefaultAudio(res.data.data.audioFile);
      } else {
        setDefaultValue(res.data);
      }

      window.showNotification("SUCCESS", "Call Whisper Changed");
      setAnchorEl(null);
       
    }
  });
};
const deleteSetting = () => {
  NewConfirmAlert({
      onSubmit: () => 
        {
      setSaving(true);
      deleteVirtualNumberSetting({
        id: item.id,
        setting: "call_whisper",
      }).then((res) => {
        setSaving(false);
        if (res.data.status === "validation-error") {
                        window.showNotification(
                          "ERROR",
                          res.data.message[Object.keys(res.data.message)[0]][0]
                        );
        } else if (res.data.status === "error") {
          window.showNotification("ERROR", res.data.message);
        } else if (res.data.status === "success") {
          setDefaultValue("");
          setFieldValue("");
          setEditedFieldValue("");
          setAudio(null);
          setOpenAudio(false);
          setDefaultOpenAudio(false);
          setAnchorEl(null);
        
          window.showNotification(
            "SUCCESS",
            "Call Whisper Setting Deleted"
          );
        }
      });
    },
    title: "Are You Sure?",
    description: "Are you sure to delete this whisper setting?",
      cancelText: 'No, Keep it',
      submitText: 'Yes, Delete it',
    width: "480px",
  });
};


  const handleDivClick = (event) => {
    if (openAudio) {
      setEdit(false);
    } else if (!fieldValue) {
      setEdit(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const divStyle = {
    width: "230px",
    border: mouseOver ? "1px solid #000" : "1px solid transparent",
    borderRadius: "8px",
    transition: "border 0.3s ease, padding 0.3s ease",
    paddingLeft: "8px",
    paddingRight: "8px",
    cursor: "pointer",
  };

  const inputStyle = {
    margin: "0px",
    width: "100%",
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    padding: "10px",
    minHeight: "40px",
  };

  const inputEditStyle = {
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    fontSize: "inherit",
    paddingLeft: "4px",
    paddingRight: "4px",
    boxShadow: "none",
    textDecoration: "none",
    border: "1px solid gray",
    borderRadius: "8px",
  };

  const dropdownStyle = {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    width: "420px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={divStyle}
        
        onClick={handleDivClick}
        ref={dropdownRef}
      >
        {openAudio ? (
          <span>
            <BootstrapTooltip arrow title={`Audio`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px 0px",
                }}
              >
                <VolumeUp
                  style={{ marginTop: "6px", marginBottom: "6px" }}
                  fontSize="medium"
                />
              </div>
            </BootstrapTooltip>
          </span>
        ) : fieldValue ? (
          <span>
            <BootstrapTooltip arrow title={fieldValue}>
              <Typography 
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              style={inputStyle}>{fieldValue}</Typography>
            </BootstrapTooltip>
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <AddCircle
              style={{ marginTop: "6px", marginBottom: "6px" }}
              fontSize="medium"
            />
          </div>
        )}
      </div>

      <Popover
        ref={inputRef}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleCrossPress}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={dropdownStyle}>
          <div className="d-flex align-items-center justify-content-between">
            {validation && (
              <small className="text-danger">{validationText}</small>
            )}
          </div>
          <div>
            {edit && (
              <>
                {openAudio ? (
                  <>
                  <AudioRecord voiceRecordCallback={(data) => setAudio(data)} />
                   { edit && !saving && ( <CustomButton onClick={() => {
                          setOpenAudio(false)
                        }}>
                      Use text
                    </CustomButton>)}
                    </>
                ) : (
                  <>
                  <input
                    ref={inputField}
                    value={editedFieldValue}
                    onKeyPress={handleEnterPress}
                    onChange={(e) => setEditedFieldValue(e.target.value)}
                    type="text"
                    placeholder={"Enter Whisper Message"}
                    style={inputEditStyle}
                  />
                   {edit && !saving && ( <CustomButton onClick={() => {setOpenAudio(true)}}>
                      Use Audio 
                    </CustomButton>)}
                  </>
                )}
              </>
            )}
            {!edit && (
              <>
                {openAudio ? (
                  <audio style={{ width: "100%" }} controls playsInline>
                    <source src={audio} type="audio/mpeg" />
                  </audio>
                ) : (
                  <div
                    style={{
                      padding: "10px",
                      maxWidth: "90%",
                      wordBreak: "break-word",
                    }}
                  >
                    {fieldValue}
                  </div>
                )}
              </>
            )}
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                  marginY: "10px",
                }}
              >
                {!edit && (
                  <div style={{ display: "flex", gap: "16px" }}>
                    <BootstrapTooltip
                      arrow
                      title={`Update Call Whisper Message`}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        <CreateIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                    {(defaultValue !== "" && defaultValue !== null) ||
                    audio ? (
                      <BootstrapTooltip
                        arrow
                        title={`Delete Call Whisper Setting`}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={deleteSetting}
                        >
                          <DeleteIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    ) : null}
                    {saving && (
                      <BootstrapTooltip
                        arrow
                        title={`Updating Call Whisper Message`}
                      >
                        <span>
                          <MoreHorizIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                  </div>
                )}
              </div>
              {edit && !saving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                    marginTop: "10px",
                  }}
                >
                  <BootstrapTooltip arrow title="Confirm">
                    {(editedFieldValue && editedFieldValue !== defaultValue) || (openAudio && audio !== defaultAudio) ? (
                      <span
                        onClick={handleCheckPress}
                        style={{ cursor: "pointer" }}
                      >
                        <DoneIcon fontSize="small" />
                      </span>
                    ) : (
                      <></>
                    )}
                  </BootstrapTooltip>
                  <BootstrapTooltip arrow title="Cancel">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleCrossPress}
                    >
                      <ClearIcon fontSize="small" />
                    </span>
                  </BootstrapTooltip>
                </div>
              )}
            </>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default CallWhisperEdit;
