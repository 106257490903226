import React from "react";
import { Tab, Tabs, styled } from "@material-ui/core";
import { HOME_PAGE_TABS } from "./helper/coreConstant";
import Utils from "../../helpers/Utils";

const TabWrapper = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiTabs-flexContainer": {
    gap: "20px !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#346fef",
  },
  "& .Mui-selected": {
    color: "#346FEF !important",
  },
  "& .tabItem": {
    color: "#133159A6",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const HomePageTab = ({ value, handleChange }) => {
  return (
    <TabWrapper
      value={value}
      onChange={handleChange}
      className="tabWrapper"
      textColor="primary"
      indicatorColor="primary"
    >
      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.PRODUCTS}
        label="Products & Services"
      />

      {((Utils.getAccountData("userId") === 1 ||
        Utils.getAccountData("userId") === 446) ||
        Utils.getAccountData("agencyBusinessType") === "INSURANCE") && (
          <Tab
            className="tabItem"
            value={HOME_PAGE_TABS.POLICY_TYPES}
            label="Policy Types"
          />
        )}

      {(Utils.getAccountData("userId") === 1 ||
        Utils.getAccountData("userId") === 446 ||
        Utils.getAccountData("agencyBusinessType") === "INSURANCE") && (
          <Tab
            className="tabItem"
            value={HOME_PAGE_TABS.INSURANCE_COMPANIES}
            label="Insurance Companies"
          />
        )}

      <Tab
        style={{ minWidth: "unset !important" }}
        className="tabItem"
        value={HOME_PAGE_TABS.GOALS}
        label="Goals"
      />
      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.DEAL_REPORT}
        label="Sales Report"
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.TEAM_SALES_REPORT}
        label="Team Member Sales Report"
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.PRODUCT_SALES_REPORT}
        label="Product Sales Report"
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.SALES_VS_GOAL_REPORT}
        label="Sales VS Goal Report"
      />
    </TabWrapper>
  );
};

export default HomePageTab;
