import React, { useState, useRef } from "react";
import {
  virtualNumberChangeDefault,
} from "../../../api/profileApi";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import ".././VirtualNumbers.css";
import { CheckCircle } from "@material-ui/icons";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";

const VirtualNumberEdit = ({ item, updateTable }) => {
  const dropdownRef = useRef(null);

 

  const divStyle = {
    width: "170px",
    borderRadius: "8px",
    transition: "border 0.3s ease, padding 0.3s ease",
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };

  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    pointerEvents: "none",
  };

 

  

  const makeDefault = () => {
    NewConfirmAlert({
      onSubmit: () => {
        virtualNumberChangeDefault({
          form_data: {
            type: "single",
            id: item.id,
          },
        })
          .then((response) => {
            if (response.data.status === "success") {
              window.showNotification("SUCCESS", response.data.message);
              updateTable();
            } else {
              window.showNotification("ERROR", response.data.message);
            }
          })
          .catch((error) => {
            window.showNotification("ERROR", "Something went wrong");
          });
      },
      title: "Are you sure?",
      description: `Do you want to make this number default?`,
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={divStyle}
        ref={dropdownRef}
      >
        <input
          style={inputStyle}
          value={item ? (item.virtual_number ? item.virtual_number : "") : ""}
          type="text"
          disabled={true}
        />
        {item.is_default ? (
          <BootstrapTooltip arrow title={`Default Number`}>
            <CheckCircle style={{ color:'#346fef', cursor: "pointer" }} 
                fontSize="small"
            />
          </BootstrapTooltip>
        ) : (
          <BootstrapTooltip arrow title={`Make Default Number`}>
             <CheckCircle style={{ color:'darkgray', cursor: "pointer" }} 
                fontSize="small"
                onClick={makeDefault}/>
          </BootstrapTooltip>
        )}
      </div>
    </div>
  );
};

export default VirtualNumberEdit;
