import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TableCell,
  TableRow,
  Typography,
  alpha,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";
import StarIcon from "@material-ui/icons/Star";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePolicyType,
  deletePolicyTypeForever,
  togglePolicyTypeFavorite,
  updatePolicyTypeStatus,
} from "../../../../../actions/ProductAction";
import SwitchC from "../../../../customField/subComponents/Switch";
import CustomConfirmAlert from "../../../common/confirm-alert/CustomConfirmAlert";
import { policyTypeFolderState } from "../../../common/productSelector";
import { renderFolderIcon } from "../../../helper/functions";
import AddOrEditNewPolicyTypeModal from "../AddOrEditNewPolicyTypeModal";
import { POLICY_TYPES_STATUS } from "../../../helper/coreConstant";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));

const PopoverStyles = styled(Popover)(({ theme }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  "& .popoverWrapper": {
    padding: theme.spacing(1),
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  },

  "& .popoverList": {
    padding: 0,
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .icon": { color: "#1d2939" },
    "& .text": { color: "#1d2939", fontWeight: 600, textAlign: "left" },
  },
}));

const EachPolicyTypeTableItem = ({ policy }) => {
  const { id, title, status, is_favorite, folders } = policy || {};

  const [openAddOrEditModal, setOpenAddOrEditModalModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [policyStatus, setPolicyStatus] = useState(
    status === "ACTIVE" ? true : false
  );
  const { selectedFolder } = useSelector(policyTypeFolderState);

  const dispatch = useDispatch();

  const handlePolicyStatus = (value) => {
    setPolicyStatus(value);
    dispatch(
      updatePolicyTypeStatus({ id: id, status: value ? "ACTIVE" : "INACTIVE" })
    );
  };

  const handleEditPolicyType = () => {
    setOpenAddOrEditModalModal(true);
    handlePopoverClose();
  };

  const handleFavoritePolicyType = () => {
    dispatch(
      togglePolicyTypeFavorite({
        is_favorite: is_favorite === "NO" ? "YES" : "NO",
        id,
      })
    );
    handlePopoverClose();
  };

  const confirmDeletePolicyType = () => {
    dispatch(
      deletePolicyType({
        id: id,
        status: POLICY_TYPES_STATUS.TRASH,
        callback: (data) => {
          if (data && !data.success && data.data?.message) {
            CustomConfirmAlert({
              onSubmit: () =>
                dispatch(
                  deletePolicyType({
                    id: id,
                    status: POLICY_TYPES_STATUS.TRASH,
                    force_delete: "TRUE",
                  })
                ),
              title: data.data?.message,
              description: "You cannot undo this action",
              submitText: "Force Delete",
            });
          }
        },
      })
    );
  };

  const handleDeletePolicyType = () => {
    handlePopoverClose();
    CustomConfirmAlert({
      onSubmit: () => confirmDeletePolicyType(),
      title: "Are you sure you want to delete this policy type?",
      description: "You cannot undo this action",
    });
  };

  const handleRestorePolicyType = () => {
    dispatch(deletePolicyType({ id: id, status: POLICY_TYPES_STATUS.ACTIVE }));
  };

  const confirmDeleteForever = () => {
    dispatch(deletePolicyTypeForever(id));
  };

  const handleDeleteForever = () => {
    handlePopoverClose();
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteForever(),
      title: "Are you sure you want to delete this policy type?",
      description: "You cannot undo this action",
    });
  };

  const renderActionButton = () => {
    switch (selectedFolder) {
      case "trash":
        return (
          <>
            <ListItem onClick={handleRestorePolicyType} button>
              <ListItemIcon>
                <RefreshIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Restore</Typography>
              </ListItemText>
            </ListItem>
            <ListItem onClick={handleDeleteForever} button>
              <ListItemIcon>
                <DeleteIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Delete Forever</Typography>
              </ListItemText>
            </ListItem>
          </>
        );

      default:
        return (
          <>
            <ListItem onClick={handleEditPolicyType} button>
              <ListItemIcon>
                <EditIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Edit</Typography>
              </ListItemText>
            </ListItem>

            <ListItem onClick={handleFavoritePolicyType} button>
              <ListItemIcon>
                <StarIcon
                  fontSize="small"
                  className="icon"
                  style={{ color: isFavorite ? "#f79009" : "#1d2939" }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">
                  {isFavorite ? "Remove Favorite" : "Favorite"}
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={handleDeletePolicyType}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography fontSize="small" className="text">
                  Delete
                </Typography>
              </ListItemText>
            </ListItem>
          </>
        );
    }
  };

  // Popover
  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const isFavorite = is_favorite === "YES" ? true : false;
  return (
    <TableRowWrapper>
      <TableCell component="th" scope="row">
        <div className="titleWrap">
          {title}
          <StarIcon
            className={isFavorite ? "iconFavorite active" : "iconFavorite"}
            onClick={handleFavoritePolicyType}
          />
        </div>
      </TableCell>
      <TableCell align="center">
        {folders?.length > 0 && (
          <Chip
            avatar={renderFolderIcon(
              folders[0]?.folder_icon,
              folders[0]?.folder_color,
              18
            )}
            style={{
              backgroundColor: alpha(folders[0]?.folder_color, 0.12) || "grey",
              color: folders[0]?.folder_color,
              paddingLeft: 6,
            }}
            size="small"
            label={folders[0]?.title}
            st
          />
        )}{" "}
      </TableCell>
      <TableCell align="center">
        <SwitchC
          status={policyStatus}
          brandColor={"#346fef"}
          onChange={() => handlePolicyStatus(!policyStatus)}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton size="small" onClick={handlePopover}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>

      <PopoverStyles
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popoverWrapper">
          <List className="popoverList" component="div" dense>
            {renderActionButton()}
          </List>
        </div>
      </PopoverStyles>

      {openAddOrEditModal && (
        <AddOrEditNewPolicyTypeModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModalModal(false)}
          edit={policy}
        />
      )}
    </TableRowWrapper>
  );
};

export default EachPolicyTypeTableItem;
