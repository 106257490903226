import { Box } from "@material-ui/core";
import React from "react";
import BodySkeleton from "./BodySkeleton";
import InsuranceCompanyHeader from "./InsuranceCompanyHeader";
import ContentBody from "./contentBody";

const Content = ({
  isLoading,
  insuranceCompanyList,
  setInsuranceCompanyList,
  search,
  setSearch,
  folderList,
  handleFolderList,
  selectedFolder,
}) => {
  return (
    <Box flex={1} sx={{ background: "white", borderRadius: "8px" }}>
      <InsuranceCompanyHeader
        search={search}
        setSearch={setSearch}
        setInsuranceCompanyList={setInsuranceCompanyList}
        folderList={folderList}
        handleFolderList={handleFolderList}
        selectedFolder={selectedFolder}
      />
      <ContentBody
        insuranceCompanyList={insuranceCompanyList}
        setInsuranceCompanyList={setInsuranceCompanyList}
        selectedFolder={selectedFolder}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default Content;
