import {
  Box,
  Button,
  Chip,
  Dialog,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productFolderState } from "../../common/productSelector";
import { BasicInput } from "../sidebar/CreateNewFolderModal";
import { productStyle } from "./ProductStyle";
import BasicSelect from "./BasicSelect";
import { addProduct, deleteProduct, updateProduct } from "../../../../actions/ProductAction";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
  description: {
    border: "1px solid #ced4da !important",
    borderRadius: "4px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-multiline" : {
      color: "var(--dark_blue)"
    }
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    height: "24px ",
    color: "var(--dark_blue)"
  },
  multipleSelect: {
    "& .MuiSelect-select": {
      // paddingTop: "6px !important",
      display: "flex",
      alignItems: "center",
    },
  },
  restoreText: {
    color: "#0b0be1",
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    userSelect: "none"
  }
});

const BasicTextField = styled(TextField)(({ theme }) => ({
  borderColor: "#ced4da",
  borderWidth: 1,

  "& input": {
    fontSize: "14px !important",
    color: "var(--dark_blue)",
  },
  "& input:valid:hover + fieldset, & input:valid:focus + fieldset": {
    borderColor: "#ced4da",
    borderWidth: 1,
  },

  "& .MuiOutlinedInput-adornedStart": {
    paddingRight: "14px",
  },

  "& .MuiTypography-root" : {
     color: "var(--dark_blue)"
   
  },

  "& .MuiInputBase-input": {
    height: "2.5rem !important",
    margin: "0 !important",
    borderBottom: "0 !important",
    "&:focus": {
      borderBottom: "0 !important",
      "box-shadow": "none !important",
    },
  },
}));

const AddOrEditNewProductModal = ({ open, onClose, edit }) => {
  const selectedFoldersFromEdit = edit?.folders.map((folder) => folder.id);
  const [title, setTitle] = useState(edit?.title || "");
  const [description, setDescription] = useState(edit?.description || "");
  const [unitCost, setUnitCost] = useState(edit?.unit_cost || 0);
  const [unitPrice, setUnitPrice] = useState(edit?.unit_price || 0);
  const [billingFrequency, setBillingFrequency] = useState(
    edit?.billing_frequency || null
  );
  const [closeDate, setCloseDate] = useState(edit?.close_date || null);
  const [userProductFolderIds, setUserProductFolderIds] = useState(
    selectedFoldersFromEdit || []
  );
  const [error, setError] = useState({
    title: "",
    description: "",
    closeDate: "",
  });
  const [duplicateTrashProduct, setDuplicateTrashProduct] = useState(null);

  const { productFolders } = useSelector(productFolderState);
  const dispatch = useDispatch();

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
      description: "",
      closeDate: "",
    };
  
    if (title.trim() === "") {
      errors.title = "Product Title is required";
      isValid = false;
    }
    if (title.length > 100) {
      errors.title = "Product title  must be less than 100 characters";
      isValid = false;
    }
    if (description.trim() === "") {
      errors.description = "Description is required";
      isValid = false;
    }
    if (description.length > 255) {
      errors.description = "Description  must be less than 255 characters";
      isValid = false;
    }
  
    setError(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    if (!validateForm()) {
      return;
  }

    const formData = {
      title,
      description,
      unit_cost: unitCost === "" ? 0 : unitCost,
      unit_price: unitPrice === "" ? 0 : unitPrice,
      billing_frequency: billingFrequency === "" ? 0 : billingFrequency,
      close_date: closeDate,
      userProductFolderIds,
      callback: (data) => {
        if (data.success) {
          onClose();
        } else {
          if (data && data.data.status === "TRASH") {
            setDuplicateTrashProduct(data.data);
            setError((prevState) => ({ ...prevState, title: "Product already exists in trash." }));
            window.showNotification("error", "Product already exists it trash.");
          } else {
            setError((prevState) => ({ ...prevState, title: data.message }));
            window.showNotification("error", data.message);
          }
        }
      }
    };

    if (edit) {
      dispatch(updateProduct({ id: edit.id, payload: formData }));
    } else {
      dispatch(addProduct(formData));
    }
  };

  const handleChange = (event) => {
    setUserProductFolderIds(event.target.value);
  };

  const handleNumberInput = (e, setter) => {
    const { value } = e.target;

    const decimalRegex = /^\d{0,6}(\.\d{0,2})?$/;

    if (decimalRegex.test(value)) {
      const [integerPart, decimalPart] = value.split(".");

      if (integerPart.length <= 6) {
        if (!decimalPart || decimalPart.length <= 2) {
          setter(value);
        }
      }
    }
  };

  const handleRestoreProduct = () => {
    dispatch(deleteProduct({
      id: duplicateTrashProduct.id,
      status: "ACTIVE",
      callback: (data) => {
        if (data && data.success) {
          onClose();
          // close modal
        }
      }
    }));
  }

  const classes = useStyles();
  const { topbarNewProductButton, lightBlue } = productStyle();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        minWidth={600}
        minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          sx={{ borderBottom: "1px solid #d3d3d3" }}
        >
          <Typography className={classes.titleHeader}>
            {edit ? "Edit Product Or Service" : "Add a New Product Or Service"}
          </Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
        <Box padding={2}>
          <Box>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Name of Product / Service<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt={1}>
              <BasicInput
                fullWidth
                value={title}
                className={`${error.title ? classes.errorStyle : ""}`}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (error.title) {
                    setError((prevState) => ({ ...prevState, title: "" }));
                  }
                }}
                required
              />
              {error.title && (
                <Typography variant="body2" className={classes.errorText}>
                  {error.title} {duplicateTrashProduct ? <span className={classes.restoreText} onClick={handleRestoreProduct}>Restore</span> : null}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box padding={2} pt={0}>
          <Typography className={classes.titleHeader}>
            Product / Service Information
          </Typography>
          <Box mt={1}>
            <Typography
              className={`${error.description ? classes.errorText : ""}`}
            >
              Description<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt={1}>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (error.description) {
                    setError((prevState) => ({ ...prevState, description: "" }));
                  }
                }}
                className={`${classes.description} ${error.description ? classes.errorStyle : ""
                  }`}
                required
              />
              {error.description && (
                <Typography variant="body2" className={classes.errorText}>
                  {error.description}
                </Typography>
              )}
            </Box>
          </Box>
          <Box mt={2}>
            <Typography>Select Category</Typography>
            <Box mt={1}>
              <BasicSelect
      className={classes.multipleSelect}
      multiple
      defaultText="Select Category"
      name="custom-select"
      value={userProductFolderIds}
      onChange={handleChange}
      options={productFolders.map((folder) => ({
        value: folder.id,
        label: folder.title,
      }))}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <em>Select Category</em>;
        }
        return (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={productFolders.find((folder) => folder.id === value)?.title}
                className={classes.chip}
              />
            ))}
          </div>
        );
      }}
    />
            </Box>
          </Box>
          <Box display={"flex"} mt={2} gridGap={16}>
            <Box flex={1}>
              <Typography>Unit Cost</Typography>
              <Box mt={1}>
                <BasicTextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 0, max: 999999 }}
                  value={unitCost}
                  onChange={(e) => handleNumberInput(e, setUnitCost)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Box flex={1}>
              <Typography>Unit Price</Typography>
              <Box mt={1}>
                <BasicTextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  inputProps={{ min: 0, max: 999999 }}
                  value={unitPrice}
                  onChange={(e) => handleNumberInput(e, setUnitPrice)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box flex={1} mt={2}>
            <Typography>Billing Frequency</Typography>
            <Box mt={1}>
              <BasicInput
                type="number"
                fullWidth
                value={billingFrequency}
                onChange={(e) => handleNumberInput(e, setBillingFrequency)}
              />
            </Box>
          </Box>


        </Box>
        <Box
          sx={{ borderTop: "1px solid #d3d3d3" }}
          padding={2}
          display={"flex"}
          justifyContent={"end"}
        >
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              color="primary"
              className={lightBlue}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={topbarNewProductButton}
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddOrEditNewProductModal;
