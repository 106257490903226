import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import HomePageTab from "./HomePageTab";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HOME_PAGE_TABS } from "../products/helper/coreConstant.js";
import { fetchPipelineApi, getTeamUserListApiV2 } from "../../api/productsApi";
import { handleAssetLoadingState, setPipelines, setTeamUsers } from "../../actions/ProductAction";
import SalesGoal from "../products/salesGoal/index.jsx";
import TeamSalesReport from "../products/teamSalesReport/index.jsx";
import ProductSalesReport from "../products/productSalesReport/index.jsx";
import SalesVsGoalReport from "../products/salesVsGoalReport/index.jsx";
import DealReport from "../dealReport/index.jsx";

const ProductsWrapper = styled("div")(({ theme }) => ({
  height: ` calc(100vh - 100px)`,
}));
const SalesReports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(null);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    let tab = HOME_PAGE_TABS.GOALS
    if (history &&
        history.location &&
        history.location.state &&
        history.location.state.from === "settingPage"
    ) {
      setValue(history.location.state.tab);

      history.replace({});
    }else {
      setValue(tab);
    }
    window.setActiveSidebar('setting')
    loadAssets().then();
  }, []);

  const loadAssets = async () => {
    try {
      const [response1, response2] = await Promise.all([
        fetchPipelineApi(),
        getTeamUserListApiV2()
      ]);

      if (response1 && response1.data && response1.data.data) {
        dispatch(setPipelines(response1.data.data));
      }

      if (response2 && response2.data && response2.data.status && response2.data.data) {
        dispatch(setTeamUsers(response2.data.data));
      }

      setAssetsLoaded(true)
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(handleAssetLoadingState(false));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderTabContent = () => {

    switch (value) {
      case HOME_PAGE_TABS.DASHBOARD:
        // return <DashboardContent />;
        break;


      case HOME_PAGE_TABS.GOALS:
        return <SalesGoal />;

      case HOME_PAGE_TABS.TEAM_SALES_REPORT:
        return <TeamSalesReport assetsLoaded={assetsLoaded} />;

      case HOME_PAGE_TABS.PRODUCT_SALES_REPORT:
        return <ProductSalesReport assetsLoaded={assetsLoaded} />;

      case HOME_PAGE_TABS.SALES_VS_GOAL_REPORT:
        return <SalesVsGoalReport assetsLoaded={assetsLoaded} />;
  
      case  HOME_PAGE_TABS.DEAL_REPORT:
        return  <DealReport/>

      default:
        return null;
    }
  };

  return (
    <ProductsWrapper>
      <HomePageTab
        value={value}
        handleChange={handleChange}
      />
      {RenderTabContent()}
    </ProductsWrapper>
  );
};

export default SalesReports;
