import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { updateInsuranceCompanyApi } from "../../../../../api/productsApi";
import EachCompanyItem from "./EachCompany";
import BodySkeleton from "../BodySkeleton";
import { CustomScrollbar } from "../../../productsStyle";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

const ContentBody = ({
  insuranceCompanyList,
  setInsuranceCompanyList,
  selectedFolder,
  isLoading,
}) => {
  const updateInsuranceCompany = async (payload) => {
    const newPayload = {
      insurance_company_folder_id: selectedFolder,
      insurance_company_id: payload.id,
      selected: payload.checked,
    };
    try {
      const response = await updateInsuranceCompanyApi(newPayload);

      if (response?.success) {
        window.showNotification("SUCCESS", response.message || "Success");

        setInsuranceCompanyList((prevState) =>
          prevState.map((company) => {
            if (company.id === payload.id) {
              return {
                ...company,
                selected: payload.checked === "TRUE" ? 1 : 0,
              };
            }
            return company;
          })
        );
      } else {
        window.showNotification("ERROR", response.data.message);
      }
    } catch (error) {}
  };
  const handleSelectCompany = (payload) => {
    updateInsuranceCompany(payload);
  };

  const classes = useStyles();
  if (isLoading) {
    return <BodySkeleton />;
  } else {
    return (
      <div className={classes.bodyWrapper}>
        <CustomScrollbar style={{ maxHeight: "calc(100vh - 270px)" }}>
          <Grid container>
            {insuranceCompanyList?.map((data) => (
              <EachCompanyItem
                key={data.id}
                company={data}
                onSelectCompany={handleSelectCompany}
              />
            ))}
          </Grid>
        </CustomScrollbar>
      </div>
    );
  }
};

export default ContentBody;
